import React, { useState } from 'react';
import { Modal } from '@mui/material';
import { ModalHeader } from './ModalHeader/index';
import styled from 'styled-components';
import If from './../If/index';
import { themes } from 'styles/theme/themes';

export const TwoViewModal = ({
  title,
  width = '473px',
  onClose,
  onOpen,
  children,
}) => {
  const [viewMode, setViewMode] = useState<'sidebar' | 'floated'>('sidebar');

  const [BodyChild, FooterChild] = React.Children.toArray(children);

  return (
    <Modal
      open={onOpen}
      onClose={onClose}
      aria-labelledby={title}
      sx={{
        display: 'flex',
        // alignItems: viewMode === 'sidebar' ? 'flex-end' : 'flex-center',
        justifyContent: viewMode === 'sidebar' ? 'end' : 'center',
      }}
    >
      <ModalContentWrapper onCenter={viewMode === 'floated'} width={width}>
        <ModalHeaderWrapper>
          <ModalHeader
            title={title}
            onClose={onClose}
            viewMode={viewMode}
            setViewMode={setViewMode}
          />
        </ModalHeaderWrapper>

        {/*  dynamic design acconrding to Modal children */}
        {/*  In case the footer passed to the modal with body */}
        <ModalBodyWrapper footerChildrenExists={!!FooterChild}>
          {BodyChild}
        </ModalBodyWrapper>

        <If condition={!!FooterChild} otherwise={null}>
          <ModalFooterWrapper>{FooterChild}</ModalFooterWrapper>
        </If>
      </ModalContentWrapper>
    </Modal>
  );
};

const ModalContentWrapper = styled.div<{ onCenter: boolean; width: string }>`
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.onCenter ? '80vh' : '100vh')};
  margin: ${(props) => (props.onCenter ? '10vh 0' : '0')};
  width: ${(props) => props.width};
  border-radius: ${(props) => (props.onCenter ? '5px' : '5px 0 0 0')};
  background-color: ${(props) => props.theme.background};
  transition:
    height 0.3s ease,
    margin 0.3s ease,
    border-radius 0.3s ease,
    justify-content 0.3s ease;
  position: relative;
`;

const ModalHeaderWrapper = styled.div`
  flex: 0 0 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
  width: 100%;
  background-color: ${() => themes.default.background};
  border-bottom: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
`;

const ModalBodyWrapper = styled.div<{ footerChildrenExists: boolean }>`
  flex: 1 1 ${(props) => (props.footerChildrenExists ? '80%' : '90%')};
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 30px;
`;

const ModalFooterWrapper = styled.div`
  flex: 0 0 10%;
  display: flex;
  align-items: center;
  width: 100%;
  border-top: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
`;
