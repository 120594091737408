import { Form } from 'app/components/Form/styles';
import Grid from '@mui/material/Grid';
import { MenuItem, Stack, Switch, Typography } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/material.css';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDebounce } from 'common/hooks/useDebounce';
import Box from '@mui/material/Box';
import { IconButton, Tooltip } from '@mui/material';
import {
  Trash,
  Flag,
  PlusCircle,
  PaperPlaneTilt,
  UploadSimple,
  TrashSimple,
} from '@phosphor-icons/react';
import {
  useUpdateCustomerMutation,
  useGetCustomerTypesQuery,
  useUpdateCustomerByIdMutation,
} from 'common/services/customerApi';
import { FieldComponent } from 'app/components/FieldComponent';
import { ClientMap } from 'app/components/ClientMap';
import { StatusCustomer } from 'app/components/StatusCustomer';
import { haveSameValues, omitEmptyValues } from '../../../helper/helpers';
import { ChipWithRadio } from '../../../../ChipWithRadio';
import { themes } from 'styles/theme/themes';
import { useGetUsersBasicQuery } from 'common/services/userApi';
import { PhoneInputField } from 'app/components/Form/PhoneInputField';
import { usePlaceContext } from '../../../hooks/usePlaceContext';
import { CustomerStatus } from 'common/types/Customer';
import { useToaster } from 'hooks/useToaster';
import { UploadZone } from 'app/components/UploadCustomerImage';
import { useUploadMutation } from 'common/services/files';
import { UploadedFile } from 'app/components/UploadComponent';
import If from 'app/components/If';
import { FileRejection } from 'react-dropzone';

export const InfoForm = ({ customerDetail }) => {
  const { canEdit } = usePlaceContext();

  const { data: clientCategoryDetail } = useGetCustomerTypesQuery({});
  const { data: usersData } = useGetUsersBasicQuery('?page=1&limit=1000');
  const [ImageValue, setImage] = useState<Array<{
    url: string;
    size: number;
  }> | null>(null);
  const [mainEmailAddress, setmainEmailAddress] = useState(
    customerDetail?.email || '',
  );
  const [emailAddresses, setEmailAddresses] = useState<string[]>(
    customerDetail?.otherEmails || [],
  );
  const [newEmailAddress, setNewEmailAddress] = useState<string>('');
  const [flagColors, setFlagColors] = React.useState({});
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
  const [isIconClicked, setIsIconClicked] = useState<boolean>(false);
  const [toggle, setToggle] = useState(false);
  const [iconcolor, setIconcolor] = useState(false);
  const [salesMobilePhoneValue, setSalesManPhoneNumber] = useState(
    customerDetail?.phone || '',
  );
  const [phoneValue, setPhoneValue] = useState(
    customerDetail?.mobilePhone || '',
  );
  const [mobilePhone2Value, setMobilePhone2Value] = useState(
    customerDetail?.mobilePhone2 || '',
  );

  const [selectedValue, setSelectedValue] = useState<string>(
    customerDetail?.type || 'client',
  );
  const [mainlocationValue, setLocationvalue] = useState<{
    name: string;
    city: string;
    department: string;
    region: string;
    continent: string;
    zipCode: string;
    formattedAddress: string;
    shortAddress: string;
    countryCode: string;
    geometry: {
      type: string;
      coordinates: number[];
    };
  }>({
    name: customerDetail?.mainLocation?.name || '',
    city: customerDetail?.mainLocation?.city || '',
    department: customerDetail?.mainLocation?.department || '',
    region: customerDetail?.mainLocation?.region || '',
    continent: customerDetail?.mainLocation?.continent || '',
    zipCode: customerDetail?.mainLocation?.zipCode || '',
    formattedAddress: customerDetail?.mainLocation?.formattedAddress || '',
    shortAddress: customerDetail?.mainLocation?.shortAddress || '',
    countryCode: customerDetail?.mainLocation?.countryCode || '',
    geometry: {
      type: customerDetail?.mainLocation?.geometry?.type || '',
      coordinates: customerDetail?.mainLocation?.geometry?.coordinates || [],
    },
  });

  const { t } = useTranslation();

  const DEFAULT_LATITUDE = 49.029045;
  const DEFAULT_LONGITUDE = 2.8033632;

  const [switchStateValue, setSwitchState] = useState<boolean>(
    customerDetail?.switchState || false,
  );

  const handleDeleteEmailAddress = (index) => {
    const updatedEmailAddresses = [...emailAddresses];
    const emailToDelete = updatedEmailAddresses[index];
    if (mainEmailAddress === emailToDelete) {
      setmainEmailAddress('');
    }
    updatedEmailAddresses.splice(index, 1);
    setEmailAddresses(updatedEmailAddresses);
    setFormData((prevFormData) => ({
      ...prevFormData,
      email: mainEmailAddress === emailToDelete ? '' : mainEmailAddress,
      other_emails: updatedEmailAddresses,
    }));
  };
  const handleFlagIconClick = (clickedEmail) => {
    const newFlagColors = {};
    for (const email of emailAddresses) {
      newFlagColors[email] = email === clickedEmail ? 'goldenrod' : '';
    }
    setmainEmailAddress(clickedEmail);
    setFormData((prevFormData) => ({
      ...prevFormData,
      email: clickedEmail,
    }));
    setFlagColors(newFlagColors);
  };
  const handleFlagIconClickAfterDelete = (clickedEmail) => {
    const newFlagColors = {};
    for (const email of emailAddresses) {
      newFlagColors[email] = email === clickedEmail ? 'goldenrod' : '';
    }
    setFlagColors(newFlagColors);
  };

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      code: customerDetail?.code ?? '',
      publicName: customerDetail?.publicName ?? '',
      legalName: customerDetail?.legalName ?? '',
      email: customerDetail?.email ?? '',
      otherEmails: customerDetail?.otherEmails ?? [],
      vatNumber: customerDetail?.vatNumber ?? '',
      invoicePeriod: customerDetail?.invoicePeriod ?? '',
      phone: customerDetail?.phone ?? '',
      mobilePhone2: customerDetail?.mobilePhone2 ?? '',
      _customerType: customerDetail?._customerType ?? '',
      _salesman: customerDetail?._salesman?.id ?? '',
      status: customerDetail?.status ?? '',
      managerName: customerDetail?.managerName ?? '',
      managerName2: customerDetail?.managerName2 ?? '',
      mainLocation: customerDetail?.mainLocation ?? {},
      switchState: customerDetail?.switchState ?? '',
      legalIdAlt: customerDetail?.legalIdAlt ?? '',
      salePhone: customerDetail?.salePhone ?? '',
      type: customerDetail?.type ?? '',
    },
  });

  useEffect(() => {
    setSalesManPhoneNumber(customerDetail?.phone || '');
    setEmailAddresses(customerDetail?.otherEmails || []);
    setPhoneValue(customerDetail?.mobilePhone || '');
    setMobilePhone2Value(customerDetail?.mobilePhone2 || '');
    setSelectedValue(customerDetail?.type);
    setmainEmailAddress(customerDetail?.email || '');
    setSwitchState(customerDetail?.switchState);
    reset({
      code: customerDetail?.code || '',
      publicName: customerDetail?.publicName || '',
      legalName: customerDetail?.legalName || '',
      email: customerDetail?.email || '',
      vatNumber: customerDetail?.vatNumber || '',
      invoicePeriod: customerDetail?.invoicePeriod || '',
      phone: customerDetail?.phone || '',
      mobilePhone2: customerDetail?.mobilePhone2 || '',
      _customerType: customerDetail?._customerType || '',
      _salesman: customerDetail?._salesman?.id || '',
      status: customerDetail?.status || '',
      managerName: customerDetail?.managerName || '',
      managerName2: customerDetail?.managerName2 || '',
      mainLocation: customerDetail?.mainLocation || {},
      legalIdAlt: customerDetail?.legalIdAlt || '',
      salePhone: customerDetail?.salePhone || '',
    });
  }, [customerDetail]);

  const { control, watch, reset } = methods;

  useEffect(() => {
    if (!!emailAddresses?.length) {
      handleFlagIconClickAfterDelete(customerDetail?.email);
    }
  }, [emailAddresses]);

  const code = watch('code');
  const publicName = watch('publicName');
  const legalName = watch('legalName');
  const vatNumber = watch('vatNumber');
  const managerName2 = watch('managerName2');
  const legalIdAlt = watch('legalIdAlt');
  const salesman = watch('_salesman');
  const status = watch('status');
  const managerName = watch('managerName');
  const salePhone = watch('salePhone');
  const customerType = watch('_customerType');

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleNewEmailAddressChange = (event) => {
    const { value } = event.target;
    setNewEmailAddress(value);
    setIsValidEmail(validateEmail(value));

    const iconColor =
      value && isValidEmail && isIconClicked ? '#2A59FF' : '#757575';
    updateIconColor(iconColor);
  };

  const toast = useToaster();

  const handleAddEmailAddress = () => {
    const trimmedEmail = newEmailAddress.trim();
    if (isValidEmail && trimmedEmail !== '') {
      const emailExists = emailAddresses.includes(trimmedEmail);
      if (!emailExists) {
        setIsIconClicked(true);
        setEmailAddresses((prevEmailAddresses) => [
          ...prevEmailAddresses,
          trimmedEmail,
        ]);
        setNewEmailAddress('');

        setFormData((prevFormData) => ({
          ...prevFormData,
          other_emails: [...(prevFormData.other_emails || []), trimmedEmail],
        }));

        const iconColor =
          newEmailAddress && isValidEmail ? '#2A59FF' : '#757575';
        updateIconColor(iconColor);
      } else {
        toast(5000, 'error', 'This email address already exists.');
      }
    }
  };

  const updateIconColor = (color) => {
    const plusCircleElement = document.getElementById('plusCircle');
    if (plusCircleElement) {
      plusCircleElement.style.color = color;
      plusCircleElement.style.cursor =
        color === '#2A59FF' ? 'pointer' : 'default';
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);
    setToggle(isValid);
    setIconcolor(isValid);
    return isValid;
  };

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchState(event.target.checked);
    setFormData({
      ...formData,
      switchState: event.target.checked,
    });
  };

  const [formData, setFormData] = useState(customerDetail);
  useEffect(() => {
    setFormData(customerDetail);
  }, []);

  const [updateCustomer] = useUpdateCustomerMutation();

  const payload = useMemo(() => {
    return {
      id: customerDetail?._id,
      code: code,
      publicName: publicName,
      email: mainEmailAddress,
      vatNumber: vatNumber,
      managerName2: managerName2,
      legalName: legalName,
      legalIdAlt: legalIdAlt,
      status: status,
      _salesman: salesman,
      _customerType: customerType,
      mobilePhone: phoneValue,
      mobilePhone2: mobilePhone2Value,
      managerName: managerName,
      switchState: switchStateValue,
      otherEmails: emailAddresses,
      mainLocation: mainlocationValue,
      phone: salesMobilePhoneValue,
      type: selectedValue,
    };
  }, [
    code,
    publicName,
    mainEmailAddress,
    vatNumber,
    managerName2,
    legalName,
    legalIdAlt,
    status,
    salesman,
    customerType,
    phoneValue,
    mobilePhone2Value,
    managerName,
    switchStateValue,
    emailAddresses,
    mainlocationValue,
    salePhone,
    selectedValue,
    salesMobilePhoneValue,
  ]);

  const debouncedPayload = useDebounce(payload, 300);

  function handleSaveCustomerData() {
    if (
      haveSameValues(control?._defaultValues, customerDetail) &&
      haveSameValues(mainlocationValue, customerDetail?.mainLocation)
    ) {
      return;
    } else {
      const cleanPayload = omitEmptyValues(debouncedPayload);

      updateCustomer(cleanPayload);
    }
  }

  useEffect(() => {
    if (canEdit) {
      handleSaveCustomerData();
    }
  }, [debouncedPayload]);

  const customerStatus: any = [];
  Object.values(CustomerStatus).forEach((status) => {
    customerStatus.push({
      label: t(`customer.status.${status}`),
      value: status,
      render: () => <StatusCustomer status={status} />,
    });
  });

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  //image
  //---------image----------------------
  const getObjectURL = (file: any): any => {
    return file && URL.createObjectURL(file);
  };

  const [uploadFile] = useUploadMutation();
  const [updateCustomerById] = useUpdateCustomerByIdMutation();

  const [isuploadingImage, setIsuploadingImage] = useState(false);
  const [hoverImage, setHoverImage] = useState<boolean>(false);
  const uploadImage = async (
    image: UploadedFile[] | null,
    customerId: number,
  ) => {
    if (image) {
      setIsuploadingImage(true);
      try {
        const formData = new FormData();
        formData.append('file', image[0]);

        const response = await uploadFile({
          formData,
          preserveName: false,
          persist: false,
          objectType: 'customers',
          objectId: customerId,
        }).unwrap();
        updateCustomerById({
          id: customerId,
          updateData: { logo: response },
        });

        const { size, url } = response;
        setImage([{ size, url }]);
        return { size, url };
      } catch (e) {
        console.error('Error uploading images:', e);
      } finally {
        setIsuploadingImage(false);
      }
    }
  };

  const handleUploadClick = (e, imageUrl) => {
    e.preventDefault();
    e.stopPropagation();
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'image.jpg';
    link.click();
  };

  return (
    <div
      style={{
        height: '450px',
        minHeight: 'calc(100vh - 210px)',
        overflow: 'auto',
      }}
    >
      <Form>
        <Grid
          container
          spacing={2}
          style={{
            width: '100%',
            height: '985px',
            padding: '17px 0px 21px 17px',
            borderRadius: '4px',
          }}
        >
          <Grid
            item
            sx={{
              borderRadius: '4px',
              width: 'calc(50% - 10px)',
              background: 'white',
              border: '0.5px solid #E0E0E0',
              marginLeft: '10px',
              height: 'max-content',
              paddingBottom: '20px',
              paddingRight: '16px',
            }}
          >
            <Typography fontWeight={500}>{t('info-main-details')}</Typography>
            <div
              style={{
                marginBottom: '20px',
              }}
            >
              <FormControl
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  marginTop: '8px',
                }}
              >
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={selectedValue}
                  onChange={handleChange}
                  style={{ display: 'flex' }}
                >
                  <FormControlLabel
                    value="Client"
                    control={
                      <ChipWithRadio
                        value="client"
                        label={t('info-client')}
                        selectedValue={selectedValue}
                        disabled={!canEdit}
                      />
                    }
                    label=""
                  />
                  <FormControlLabel
                    value="Potential Client"
                    control={
                      <ChipWithRadio
                        value="prospect"
                        label={t('add-main-details-potential-client')}
                        selectedValue={selectedValue}
                        disabled={!canEdit}
                      />
                    }
                    label=""
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <FieldComponent
              style={{ width: '100%' }}
              name="legalIdAlt"
              control={control}
              size="small"
              label={t('Add_Client_SIREN.number')}
              placeholder={t('Add_Client_Enter_SIREN_number')}
              disabled={false}
              variant="outlined"
              InputProps={{
                disabled: !canEdit,
              }}
            />

            <div
              className="clientInfo"
              style={{
                marginTop: '16px',
              }}
            >
              <Typography
                fontWeight={500}
                sx={{
                  mb: '20px',
                }}
              >
                {t('client_information')}
              </Typography>
              <Grid container alignItems="center">
                <FieldComponent
                  style={{ width: 'calc(50% - 10px)', marginRight: '20px' }}
                  name="code"
                  control={control}
                  size="small"
                  label={t('Add_Client_Enter_Client_Code')}
                  placeholder={t('Add_Client_Enter_Client_Code')}
                  disabled={false}
                  variant="outlined"
                  required
                  InputProps={{
                    disabled: !canEdit,
                  }}
                />
                <FieldComponent
                  style={{ width: 'calc(50% - 10px)' }}
                  name="publicName"
                  control={control}
                  required
                  size="small"
                  label={t('Add_Client_Client_Name')}
                  placeholder={t('Add_Client_Enter_Public_Name')}
                  disabled={false}
                  variant="outlined"
                  InputProps={{
                    disabled: !canEdit,
                  }}
                />
              </Grid>
              <Grid container alignItems="center">
                <FieldComponent
                  control={control}
                  name="status"
                  label={t('Add_Client_Client_Status')}
                  style={{ width: 'calc(50% - 10px)' }}
                  select
                  InputProps={{
                    disabled: !canEdit,
                  }}
                >
                  {customerStatus?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.render()}
                    </MenuItem>
                  ))}
                </FieldComponent>
                <FieldComponent
                  control={control}
                  name="_customerType"
                  label={t('Add_Client_Client_Group')}
                  style={{ width: 'calc(50% - 10px)', marginLeft: '20px' }}
                  select
                  InputProps={{
                    disabled: !canEdit,
                  }}
                >
                  {clientCategoryDetail?.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </FieldComponent>
              </Grid>
              <Grid container alignItems="center">
                <FieldComponent
                  style={{ width: 'calc(50% - 10px)' }}
                  name="categorie"
                  control={control}
                  size="small"
                  label={t('add-client-category')}
                  variant="outlined"
                  InputProps={{
                    disabled: true,
                  }}
                />
                <FieldComponent
                  control={control}
                  name="tags"
                  label={t('info-client-Tags')}
                  style={{ width: 'calc(50% - 10px)', marginLeft: '20px' }}
                  select
                  InputProps={{
                    disabled: !canEdit,
                  }}
                />
              </Grid>
            </div>

            <div
              className="contactInformation"
              style={{
                marginTop: '20px',
              }}
            >
              <Typography
                fontWeight={500}
                sx={{
                  mb: '12px',
                }}
              >
                {t('Add_Client_Contact_Information')}
              </Typography>
              <Typography
                fontWeight={500}
                fontSize="1rem"
                sx={{
                  mb: '20px',
                }}
              >
                Email List
              </Typography>
              <Grid container alignItems="center">
                <FieldComponent
                  style={{
                    width: 'calc(100% - 60px)',
                  }}
                  name="email"
                  control={control}
                  size="small"
                  label={t('Add_Client_Enter_email_address')}
                  color={toggle ? 'primary' : 'secondary'}
                  value={newEmailAddress}
                  onChange={handleNewEmailAddressChange}
                  placeholder={t('Add_Client_Enter_email_address')}
                  disabled={false}
                  variant="outlined"
                  InputProps={{
                    disabled: !canEdit,
                  }}
                />
                <PlusCircle
                  style={{
                    fontSize: '25px',
                    marginLeft: '19px',
                    marginTop: '-20px',
                    cursor: 'pointer',
                    color: iconcolor ? '#2A59FF' : '#757575',
                  }}
                  onClick={() => {
                    !!canEdit && handleAddEmailAddress();
                  }}
                  weight="fill"
                />
              </Grid>
              <div
                style={{
                  width: '100%',
                  marginBottom: '8px',
                }}
              >
                {emailAddresses.map((email, index) => (
                  <div
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    key={index}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '2px 10px',
                      border: '0.2px solid #adaaaaef',
                      borderRadius: '5px',
                      backgroundColor:
                        hoveredIndex === index ? '#FAFAFA' : 'inherit',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        sx={{
                          marginLeft: '4px',
                        }}
                      >
                        {email}
                      </Typography>
                      {!!canEdit && (
                        <span
                          style={{
                            marginLeft: '8px',
                            fontFamily: 'Roboto',
                            fontSize: '12px',
                            color: '#7d7d7dee',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <PaperPlaneTilt
                            size={12}
                            style={{
                              marginRight: '4px',
                            }}
                          />
                          {t('info-invitation-sent')}
                        </span>
                      )}
                    </div>

                    {!!canEdit && hoveredIndex === index && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <IconButton
                          style={{
                            marginRight: '7px',
                            padding: '2px',
                            marginLeft: '4px',
                          }}
                        >
                          <Tooltip
                            title={
                              flagColors[email] === 'goldenrod'
                                ? 'Remove main'
                                : 'Make as Main'
                            }
                          >
                            <Flag
                              onClick={() => handleFlagIconClick(email)}
                              style={{
                                color: flagColors[email] || 'inherit',
                                fontSize: '14px',
                              }}
                              weight={
                                flagColors[email] === 'goldenrod'
                                  ? 'fill'
                                  : 'regular'
                              }
                            />
                          </Tooltip>
                        </IconButton>

                        <IconButton
                          onClick={() => handleDeleteEmailAddress(index)}
                          style={{
                            color: 'red',
                            padding: '2px',
                          }}
                        >
                          <Tooltip title="Delete">
                            <Trash size={14} style={{ marginRight: '4px' }} />
                          </Tooltip>
                        </IconButton>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div
              className="contactsDetails"
              style={{
                marginTop: '20px',
              }}
            >
              <Typography
                fontWeight={500}
                sx={{
                  marginBottom: '20px',
                }}
              >
                Contact Details
              </Typography>
              <Stack direction="row" gap="20px">
                <PhoneInputField
                  specialLabel={t('Add_Client_Mobile_Phone')}
                  value={phoneValue}
                  disabled={!canEdit}
                  onChange={(value: string) => {
                    setPhoneValue(value);
                  }}
                />
                <PhoneInputField
                  specialLabel={t('Add_Client_Sec_Mobile_Phone')}
                  value={mobilePhone2Value}
                  disabled={!canEdit}
                  onChange={(value: string) => {
                    setMobilePhone2Value(value);
                  }}
                />
              </Stack>
              <Grid container alignItems="center">
                <FieldComponent
                  style={{
                    width: 'calc(50% - 10px)',
                    marginRight: '20px',
                  }}
                  name="managerName"
                  control={control}
                  size="small"
                  label={t('Add_Client_Manager')}
                  placeholder={t('Add_Clients_Manager_Full_Name')}
                  disabled={false}
                  variant="outlined"
                  InputProps={{
                    disabled: !canEdit,
                  }}
                />
                <FieldComponent
                  style={{
                    width: 'calc(50% - 10px)',
                  }}
                  name="managerName2"
                  control={control}
                  size="small"
                  label={t('Add_Client_Secondary_Manager')}
                  placeholder={t('Add_Client_Sec_Man_Full_Name')}
                  disabled={false}
                  variant="outlined"
                  InputProps={{
                    disabled: !canEdit,
                  }}
                />
              </Grid>
              <Grid container alignItems="center">
                <FieldComponent
                  control={control}
                  name="_salesman"
                  label={t('Add_Client_Sales')}
                  defaultValue={customerDetail?._salesman?.id}
                  style={{ width: 'calc(50% - 10px)', marginRight: '20px' }}
                  select
                  InputProps={{
                    disabled: !canEdit,
                  }}
                >
                  {usersData?.docs?.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option?.firstName + ' ' + option?.lastName}
                    </MenuItem>
                  ))}
                </FieldComponent>

                <PhoneInputField
                  specialLabel={t('infotab-sales-phone-number')}
                  value={salesMobilePhoneValue}
                  disabled={!canEdit}
                  onChange={(value: string) => {
                    setSalesManPhoneNumber(value);
                  }}
                />
              </Grid>
            </div>
            <div>
              <Typography
                fontWeight={500}
                sx={{
                  mb: '5px',
                }}
              >
                {t('Client Logo')}
              </Typography>
              <span
                style={{ fontSize: '9.5px', color: '#a9a9a9', padding: '3px' }}
              >
                {t('add-client-logo-description')}
              </span>
              <div
                style={{ height: '80px', width: '80px', marginBottom: '20px' }}
              >
                <UploadZone
                  maxFiles={10}
                  type=""
                  title=""
                  description={t('')}
                  onOk={(files: UploadedFile[]) => {
                    uploadImage(files, customerDetail?.id);
                  }}
                  onUnOk={(rejection: FileRejection) => {
                    toast(5000, 'error', rejection?.errors?.[0]?.message);
                  }}
                  acceptFile={{
                    'image/png': [],
                    'image/jpg': ['.jpeg', '.jpg'],
                    'image/svg+xml': ['.svg'],
                  }}
                  isUploading={isuploadingImage}
                  style={{
                    height: '80px',
                    position: 'relative',
                  }}
                  items={
                    (ImageValue && ImageValue.length > 0) ||
                    customerDetail?.logo.length > 0 ? (
                      <div
                        onMouseEnter={() => setHoverImage(true)}
                        onMouseLeave={() => setHoverImage(false)}
                      >
                        <img
                          src={
                            ImageValue && ImageValue.length > 0
                              ? ImageValue[0]?.url
                              : customerDetail.logo[0]?.url
                          }
                          height="80px"
                          width="80px"
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                          }}
                        />
                        <If condition={hoverImage}>
                          <Grid
                            container
                            alignItems="center"
                            gap="6px"
                            sx={{
                              position: 'absolute',
                              top: 50,
                              left: 15,
                            }}
                          >
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleUploadClick(
                                  e,
                                  getObjectURL(
                                    ImageValue ? ImageValue[0] : null,
                                  ),
                                );
                              }}
                              sx={{
                                ':hover': {
                                  backgroundColor: themes?.default?.lightGrey,
                                },
                                backgroundColor:
                                  themes?.default?.accordionWhiteBg,

                                borderRadius: '8px',
                              }}
                            >
                              <UploadSimple />
                            </IconButton>

                            <IconButton
                              onClick={async (e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                try {
                                  // const fileId = customerDetail?.logo[0]?._id;
                                  // console.log("File ID to delete: ", fileId);
                                  // await deleteFile(fileId);
                                  updateCustomerById({
                                    id: customerDetail?._id,
                                    updateData: { logo: [] },
                                  });
                                  setImage([]);
                                } catch (error) {
                                  console.error('Error deleting file: ', error);
                                }
                              }}
                              size="small"
                              sx={{
                                backgroundColor:
                                  themes?.default?.accordionWhiteBg,
                                borderRadius: '8px',
                                ':hover': {
                                  backgroundColor: themes?.default?.lightGrey,
                                },
                              }}
                            >
                              <TrashSimple
                                color={themes?.default?.progressRed}
                              />
                            </IconButton>
                          </Grid>
                        </If>
                      </div>
                    ) : null
                  }
                />
              </div>
            </div>
            <div
              className="businessInfo"
              style={{
                marginTop: '24px',
              }}
            >
              <Typography
                fontWeight={500}
                sx={{
                  mb: '20px',
                }}
              >
                {t('Add_Client_Business_Information')}
              </Typography>
              <Grid container alignItems="center">
                <FieldComponent
                  style={{ width: 'calc(50% - 10px)', marginRight: '20px' }}
                  name="legalName"
                  control={control}
                  required
                  size="small"
                  label={t('Add_Client_Brand_Name')}
                  placeholder={t('Add_Client_Enter_Legal_Name')}
                  disabled={false}
                  variant="outlined"
                  InputProps={{
                    disabled: !canEdit,
                  }}
                />
                <FieldComponent
                  style={{ width: 'calc(50% - 10px)' }}
                  name="vatNumber"
                  control={control}
                  size="small"
                  label={t('Add_Client_Intracommunity_VAT')}
                  placeholder={t('Add_Client_Enter_VAT_Number')}
                  disabled={false}
                  variant="outlined"
                  InputProps={{
                    disabled: !canEdit,
                  }}
                />
              </Grid>
            </div>
            <div className="settings">
              <Typography
                fontWeight={500}
                sx={{
                  mb: '20px',
                }}
              >
                {t('Add_Client_Settings')}
              </Typography>
              <Box style={{ marginTop: '-10px' }}>
                <FormControlLabel
                  label={t('Add_Client_Show_prices_on_delivery_note')}
                  control={
                    <div style={{ marginLeft: '5px' }}>
                      <Switch
                        checked={switchStateValue}
                        onChange={handleChangeSwitch}
                        size="small"
                        color="primary"
                        disabled={!canEdit}
                      />
                    </div>
                  }
                />
              </Box>
            </div>
          </Grid>

          <Grid
            item
            sx={{
              width: 'calc(50% - 20px)',
              background: 'white',
              border: '0.5px solid    #E0E0E0',
              marginLeft: '20px',
              height: 'fit-content',
              overflowY: 'hidden',
              borderRadius: '4px',
              paddingBottom: '17px',
              position: 'sticky',
              top: '1px',
            }}
          >
            <ClientMap
              latitude={
                customerDetail?.mainLocation?.geometry?.coordinates[1] ||
                DEFAULT_LATITUDE
              }
              longitude={
                customerDetail?.mainLocation?.geometry?.coordinates[0] ||
                DEFAULT_LONGITUDE
              }
              mapHeight="500px"
              mapWidth="calc(100% - 17px)"
              title={t('common.address')}
              setLocationValue={setLocationvalue}
              defaultFieldValue={customerDetail?.mainLocation}
              control={control}
            />
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};
