import { Box, Grid, Typography } from '@mui/material';
import { Check } from '@phosphor-icons/react';
import If from 'app/components/If';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';

export function ActionCard({ isReaden, activity, onCardClick }) {
  const { i18n } = useTranslation();
  const locale = i18n.language;

  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <Box
      onClick={() => onCardClick(activity?._id, activity?.read)}
      width="100%"
      height="fit-content"
      display="flex"
      flexDirection="column"
      padding="8px 12px"
      borderRadius="4px"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        backgroundColor: isReaden
          ? themes?.default?.lightGrey
          : themes?.default?.primaryLight1,
        border: isReaden
          ? `1px solid ${themes?.default?.gainsboro2}`
          : `1px solid ${themes?.default.primary}`,
        cursor: isReaden ? 'default' : 'pointer',
      }}
    >
      <Grid container alignItems="center">
        <Grid item width="calc(100% - 100px)">
          <Typography fontWeight={700} fontSize="12px">
            {activity?.data?.action}
          </Typography>
          <Typography fontWeight={700} fontSize="12px">
            {activity?.data?.i18n?.[locale].title}
          </Typography>
          <Typography fontWeight={400} fontSize="12px">
            {activity?.data?.i18n?.[locale].body}
          </Typography>
        </Grid>
        <Grid item marginLeft="auto">
          <If
            condition={isHovered && !activity?.read}
            otherwise={
              <Typography
                fontWeight={700}
                fontSize="12px"
                color={themes.default.textColorSecondary}
              >
                {activity?.createdAt
                  ? moment(activity?.createdAt).format('HH:mm')
                  : '-:-'}
              </Typography>
            }
          >
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              gap="5px"
              sx={{
                width: '24px',
                height: '24px',
                borderRadius: '6px',
                backgroundColor: themes?.default?.whiteSmokeBg,
                border: `1px solid ${themes?.default?.gainsboro2}`,
              }}
            >
              <Check size={16} color={themes?.default?.grey2} />
            </Grid>
          </If>
        </Grid>
      </Grid>
    </Box>
  );
}
