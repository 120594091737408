import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty, uniqBy } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { Avatar, Tooltip, List } from '@mui/material';
import {
  selectAuthUser,
  selectMainMenuSize,
} from '../../slices/auth/selectors';
import { selectTheme } from '../../../styles/theme/slice/selectors';
import { Popover, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { appActions } from 'common/store/app';
import { useLazyGetOrganizationActivitiesQuery } from 'common/services/organizationApi';
import {
  SETTINGS_COMPANY_PROFILE,
  DASHBOARD_ALL,
  REPORTS_ALL,
  REDIRECTS,
} from 'utils/routes';
import { ActionPopover } from '../Popover';
import BampteeLogo from '../../../assets/img/Menu/bamptee-logo-white.svg';
import BampteeLogoColor from '../../../assets/img/Menu/BampteeLogo.svg';
import OrdoriaLogoInside from 'assets/img/Menu/OrdoriaLogo.svg';
import OrdoriaLogoOutside from 'assets/img/Menu/Logo-Ordoria-lightmode2.svg';
import GescomLogo from '../../../assets/img/Menu/GescomLogo.png';
import CollapseIcon from './Common/ArrowSVG';
import { getInitials } from '../../../common/helpers/organization';
import If from '../If';
import { useSwitchOrganizationMutation } from 'common/services/authApi';
import { themes } from '../../../styles/theme/themes';
import { useApplication } from '../../../hooks/useApplication';
import { BampteeApplications } from 'common/store/app';
import { useToaster } from '../../../hooks/useToaster';
import { useSubscribeObject } from 'common/hooks/ws';
import BiantaMenu from './BiantaMenu';
import OrdoriaMenu from './OrdoriaMenu';
import StockeeMenu from './StockeeMenu';
import IAMMenu from './IAMMenu';
import { CaretDown } from '@phosphor-icons/react';
import EllipsisText from '../EllipsisText';
import { themeActions } from 'styles/theme/slice';
import { useAbility } from 'hooks/Abilities';
import { Can } from '@casl/react';
import { useCreateShortcut } from 'app/pages/RoundTrips/components/KeyboardShortcuts/hooks/useCreateShortcut';
import { SystemIcon } from './Components';
import StockeeLogoLight from '../../../assets/img/Menu/Stockee/StockeeLogoLight.svg';
import StockeeLogoDark from '../../../assets/img/Menu/Stockee/StockeeLogoDark.svg';
import IAMLogoLight from '../../../assets/img/Menu/IAM/IAMLogoLight.svg';
import IAMLogoDark from '../../../assets/img/Menu/IAM/IAMLogoDark.svg';

const ordoriaLogo = {
  inside: OrdoriaLogoInside,
  outside: OrdoriaLogoOutside,
};

const applicationsImg = {
  bianta: BampteeLogo,
  ordoria: ordoriaLogo,
  gescom: GescomLogo,
  stockee: StockeeLogoDark,
  iam: IAMLogoDark,
};

export function isActiveRoute(location: any, path: string) {
  return path === location.pathname;
}

export function isActiveNestedRoute(location: any, paths: string[]) {
  return paths?.includes(location.pathname);
}

export function OrganizationAvatar({
  isActive = false,
  isAllActive = false,
  organization,
  onClick,
  theme,
}: any) {
  const isCurrentOrganization = isActive;

  return (
    <Tooltip title={organization.name} placement="right">
      <Avatar
        onClick={onClick}
        sx={{
          width: '32px',
          height: '32px',
          bgcolor: isCurrentOrganization
            ? themes?.default?.accordionWhiteBg
            : theme.lightBackground,
          mb: '20px',
          border: isCurrentOrganization
            ? `1px solid ${themes?.default?.accordionWhiteBg}`
            : `1px solid ${themes.default.blue50}`,
          cursor: 'pointer',
          outline: isCurrentOrganization
            ? `${
                organization._id !== 'all' && isAllActive
                  ? ''
                  : themes?.default?.accordionWhiteBg
              } solid 1.85px`
            : 'none',
          outlineOffset: isCurrentOrganization ? '4px' : 'none',
          color: isCurrentOrganization
            ? theme.primaryActiveColor
            : themes.default.black,
        }}
        alt={organization.name}
        imgProps={{ sx: { objectFit: 'contain' } }}
      >
        <Typography fontSize="0.75rem" fontWeight="400">
          {!isEmpty(organization.shortname)
            ? organization.shortname
            : getInitials(organization.name)}
        </Typography>
      </Avatar>
    </Tooltip>
  );
}

function LeftNav({
  applicationId,
  organizations,
  currentApplication,
  currentOrganization,
  onSwitchOrganization,
  theme,
  displayAppSelector,
  setDisplayAppSelector,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToaster();
  const location = useLocation();
  const organizationsUniq = uniqBy(organizations, '_id');
  const ability = useAbility();

  const isAllActive =
    location.pathname.includes(DASHBOARD_ALL) ||
    location.pathname.includes(REPORTS_ALL);

  const displayAllOrganizationsDashboard =
    (ability.can('canview', 'dashboard-all') ||
      ability.can('canview', 'reports-all')) &&
    currentOrganization?._organizationCluster;

  const handleSelectApplication = async (applicationId) => {
    setDisplayAppSelector(false);

    const app = BampteeApplications.find((app) => app.id === applicationId);
    if (app) {
      dispatch(appActions.setCurrentApplication({ application: app }));
      //@ts-ignore
      dispatch(themeActions.changeTheme(app.id));
      navigate(`${app?.url || ''}`);
      toast(3000, 'info', 'application.welcome', {
        application: app.displayName,
      });
    }
  };

  const hasAccessToMultipleApplications = useMemo(() => {
    return (
      BampteeApplications.filter((app) => ability.can('access', app.id))
        ?.length > 1
    );
  }, [ability]);

  const handleClickOnApplicationLogo = () => {
    if (!!hasAccessToMultipleApplications) {
      setDisplayAppSelector(true);
    }
  };

  const navigateAllPath = ability.can('canview', 'dashboard-all')
    ? DASHBOARD_ALL
    : REPORTS_ALL;

  useCreateShortcut({
    shortcut: {
      key1: 'Meta',
      key2: 'switch',

      action: {
        callback: (num) => switchApp(num),
        eventName: 'keydown',
      },
    },
  });

  function switchApp(num) {
    switch (num) {
      case '1':
        handleSelectApplication('bianta');
        break;
      case '2':
        handleSelectApplication('ordoria');
        break;
      case '3':
        handleSelectApplication('iam');
        break;
      case '4':
        handleSelectApplication('stockee');
        break;
      default:
        handleSelectApplication('bianta');
    }
  }
  return (
    <>
      <Popover
        id="appSelector"
        open={displayAppSelector}
        onClose={() => setDisplayAppSelector(false)}
        sx={{
          position: 'absolute',
          top: '-2px',
          left: '-6px',
        }}
        PaperProps={{
          style: {
            width: '130px',
            height: 'auto',
            padding: '15px 12px',
            overflow: 'hidden',
            boxSizing: 'border-box',
          },
        }}
      >
        <ImgWrapper>
          <SystemIcon
            access="bianta"
            companyLogoIcon={BampteeLogoColor}
            onClick={() => handleSelectApplication('bianta')}
            title="Bianta"
            switchKey="1"
            hasBorder={currentApplication.id === 'bianta'}
          />
          <SystemIcon
            access="ordoria"
            companyLogoIcon={ordoriaLogo.outside}
            onClick={() => handleSelectApplication('ordoria')}
            title="Ordoria"
            switchKey="2"
            hasBorder={currentApplication.id === 'ordoria'}
          />
          <SystemIcon
            access="stockee"
            companyLogoIcon={IAMLogoLight}
            onClick={() => handleSelectApplication('iam')}
            title="IAM"
            switchKey="3"
            hasBorder={currentApplication.id === 'iam'}
          />
          <SystemIcon
            access="iam"
            companyLogoIcon={StockeeLogoLight}
            onClick={() => handleSelectApplication('stockee')}
            title="Stockee"
            switchKey="4"
            hasBorder={currentApplication.id === 'stockee'}
          />
        </ImgWrapper>
      </Popover>

      <LeftMenu className={applicationId}>
        <Logo onClick={handleClickOnApplicationLogo}>
          {applicationId === 'ordoria' ? (
            <img src={ordoriaLogo.inside} alt={applicationId} />
          ) : (
            <img
              src={applicationsImg[applicationId] || applicationsImg.bianta}
              alt={applicationId}
            />
          )}
        </Logo>
        <AvatarWrapper>
          <AvatarUserWrapper>
            {displayAllOrganizationsDashboard && (
              <OrganizationAvatar
                key="all"
                theme={theme}
                isActive={isAllActive}
                isAllActive={isAllActive}
                organization={{
                  name: 'All',
                  shortname: 'All',
                  _id: 'all',
                }}
                colorIndex={0}
                onClick={() =>
                  navigate(
                    currentApplication.id === 'bianta'
                      ? navigateAllPath
                      : `/${currentApplication.id}${navigateAllPath}`,
                  )
                }
              />
            )}
            {organizationsUniq.map((organization: any, index: number) => {
              return (
                <Tooltip
                  title={currentOrganization.name}
                  placement="right"
                  key={organization._id}
                >
                  <OrganizationAvatar
                    theme={theme}
                    key={organization._id}
                    isAllActive={isAllActive}
                    organization={organization}
                    colorIndex={index + 1}
                    isActive={currentOrganization._id === organization._id}
                    onClick={() => onSwitchOrganization(organization._id)}
                  />
                </Tooltip>
              );
            })}
          </AvatarUserWrapper>
          {/* <div
            className="popover"
            style={{
              borderRadius: '4px',
              backgroundColor: themes?.default?.solitude,
            }}
          >
            <ActionPopover
              iconAction={<AddIcon sx={{ color: theme.primaryActiveColor }} />}
              id={2}
              styling={{
                width: '24px',
                height: '24px',
                justifyContent: 'center',
              }}
              handleAction={(event, action) => {
                if (action === 'create') {
                }
              }}
              anchorOriginVertical={'top'}
              anchorOriginHorizontal={'right'}
              actions={[
                {
                  action: 'SignInOther',
                  element: (
                    <PopoverWrapperActions style={{ width: '232px' }}>
                      <LoginIcon color="action" sx={{ ml: '10px' }} />
                      <Typography sx={{ ml: '8px' }}>
                        Sign In To Another Company
                      </Typography>
                    </PopoverWrapperActions>
                  ),
                },
                {
                  action: 'create',
                  element: (
                    <PopoverWrapperActions style={{ width: '232px' }}>
                      <CreateNewFolderIcon color="action" sx={{ ml: '10px' }} />
                      <Typography sx={{ ml: '8px' }}>
                        Create New Company
                      </Typography>
                    </PopoverWrapperActions>
                  ),
                },
              ]}
            />
          </div> */}
        </AvatarWrapper>
      </LeftMenu>

      <If condition={displayAppSelector}>
        <BlurBackground />
      </If>
    </>
  );
}

export function MainNav() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useSelector(selectTheme);
  const authUser = useSelector(selectAuthUser);
  const mainMenuSize = useSelector(selectMainMenuSize);
  const [displayAppSelector, setDisplayAppSelector] = useState(false);
  const [titlePopoverOpen, setTitlePopoverOpen] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const ability = useAbility();
  const { pathname } = useLocation();
  const currentApplication = useApplication();

  const isDashboardAll =
    pathname ===
    (currentApplication.id === 'bianta'
      ? DASHBOARD_ALL
      : `/${currentApplication.id + DASHBOARD_ALL}`);
  const isReportAll = pathname === REPORTS_ALL;

  const [triggerGetActivities, { data: organizationActivities }] =
    useLazyGetOrganizationActivitiesQuery();

  const [switchOrganization] = useSwitchOrganizationMutation();

  const wsInbox = useSubscribeObject(
    'organization',
    authUser?._currentOrganization._id,
    [
      'new-message',
      'new-conversation',
      'new-customer-document',
      'check-customer-document',
    ],
  );

  useEffect(() => {
    triggerGetActivities();
  }, [wsInbox]);

  const handleDisplayAppSelector = (val) => {
    if (val && authUser) {
      setDisplayAppSelector(true);
    } else {
      setDisplayAppSelector(false);
    }
  };

  const nagivateOnSwitch = () => {
    if (isDashboardAll || isReportAll) {
      navigate(currentApplication?.url || '/');
    } else {
      const app = currentApplication.displayName;
      const redirects = REDIRECTS[app];
      if (redirects) {
        for (const [currPath, targetPath] of Object.entries(redirects)) {
          if (pathname.includes(currPath)) {
            navigate(targetPath as string);
            break;
          }
        }
      }
    }
  };

  const handleSwitchOrganization = async (organizationId: string) => {
    const { user } = await switchOrganization({ organizationId }).unwrap();

    dispatch(
      appActions.setLastToasterNotification({
        duration: 5000,
        severity: 'success',
        message: t('auth.organizationSwitch.success', {
          organizationName: user._currentOrganization.name,
        }),
      }),
    );

    nagivateOnSwitch();
    triggerGetActivities();
  };

  React.useEffect(() => {
    triggerGetActivities();
  }, []);

  if (!authUser) {
    return null;
  }

  const isMinify = mainMenuSize === 'minify';
  const { _organizations, _currentOrganization } = authUser;
  return (
    <WrapperMenu>
      <LeftNav
        applicationId={currentApplication.id}
        organizations={_organizations}
        currentApplication={currentApplication}
        currentOrganization={_currentOrganization}
        onSwitchOrganization={handleSwitchOrganization}
        theme={theme}
        setDisplayAppSelector={handleDisplayAppSelector}
        displayAppSelector={displayAppSelector}
      />
      <List
        sx={{
          width: isMinify ? theme.mainNavWidthMin : theme.mainNavWidth,
          minWidth: isMinify ? '60px' : 'auto',
          maxWidth: isMinify ? '60px' : theme.mainNavWidth,
          bgcolor: 'background.paper',
          filter: displayAppSelector ? 'blur(4)' : 'blur(0)',
          overflow: 'visible',
          transition: '0.15s all ease-out',
          borderRight: `1px solid ${themes?.default?.lightFill}`,
          boxShadow: '0px 3px 15px rgba(139, 139, 139, 0.1)',
        }}
        component="nav"
        style={{ zIndex: 2, position: 'relative' }}
        aria-labelledby="nested-list-subheader"
        subheader={
          <MainNavHeader>
            <Cover>
              {isMinify ? (
                displayAppSelector ? (
                  <CollapseIcon classList="expand blur" />
                ) : (
                  <CollapseIcon classList="expand" />
                )
              ) : displayAppSelector ? (
                <CollapseIcon classList="collapse blur" />
              ) : (
                <CollapseIcon classList="collapse" />
              )}
            </Cover>
            <CompanyWrapper isMinify={isMinify}>
              <div
                onClick={() => setTitlePopoverOpen(!titlePopoverOpen)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {/* {!isMinify && (
                    <Typography
                      fontSize="1.125rem"
                      fontWeight="700"
                      maxWidth="150px"
                    >
                      {_currentOrganization.name}
                    </Typography>
                  )} */}

                <Can I="access" a="idm" ability={ability}>
                  <ActionPopover
                    id={1}
                    handleAction={(event, action) => {
                      if (action === 'settings') {
                        navigate(SETTINGS_COMPANY_PROFILE);
                      }
                    }}
                    iconAction={
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: isMinify ? '4px' : '14px',
                          width: '184px',
                          justifyContent: !isMinify ? 'space-between' : '',
                        }}
                      >
                        <Typography
                          fontSize="1.125rem"
                          fontWeight="700"
                          maxWidth="150px"
                        >
                          <If
                            condition={!isMinify}
                            otherwise={
                              <Tooltip title={_currentOrganization.name}>
                                {_currentOrganization.name.substr(0, 2)}
                              </Tooltip>
                            }
                          >
                            <EllipsisText
                              text={_currentOrganization.name}
                              width={140}
                            />
                          </If>
                        </Typography>
                        <CaretDown
                          weight="bold"
                          size={12}
                          color={themes?.default?.iconColor}
                          style={{
                            transitionDuration: '0.2s',
                            transform: menuOpen ? 'rotate(180deg)' : '',
                          }}
                        />
                      </div>
                    }
                    anchorOriginVertical="bottom"
                    anchorOriginHorizontal={-10}
                    transformOriginHorizontal="left"
                    actions={[
                      {
                        action: 'settings',
                        element: (
                          <PopoverWrapperActions style={{ width: '218px' }}>
                            <Typography sx={{ paddingX: '20px' }}>
                              {t('account_settings')}
                            </Typography>
                          </PopoverWrapperActions>
                        ),
                      },
                      // hide dead link
                      // {
                      //   action: 'customize',
                      //   element: (
                      //     <PopoverWrapperActions style={{ width: '218px' }}>
                      //       <WidgetsIcon color="action" sx={{ ml: '10px' }} />
                      //       <Typography sx={{ ml: '10px' }}>
                      //         Customize {_currentOrganization.name}
                      //       </Typography>
                      //     </PopoverWrapperActions>
                      //   ),
                      // },
                    ]}
                    setMenuOpen={setMenuOpen}
                  />
                </Can>
                <Can not I="access" a="idm" ability={ability}>
                  <Typography
                    fontSize="1.125rem"
                    fontWeight="700"
                    maxWidth="150px"
                  >
                    <If
                      condition={!isMinify}
                      otherwise={
                        <Tooltip title={_currentOrganization.name}>
                          {_currentOrganization.name.substr(0, 2)}
                        </Tooltip>
                      }
                    >
                      <EllipsisText
                        text={_currentOrganization.name}
                        width={140}
                      />
                    </If>
                  </Typography>
                </Can>
              </div>
            </CompanyWrapper>
          </MainNavHeader>
        }
      >
        {currentApplication?.id === 'ordoria' ? (
          <Can I="access" a="ordoria" ability={ability}>
            <OrdoriaMenu
              authUser={authUser}
              theme={theme}
              mode={mainMenuSize}
            />
          </Can>
        ) : currentApplication?.id === 'stockee' ? (
          <Can I="access" a="stockee" ability={ability}>
            <StockeeMenu theme={theme} mode={mainMenuSize} />
          </Can>
        ) : currentApplication?.id === 'iam' ? (
          <Can I="access" a="iam" ability={ability}>
            <IAMMenu authUser={authUser} theme={theme} mode={mainMenuSize} />
          </Can>
        ) : (
          <Can I="access" a="bianta" ability={ability}>
            <BiantaMenu
              authUser={authUser}
              theme={theme}
              mode={mainMenuSize}
              organizationActivities={organizationActivities}
            />
          </Can>
        )}
      </List>
    </WrapperMenu>
  );
}

const MainNavHeader = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 23px;
  flex-direction: column;
  justify-content: center;
`;

interface CompanyWrapperProps {
  isMinify?: boolean;
}
const CompanyWrapper = styled.div<CompanyWrapperProps>`
  display: flex;
  width: ${(props) => (props.isMinify ? '59px' : '223.629px')};
  height: 57px;
  padding: 18px 0px 16px 15px;
  align-items: center;
  flex-shrink: 0;
  .css-yvetkp-MuiButtonBase-root-MuiIconButton-root {
    padding: 0;
  }
  justify-content: flex-start;
`;

const PopoverWrapperActions = styled.div`
  display: flex;
  align-items: center;
`;

const LeftMenu = styled.div`
  z-index: 2;
  display: flex;
  height: 100vh;
  padding: 6px 10px 0px 10px;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  background: ${(props) => props.theme.primary};
  border-right: 1px solid var(--divider, ${themes?.default?.lightFill});
  box-shadow: 0px 3px 15px 0px rgba(139, 139, 139, 0.1);
`;

const WrapperMenu = styled.div`
  display: flex;
`;

const AvatarWrapper = styled.div`
  display: flex;
  overflow: auto;
  width: 150%;
  padding-top: 10px;
  flex-direction: column;
  align-items: center;
`;

const Cover = styled.div`
  .collapse {
    position: absolute;
    top: 20px;
    left: 215px;
    &:hover {
      cursor: pointer;
      transition: all 200ms ease-in;
      transform: scale(1.1);
    }
  }
  .expand {
    position: absolute;
    top: 20px;
    left: 50px;
    transform: rotate(180deg);
    &:hover {
      cursor: pointer;
      transition: all 200ms ease-in;
      transform: rotate(180deg) scale(1.1);
    }
  }
`;

const BlurBackground = styled.div`
  position: fixed;
  z-index: 3;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
`;

const ImgWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 25px;
`;

const Logo = styled.div`
  background: transparent;
  cursor: pointer;
  width: 42px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

const AvatarUserWrapper = styled.div`
  margin-left: 2px;
`;
