import { Drawer, IconButton, Stack, Typography } from '@mui/material';
import { ArrowLeft } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { Tooltip } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionPopover, PopoverEl } from 'app/components/Popover';
import { themes } from 'styles/theme/themes';
import { FieldErrorsImpl } from 'react-hook-form';
import { Accessory, AssigneeType } from 'app/pages/Accessories/Types';
import _ from 'lodash';
import { GetCustomerFrequency } from 'app/pages/Customers/components/CustomerFrequencyIcons';
import { StateSetter } from 'types';
import {
  useGetCustomerFinancialsQuery,
  useLazyGetCustomerQuery,
  useUpdateCustomerMutation,
} from 'common/services/customerApi';
import { CreditScoreBadge } from 'app/components/CreditScoreBadge';
import If from 'app/components/If';
import { GridProvider } from 'app/components/Grid/context';
import { CustomerInfoCard } from './components/CustomerInfoCard';
import { CustomerEventsTab } from './components/Tabs';
import { PlaceProvider } from './context';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import { CustomerStatus } from 'common/types/Customer';
import { CreateOrderDrawerSales } from 'app/pages/Ordoria/Orders/components/CreateOrderDrawerSales';
import { useApplication } from 'hooks/useApplication';

interface ContextProps {
  _assigneeType: AssigneeType | string;
  accessory: Accessory | null;
  assigned: boolean;
  control: any;
  id: string;
  outofservice: boolean;
  setAssigned: StateSetter<boolean>;
  selectedTab: string;
  setSelectedTab: StateSetter<string>;
  errors: Partial<FieldErrorsImpl<any>>;
}

export const ViewCustomercontext = createContext<ContextProps>({
  _assigneeType: '',
  accessory: null,
  assigned: false,
  control: null,
  id: '',
  outofservice: false,
  setAssigned: () => {},
  selectedTab: 'info',
  setSelectedTab: () => {},
  errors: {},
});

export const ViewCustomer = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { id } = useParams();

  const can = usePermission('customers');
  const canEdit = can(Permission.EDIT);
  const currentApplication = useApplication();

  const [openCreateOrderDrawer, setOpenCreateOrderDrawer] = useState(false);
  const [customer, setCustomer] = useState<any>(null);
  const [radioValue, setRadioValue] = useState<string>('delivery');
  const [nextDeliveryDate, setNextDeliveryDate] = useState<string>(
    customer?._organizationDeck?.nextDeliveryDate ?? '',
  );

  const [getCustomer, { data: customerDetail, isLoading }] =
    useLazyGetCustomerQuery();

  const [updateCustomer] = useUpdateCustomerMutation();

  const { data: customerFinancials } = useGetCustomerFinancialsQuery({
    id: id,
  });

  function handleChangeStatus(id: string) {
    updateCustomer({ id, status: CustomerStatus.ARCHIVED })
      .unwrap()
      .then(() => {
        getCustomer(id);
      });
  }

  function handleCreateOrder(customer) {
    setCustomer(customer);
    setOpenCreateOrderDrawer(true);
  }

  useEffect(() => {
    if (id) {
      getCustomer(id);
    }
  }, [id]);

  const handleBack = () => {
    if (currentApplication.id === 'ordoria') {
      navigate('/ordoria/customers');
    } else {
      navigate('/customers');
    }
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <StyledForm>
      <TopSection>
        <Stack direction="row" gap="8px" alignItems="center">
          <IconButton
            onClick={() => handleBack()}
            sx={{
              marginRight: '12px',
            }}
          >
            <Icon icon={<ArrowLeft />} size="24px" />
          </IconButton>
          <Typography fontWeight={600}>{customerDetail?.publicName}</Typography>
          <If condition={customerDetail?.creditScore}>
            <Tooltip title={t('customer.creditScore')}>
              <div>
                <CreditScoreBadge
                  score={customerDetail?.creditScore?.commonValue}
                  size="small"
                />
              </div>
            </Tooltip>
          </If>
          {customerDetail?.cache?.financials?.frequencyStatus === 'noOrder' ? (
            <Tooltip title={t('customer-info-no-orders-yet')}>
              <div>
                {customerDetail?.cache?.financials?.frequencyStatus && (
                  <GetCustomerFrequency
                    size={24}
                    frequency={
                      customerDetail?.cache?.financials?.frequencyStatus
                    }
                  />
                )}
              </div>
            </Tooltip>
          ) : (
            <Tooltip title={t('Order Frequency')}>
              <div>
                {customerDetail?.cache?.financials?.frequencyStatus && (
                  <GetCustomerFrequency
                    size={24}
                    frequency={
                      customerDetail?.cache?.financials?.frequencyStatus
                    }
                  />
                )}
              </div>
            </Tooltip>
          )}
          <If condition={canEdit}>
            <ActionPopover
              id={customerDetail?._id}
              styling={{ marginLeft: 'auto' }}
              actions={[
                {
                  action: 'createOrder',
                  onClick: () => handleCreateOrder(customerDetail),
                  element: (
                    <PopoverEl redStatus="" style={{ width: '100%' }}>
                      <div className="create order popover-item">
                        {t('orders.create_orders')}
                      </div>
                    </PopoverEl>
                  ),
                },
                {
                  action: 'delete',
                  onClick: () => {
                    handleChangeStatus(customerDetail?._id);
                  },
                  element: (
                    <PopoverEl
                      redStatus={themes.default.redStatus}
                      style={{ width: '100%' }}
                    >
                      <div className="delete popover-item">{t('delete')}</div>
                    </PopoverEl>
                  ),
                },
              ]}
            />
          </If>
        </Stack>

        <CustomerInfoCard
          customerDetail={customerDetail}
          customerFinancials={customerFinancials}
        />
      </TopSection>

      <GridProvider>
        <PlaceProvider>
          <CustomerEventsTab customerDetail={customerDetail} />
        </PlaceProvider>
      </GridProvider>

      <Drawer
        anchor="right"
        open={openCreateOrderDrawer}
        onClose={() => setOpenCreateOrderDrawer(false)}
      >
        <DrawerWrapper>
          <CreateOrderDrawerSales
            closeDrawer={() => setOpenCreateOrderDrawer(false)}
            radioValue={radioValue}
            dateValue={nextDeliveryDate}
            setDateValue={setNextDeliveryDate}
            setRadioValue={setRadioValue}
            setNextDeliveryDate={setNextDeliveryDate}
            selectedCustomer={customer?._id}
          />
        </DrawerWrapper>
      </Drawer>
    </StyledForm>
  );
};

const TopSection = styled.div`
  background-color: white;
  padding: 8px 20px 5px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: sticky;
  top: 0;
  z-index: 2;
`;

const StyledForm = styled.form`
  width: 100%;
  overflow-y: hidden;
  height: 100%;
  background-color: ${themes?.default?.pageGreyBg};
`;

const DrawerWrapper = styled.div`
  width: 775px;
`;
