//import sections
import { TrashSimple, UploadSimple } from '@phosphor-icons/react';
import { useUploadMutation } from 'common/services/files';
import {
  Form,
  FormActions,
  FormSection,
  FormWrapper,
} from 'app/components/Form/styles';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Box,
  Typography,
  Grid,
  MenuItem,
} from '@mui/material';
import { FileRejection } from 'react-dropzone';
import { useToaster } from 'hooks/useToaster';
import { UploadZone } from 'app/components/UploadCustomerImage';
import { SubmitButton } from 'app/pages/Accessories/Components/AccessoryForm/Components/SubmitButton';
import { themes } from 'styles/theme/themes';
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import { Switch } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import styled from 'styled-components';
import {
  TextField,
  Autocomplete,
  InputAdornment,
  IconButton,
  Tooltip,
  Button,
} from '@mui/material';
import {
  Trash,
  Flag,
  PlusCircle,
  CrosshairSimple,
} from '@phosphor-icons/react';
import {
  useLazyGetCustomerQuery,
  usePostCustomerMutation,
  useGetCustomerTypesQuery,
  useUpdateCustomerMutation,
} from 'common/services/customerApi';
import { useGetUsersWebQuery } from 'common/services/userApi';
import { LocationInput } from 'app/components/LocationInput';
import { FieldComponent } from 'app/components/FieldComponent';
import { UploadedFile } from 'app/components/UploadComponent';

import {
  TitleField,
  InputFields,
  ClientInformationTitle,
  ClientInformationTitleContainer,
  Gap20,
} from '../styles';
import If from 'app/components/If';
import MapAddClient from 'app/components/MapAddClient/index';
import {
  Position,
  Suggestion,
} from '../ViewCustomer/components/Tabs/BranchForm';
import { ChipWithRadio } from '../ChipWithRadio';
import { CustomerStatus as CustomerStatusEnum } from 'common/types/Customer';
interface Props {
  onClose: () => void;
  customer: any;
  afterSubmit: any;
}

const StyledWrapper = styled.div`
  .special-label {
    font-size: 11px !important;
    color: #b4b3b3;
    position: absolute;
    left: 10px !important;
  }
`;

export const AddCustomers: React.FC<Props> = ({
  customer,
  onClose,
  afterSubmit,
}) => {
  const { data: clientCategoryDetail } = useGetCustomerTypesQuery({});
  const { data: usersData } = useGetUsersWebQuery('?roles=sales');
  const [getCustomer, { data: customerDetail }] = useLazyGetCustomerQuery();
  const [emailAddresses, setEmailAddresses] = useState<string[]>([]);
  const [newEmailAddress, setNewEmailAddress] = useState<string>('');
  const [flagColors, setFlagColors] = React.useState({});
  const [locations, setLocations] = useState<any>([]);
  const [open, setOpen] = useState(true);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
  const [isIconClicked, setIsIconClicked] = useState<boolean>(false);
  const [toggle, setToggle] = useState(false);
  const [iconcolor, setIconcolor] = useState(false);
  const [phoneValue, setPhoneValue] = useState('');
  const [mobilePhone2Value, setMobilePhone2Value] = useState('');
  const [salesMobilePhoneValue, setSalesManPhoneNumber] = useState('');
  const [statusValue, setStatusValue] = useState<{
    label: string;
    value: string;
  } | null>({ label: '', value: '' });
  const [selectedCategoryValue, setSelectedCategoryValue] = useState<{
    label: string;
    value: string;
  } | null>({ label: '', value: '' });
  const [selectedSalesValue, setSelectedSalesValue] = useState<{
    label: string;
    value: string;
  } | null>({ label: '', value: '' });
  const [managernameValue, setmangernameValue] = useState('');
  const [ImageValue, setImage] = useState<UploadedFile[] | null>(null);
  const [mainlocationValue, setLocationvalue] = useState<{
    name: string;
    city: string;
    department: string;
    region: string;
    continent: string;
    zipCode: string;
    formattedAddress: string;
    shortAddress: string;
    countryCode: string;
    geometry: {
      type: string;
      coordinates: number[];
    };
  }>({
    name: '',
    city: '',
    department: '',
    region: '',
    continent: '',
    zipCode: '',
    formattedAddress: '',
    shortAddress: '',
    countryCode: '',
    geometry: {
      type: '',
      coordinates: [],
    },
  });
  const [loading, setLoading] = useState(false);

  const DEFAULT_LATITUDE = 49.029045;
  const DEFAULT_LONGITUDE = 2.8033632;

  const [otherEmails, setOtherEmails] = useState<string[]>([]);
  const [switchStateValue, setSwitchState] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [suggestions, setSuggestions] = useState<any>([]);
  const [latitude, setLatitude] = useState<number | null>(DEFAULT_LATITUDE);
  const [longitude, setLongitude] = useState<number | null>(DEFAULT_LONGITUDE);

  const handleDeleteEmailAddress = (index) => {
    const updatedEmailAddresses = [...emailAddresses];
    updatedEmailAddresses.splice(index, 1);
    setEmailAddresses(updatedEmailAddresses);
  };

  const handleFlagIconClick = (clickedEmail) => {
    const newFlagColors = {};
    for (const email of emailAddresses) {
      newFlagColors[email] = email === clickedEmail ? 'goldenrod' : '';
    }
    setFlagColors(newFlagColors);
  };

  const toast = useToaster();

  const methods = useForm({
    defaultValues: {
      code: '',
      publicName: '',
      legalName: '',
      email: '',
      otherEmails: '',
      vatNumber: '',
      invoicePeriod: '',
      phone: '+33',
      mobilePhone2: '+33',
      _customerType: '',
      _salesman: '',
      status: { label: '', value: '' },
      locations: '',
      managerName: '',
      managerName2: '',
      mainLocation: '',
      switchState: '',
      legalIdAlt: '',
      salePhone: '',
    },
  });

  const { control, watch, reset } = methods;
  const { t } = useTranslation();
  const [Addcustomer] = usePostCustomerMutation();

  const code = watch('code');
  const publicName = watch('publicName');
  const legalName = watch('legalName');
  const vatNumber = watch('vatNumber');
  const managerName2 = watch('managerName2');
  const legalIdAlt = watch('legalIdAlt');

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (customer?._id) {
      getCustomer(customer._id);
    }
  }, [customer?._id, mainlocationValue]);

  const handleSetLocation = (index: number, location: any) => {
    const newLocations = [...locations];
    newLocations[index] = location;
    setLocations(newLocations);
  };

  const isValid = () => {
    const isCodeValid = !!watch('code');
    const isPublicNameValid = !!watch('publicName');
    const isLegalNameValid = !!watch('legalName');
    const ismainLocationValid = query.length;
    const isStatusValid = statusValue?.value.length;
    const isSalesValid = selectedSalesValue?.value.length;
    const isClientgroupValid = selectedCategoryValue?.value.length;

    return (
      isCodeValid &&
      isPublicNameValid &&
      isLegalNameValid &&
      ismainLocationValid &&
      isStatusValid &&
      isSalesValid &&
      isClientgroupValid
    );
  };

  const resetForm = () => {
    setPhoneValue('');
    setEmailAddresses([]);
    setNewEmailAddress('');
    setFlagColors({});
    setLocations([]);
    setPhoneValue('');
    setMobilePhone2Value('');
    setSalesManPhoneNumber('');
    setStatusValue({
      label: '',
      value: '',
    });
    setSelectedCategoryValue({
      label: '',
      value: '',
    });
    setSelectedSalesValue({
      label: '',
      value: '',
    });
    setmangernameValue('');
    setLocationvalue({
      name: '',
      city: '',
      department: '',
      region: '',
      continent: '',
      zipCode: '',
      formattedAddress: '',
      shortAddress: '',
      countryCode: '',
      geometry: {
        type: '',
        coordinates: [],
      },
    });
    reset();
    setQuery('');
    setSwitchState(false);
    setClicked(false);
    setToggle(false);
    setIconcolor(false);
    setLatitude(DEFAULT_LATITUDE);
    setLongitude(DEFAULT_LONGITUDE);
  };
  const [updateCustomer] = useUpdateCustomerMutation();

  const onSubmit = async (options) => {
    if (!isValid()) {
      return;
    }

    try {
      const primaryEmail = emailAddresses.find(
        (email) => flagColors[email] === 'goldenrod',
      );
      setLoading(true);

      const result: any = await Addcustomer({
        publicName,
        code,
        legalName,
        status: statusValue?.value,
        email: primaryEmail,
        otherEmails: otherEmails,
        phone: salesMobilePhoneValue,
        mobilePhone2: mobilePhone2Value,
        mobilePhone: phoneValue,
        vatNumber,
        _customerType: selectedCategoryValue?.value,
        _salesman: selectedSalesValue?.value,
        managerName: managernameValue,
        managerName2: managerName2,
        mainLocation: mainlocationValue,
        switchState: switchStateValue,
        legalIdAlt: legalIdAlt,
      }).unwrap();
      //upload image
      if (ImageValue) {
        const imageResult = await uploadImage(ImageValue[0], result.id);
        await updateCustomer({
          ...result,
          logo: [imageResult],
        });
      }

      setLoading(false);
      afterSubmit({
        close: options?.closeForm,
        newId: options?.refetch ? result?._id : '',
      });
      if (options?.resetForm) {
        resetForm();
      } else {
        handleClose();
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const themegray = createTheme({
    palette: {
      secondary: {
        main: '#b3a9acd3',
      },
    },
  });

  const CustomerStatus: { value: CustomerStatusEnum; label: string }[] = [];
  Object.values(CustomerStatusEnum).forEach((status) => {
    CustomerStatus.push({
      label: t(`customer.status.${status}`),
      value: status,
    });
  });

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleNewEmailAddressChange = (event) => {
    const { value } = event.target;
    setNewEmailAddress(value);
    setIsValidEmail(validateEmail(value));

    const iconColor =
      value && isValidEmail && isIconClicked ? '#2A59FF' : '#757575';
    updateIconColor(iconColor);
  };

  const handleAddEmailAddress = () => {
    const trimmedEmail = newEmailAddress.trim();

    if (isValidEmail && trimmedEmail !== '') {
      const emailExists = emailAddresses.includes(trimmedEmail);
      if (!emailExists) {
        setIsIconClicked(true);
        setEmailAddresses((prevEmailAddresses) => {
          const updatedEmails = [...prevEmailAddresses, trimmedEmail];
          if (updatedEmails.length === 1) {
            setFlagColors({ [trimmedEmail]: 'goldenrod' });
          }
          return updatedEmails;
        });
        setNewEmailAddress('');
        setOtherEmails((prevOtherEmails) => [...prevOtherEmails, trimmedEmail]);

        const iconColor =
          newEmailAddress && isValidEmail ? '#2A59FF' : '#757575';
        updateIconColor(iconColor);
      } else {
        toast(5000, 'error', 'This email address already exists.');
      }
    }
  };
  const updateIconColor = (color) => {
    const plusCircleElement = document.getElementById('plusCircle');
    if (plusCircleElement) {
      plusCircleElement.style.color = color;
      plusCircleElement.style.cursor =
        color === '#2A59FF' ? 'pointer' : 'default';
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);
    setToggle(isValid);
    setIconcolor(isValid);
    return isValid;
  };

  const handleChangeswitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchState(event.target.checked);
  };

  const handleClick1 = async () => {
    if (!clicked) {
      setClicked(true);
      try {
        const position = await new Promise<Position>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        const { latitude, longitude } = position.coords;
        setLatitude(latitude);
        setLongitude(longitude);

        const address = await reverseGeocode(latitude, longitude);
        if (address) {
          setQuery(address.displayName);

          const name = address.displayName;

          const mainLocationValue = {
            ...mainlocationValue,
            name: name,
            city: address.city || '',
            district: address.district || '',
            region: address.region || '',
            countryCode: address.countryCode || '',
            zipCode: address.zipCode || '',
            geometry: {
              type: 'Point',
              coordinates: [longitude, latitude],
            },
          };
          setLocationvalue(mainLocationValue);
          setSuggestions([]);
        } else {
          console.error('No address found for the provided coordinates.');
        }
      } catch (error) {
        console.error('Error getting location:', error);
      }
    } else {
      setClicked(false);
      setQuery('');
    }
  };

  const reverseGeocode = async (latitude, longitude) => {
    try {
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`;
      const response = await fetch(url);
      const data = await response.json();

      return {
        displayName: data.display_name,
        city:
          data.address.city || data.address.town || data.address.village || '',
        district: data.address.suburb || data.address.neighbourhood || '',
        region: data.address.state || '',
        countryCode: data.address.country_code || '',
        zipCode: data.address.postcode || '',
      };
    } catch (error) {
      console.error('Error fetching address:', error);
      return null;
    }
  };

  const geocodingClient = mbxGeocoding({
    accessToken:
      'pk.eyJ1IjoianJleW5hdWQiLCJhIjoiY2xkeGN0OTljMDFoODN3azlkM3UyOXA4dyJ9.gzYtNROqJfITHXZMYsoWvA',
  });

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue = event.target.value;
    setQuery(inputValue);

    try {
      const response = await geocodingClient
        .forwardGeocode({
          query: inputValue,
          limit: 6,
        })
        .send();

      if (response && response.body && response.body.features) {
        const suggestions = response.body.features.map((feature) => ({
          name: feature.place_name,
          coordinates: feature.geometry.coordinates,
        }));

        setLocationvalue((prevState) => ({
          ...prevState,
          city: inputValue,
        }));

        setSuggestions(suggestions);
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const handleSuggestionClick = async (suggestion: Suggestion) => {
    setLatitude(suggestion.coordinates[1]);
    setLongitude(suggestion.coordinates[0]);
    setOpen(true);
    setQuery(suggestion.name);
    setSuggestions([]);
    setClicked(false);

    const response = await geocodingClient
      .forwardGeocode({
        query: suggestion.name,
        limit: 1,
      })
      .send();

    if (response && response.body && response.body.features) {
      const res = response.body.features[0];
      let countryCode = '';
      let regionText = '';
      let zipCode = '';
      let city = '';
      let type = '';
      let coordinates: number[] = [];

      if (res.context) {
        res.context.forEach((item) => {
          if (item.id.includes('country') && !countryCode) {
            countryCode = item.short_code || '';
          } else if (item.id.includes('region') && !regionText) {
            regionText = item.text;
          } else if (item.id.includes('postcode') && !zipCode) {
            zipCode = item.text;
          } else if (item.id.includes('place') && !city) {
            city = item.text;
          }
        });
      }

      if (res.geometry && res.geometry.type && res.geometry.coordinates) {
        type = res.geometry.type;
        coordinates = res.geometry.coordinates;
      }

      setLocationvalue({
        ...mainlocationValue,
        name: res.place_name,
        city: city,
        department: '',
        region: regionText,
        continent: '',
        zipCode: zipCode,
        formattedAddress: '',
        shortAddress: '',
        countryCode: countryCode,
        geometry: {
          type: type,
          coordinates: coordinates,
        },
      });
    }
  };

  const [selectedValue, setSelectedValue] = useState('Client');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  //---------image----------------------
  const getObjectURL = (file: any): any => {
    return file && URL.createObjectURL(file);
  };

  const [uploadFile] = useUploadMutation();

  const [isuploadingImage, setIsuploadingImage] = useState(false);
  const [hoverImage, setHoverImage] = useState<boolean>(false);

  const uploadImage = async (image: File | null, customerId: number) => {
    if (image) {
      setIsuploadingImage(true);
      try {
        const formData = new FormData();
        formData.append('file', image);
        const { size, url } = await uploadFile({
          formData,
          preserveName: false,
          persist: false,
          objectType: 'customers',
          objectId: customerId,
        }).unwrap();
        return { size, url };
      } catch (e) {
        console.error('Error uploading images:', e);
      } finally {
        setIsuploadingImage(false);
      }
    }
  };

  const handleUploadClick = (e, imageUrl) => {
    e.preventDefault();
    e.stopPropagation();
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'image.jpg';
    link.click();
  };

  const disableBtn = !isValid();

  return (
    <ThemeProvider theme={themegray}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormWrapper>
          <FormSection>
            <div className="title-field">
              <div className="title-field" style={{ position: 'relative' }}>
                <Typography
                  fontWeight="500"
                  sx={{
                    mb: '8px',
                  }}
                >
                  {t('add-client-main-details')}
                </Typography>
                <FormControl>
                  <RadioGroup row value={selectedValue} onChange={handleChange}>
                    <FormControlLabel
                      value="Client"
                      control={
                        <ChipWithRadio
                          value="Client"
                          label={t('Client')}
                          selectedValue={selectedValue}
                        />
                      }
                      label=""
                    />
                    <FormControlLabel
                      value="Potential Client"
                      control={
                        <ChipWithRadio
                          value="Potential Client"
                          label={t('add-main-details-potential-client')}
                          selectedValue={selectedValue}
                        />
                      }
                      label=""
                    />
                  </RadioGroup>
                </FormControl>
                <FieldComponent
                  style={{ width: '100%' }}
                  name="legalIdAlt"
                  control={control}
                  size="small"
                  label={t('clientModule.fields.siret')}
                  placeholder={t('Enter SIRET NUMBER')}
                  disabled={false}
                  variant="outlined"
                />
                <Typography
                  fontWeight="500"
                  sx={{
                    mb: '9px',
                  }}
                >
                  {t('Client Information')}
                </Typography>

                <FieldComponent
                  style={{ width: '100%' }}
                  name="publicName"
                  control={control}
                  required
                  size="small"
                  label={t('Name')}
                  placeholder={t('Add_Client_Enter_Public_Name')}
                  disabled={false}
                  variant="outlined"
                />
              </div>
            </div>
            <Grid
              container
              alignItems="center"
              gap="20px"
              style={{
                width: '100%',
              }}
            >
              <FieldComponent
                style={{ width: 'calc(50% - 10px)' }}
                name="code"
                control={control}
                size="small"
                label={t('Code')}
                placeholder={t('Add_Client_Enter_Client_Code')}
                disabled={false}
                variant="outlined"
                required
              />
              <Autocomplete
                options={CustomerStatus}
                value={statusValue}
                style={{ width: 'calc(50% - 10px)' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label={t('Add_Client_Client_Status')}
                    size="small"
                    placeholder={t('Add_Client_Select_Status')}
                    variant="outlined"
                    defaultValue="pending"
                  />
                )}
                onChange={(event, newValue: any) => {
                  setStatusValue(newValue);
                }}
              />
            </Grid>
            <Grid container alignItems="center" gap="20px">
              <Autocomplete
                disablePortal
                id="_customerType"
                options={CustomerStatus}
                disabled={true}
                style={{ width: 'calc(50% - 10px)' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('add-client-category')}
                    name="_customerType"
                    size="small"
                    value={selectedCategoryValue}
                    placeholder={t('Add_Client_Select_Category')}
                    disabled={false}
                    variant="outlined"
                  />
                )}
              />
              <Autocomplete
                disablePortal
                id="Client Group"
                options={
                  Array.isArray(clientCategoryDetail)
                    ? clientCategoryDetail.map((item) => ({
                        label: item.label,
                        value: item._id,
                      }))
                    : []
                }
                value={selectedCategoryValue}
                onChange={(event, newValue) => {
                  setSelectedCategoryValue(
                    newValue || { label: '', value: '' },
                  );
                }}
                style={{ width: 'calc(50% - 10px)' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label={t('add-client-group')}
                    size="small"
                    placeholder={t('Add_Client_Select_Group')}
                    disabled={false}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </FormSection>
          <FormSection>
            <div
              className="title-field"
              style={{
                position: 'relative',
              }}
            >
              <Typography
                fontWeight="500"
                sx={{
                  mb: '9px',
                }}
              >
                {t('Add_Client_Contact_Information')}
              </Typography>
              <TextField
                style={{ width: '100%' }}
                name="mainLocation"
                required
                label={t('Add_Client_Address')}
                size="small"
                type="text"
                value={query}
                onChange={handleInputChange}
                placeholder={t('Add_Client_City,Street,Building')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClick1}
                        sx={{
                          p: '4px',
                        }}
                      >
                        <CrosshairSimple
                          size={25}
                          style={{
                            color: clicked
                              ? themes?.default?.primaryActiveColor
                              : themes?.default?.iconColor,
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  zIndex: 9999,
                  position: 'absolute',
                  backgroundColor: '#fff',
                  boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                  borderRadius: '4px',
                }}
              >
                {suggestions.map((suggestion, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                    sx={{
                      cursor: 'pointer',
                      marginBottom: '5px',
                      color: themes.default.black,
                    }}
                  >
                    <Typography
                      sx={{
                        ':hover': {
                          color: themes.default.primary,
                        },
                        width: '600px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {suggestion.name}
                    </Typography>
                  </MenuItem>
                ))}
              </div>

              {open && (
                <MapAddClient
                  editMode={false}
                  latitude={latitude}
                  longitude={longitude}
                  open={open}
                  handleClose={() => setOpen(false)}
                />
              )}

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  marginBottom: '8px',
                  marginTop: '24px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '8px',
                  }}
                >
                  <FieldComponent
                    style={{
                      width: '620px',
                    }}
                    name="email"
                    control={control}
                    size="small"
                    label={t('Email Addresse(s)')}
                    color={toggle ? 'primary' : 'secondary'}
                    value={newEmailAddress}
                    onChange={handleNewEmailAddressChange}
                    placeholder={t('Add_Client_Enter_email_address')}
                    disabled={false}
                    variant="outlined"
                  />
                  <PlusCircle
                    style={{
                      fontSize: '24px',
                      marginLeft: '8px',
                      marginTop: '-20px',
                      cursor: 'pointer',
                      color: iconcolor ? '#2A59FF' : '#757575',
                    }}
                    onClick={() => {
                      handleAddEmailAddress();
                    }}
                    weight="fill"
                  />
                </div>

                <div
                  style={{
                    width: '100%',
                    marginBottom: '8px',
                  }}
                >
                  {emailAddresses.map((email, index) => (
                    <div
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      key={index}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '2px',
                        border: '0.2px solid #adaaaaef',
                        borderRadius: '5px',
                        backgroundColor:
                          hoveredIndex === index ? '#FAFAFA' : 'inherit',
                      }}
                    >
                      <Typography
                        sx={{
                          ml: '8px',
                        }}
                      >
                        {email}
                      </Typography>

                      {hoveredIndex === index && (
                        <Grid
                          container
                          alignItems="center"
                          gap="8px"
                          sx={{
                            mr: '8px',
                          }}
                        >
                          <IconButton
                            onClick={() => handleFlagIconClick(email)}
                            sx={{
                              p: '2px',
                              marginLeft: 'auto',
                            }}
                          >
                            <Tooltip
                              title={
                                flagColors[email] === 'goldenrod'
                                  ? 'Remove main'
                                  : 'Make as Main'
                              }
                            >
                              <Flag
                                weight={
                                  flagColors[email] === 'goldenrod'
                                    ? 'fill'
                                    : 'regular'
                                }
                                style={{
                                  color: flagColors[email] || 'inherit',
                                  fontSize: '15px',
                                }}
                              />
                            </Tooltip>
                          </IconButton>
                          <IconButton
                            onClick={() => handleDeleteEmailAddress(index)}
                            style={{ color: 'red', padding: '2px' }}
                          >
                            <Tooltip title="Delete">
                              <Trash size={12} />
                            </Tooltip>
                          </IconButton>
                        </Grid>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div style={{ gap: '20px', marginBottom: '16px' }}>
                <TitleField style={{ marginBottom: '16px' }}>
                  <StyledWrapper>
                    <PhoneInput
                      country="fr"
                      specialLabel={t('Add_Client_Mobile_Phone')}
                      inputStyle={{
                        height: '37.13px',
                        width: '320.5px',
                        marginRight: '20px',
                      }}
                      onChange={setPhoneValue}
                      value={phoneValue}
                    />
                  </StyledWrapper>

                  <StyledWrapper>
                    <PhoneInput
                      country="fr"
                      specialLabel={t('Add_Client_Sec_Mobile_Phone')}
                      inputStyle={{
                        height: '37.13px',
                        width: '320.5px',
                      }}
                      onChange={setMobilePhone2Value}
                      value={mobilePhone2Value}
                    />
                  </StyledWrapper>
                </TitleField>
                <TitleField>
                  <InputFields>
                    <div style={{ gap: '20px', display: 'flex' }}>
                      <FieldComponent
                        style={{ width: '320.5px' }}
                        name="ClientManager"
                        control={control}
                        size="small"
                        label={t('Add_Client_Manager')}
                        placeholder={t('Add_Clients_Manager_Full_Name')}
                        disabled={false}
                        variant="outlined"
                        onChange={(event) =>
                          setmangernameValue(event.target.value)
                        }
                        value={managernameValue}
                      />
                      <FieldComponent
                        style={{ width: '320.5px' }}
                        name="secmanagername"
                        control={control}
                        size="small"
                        label={t('Add_Client_Secondary_Manager')}
                        placeholder={t('Add_Client_Sec_Man_Full_Name')}
                        disabled={false}
                        variant="outlined"
                      />
                    </div>
                  </InputFields>
                </TitleField>
                <TitleField style={{ gap: '5px' }}>
                  <InputFields>
                    <div style={{ gap: '20px', display: 'flex' }}>
                      <Autocomplete
                        disablePortal
                        id="_salesman"
                        options={
                          usersData && Array.isArray(usersData.docs)
                            ? usersData.docs.map((user) => ({
                                label: `${user.firstName} ${user.lastName}`,
                                value: user.id,
                              }))
                            : []
                        }
                        onChange={(event, newValue) => {
                          setSelectedSalesValue(newValue || null);
                        }}
                        value={selectedSalesValue}
                        sx={{ width: '320.5px' }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label={t('Add_Client_Sales')}
                            style={{ width: '320.5px' }}
                            size="small"
                            placeholder={t('Add-Client-Select-Sales')}
                            disabled={false}
                            variant="outlined"
                          />
                        )}
                      />
                      <StyledWrapper>
                        <PhoneInput
                          country="fr"
                          specialLabel={t('add-client-sales-number')}
                          inputStyle={{
                            height: '37.13px',
                            width: '320.5px',
                          }}
                          onChange={setSalesManPhoneNumber}
                          value={salesMobilePhoneValue}
                        />
                      </StyledWrapper>
                    </div>
                  </InputFields>
                </TitleField>
              </div>
            </div>
          </FormSection>
          <FormSection>
            <div>
              <div className="title-field" style={{ position: 'relative' }}>
                <ClientInformationTitleContainer>
                  <ClientInformationTitle style={{ width: '200px' }}>
                    {t('add-client-logo')}
                  </ClientInformationTitle>
                </ClientInformationTitleContainer>
                <span style={{ fontSize: '9.5px', color: '#a9a9a9' }}>
                  {t('add-client-logo-description')}
                </span>
              </div>
              <div
                style={{ height: '80px', width: '80px', marginBottom: '20px' }}
              >
                <UploadZone
                  maxFiles={10}
                  type=""
                  title=""
                  description={t('')}
                  onOk={(files: UploadedFile[]) => {
                    setImage(files);
                  }}
                  onUnOk={(rejection: FileRejection) => {
                    toast(5000, 'error', rejection?.errors?.[0]?.message);
                  }}
                  acceptFile={{
                    'image/png': [],
                    'image/jpg': ['.jpeg', '.jpg'],
                    'image/svg+xml': ['.svg'],
                  }}
                  isUploading={isuploadingImage}
                  style={{
                    height: '80px',
                    position: 'relative',
                  }}
                  items={
                    ImageValue && ImageValue[0] ? (
                      <div
                        onMouseEnter={() => setHoverImage(true)}
                        onMouseLeave={() => setHoverImage(false)}
                      >
                        <img
                          src={getObjectURL(ImageValue ? ImageValue[0] : null)}
                          height="80px"
                          width="80px"
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                          }}
                        />
                        <If condition={hoverImage}>
                          <Grid
                            container
                            alignItems="center"
                            gap="6px"
                            sx={{
                              position: 'absolute',
                              top: 50,
                              left: 15,
                            }}
                          >
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleUploadClick(
                                  e,
                                  getObjectURL(
                                    ImageValue ? ImageValue[0] : null,
                                  ),
                                );
                              }}
                              sx={{
                                ':hover': {
                                  backgroundColor: themes?.default?.lightGrey,
                                },
                                backgroundColor:
                                  themes?.default?.accordionWhiteBg,

                                borderRadius: '8px',
                              }}
                            >
                              <UploadSimple />
                            </IconButton>
                            <IconButton
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setImage(null);
                              }}
                              size="small"
                              sx={{
                                backgroundColor:
                                  themes?.default?.accordionWhiteBg,
                                borderRadius: '8px',
                                ':hover': {
                                  backgroundColor: themes?.default?.lightGrey,
                                },
                              }}
                            >
                              <TrashSimple
                                color={themes?.default?.progressRed}
                              />
                            </IconButton>
                          </Grid>
                        </If>
                      </div>
                    ) : null
                  }
                />
              </div>
            </div>
          </FormSection>

          <FormSection>
            <div
              className="subtitle"
              style={{ fontWeight: 'bold', width: '651px' }}
            >
              <ClientInformationTitleContainer>
                <ClientInformationTitle style={{ width: '200px' }}>
                  {t('Add_Client_Business_Information')}
                </ClientInformationTitle>
              </ClientInformationTitleContainer>
              <div style={{ display: 'flex', gap: '21px' }}>
                <TitleField>
                  <InputFields>
                    <FieldComponent
                      style={{ width: '320.5px' }}
                      name="legalName"
                      control={control}
                      required
                      size="small"
                      label={t('Add_Client_Brand_Name')}
                      placeholder={t('Add_Client_Enter_Legal_Name')}
                      disabled={false}
                      variant="outlined"
                    />
                  </InputFields>
                </TitleField>
                <TitleField>
                  <InputFields>
                    <FieldComponent
                      style={{ width: '320.5px' }}
                      name="vatNumber"
                      control={control}
                      size="small"
                      label={t('Add_Client_Intracommunity_VAT')}
                      placeholder={t('Add_Client_Enter_VAT_Number')}
                      disabled={false}
                      variant="outlined"
                    />
                  </InputFields>
                </TitleField>
              </div>
            </div>
          </FormSection>
          <FormSection>
            <div className="title-field" style={{ width: '651px' }}>
              <ClientInformationTitleContainer>
                <ClientInformationTitle>
                  {t('Add_Client_Settings')}
                </ClientInformationTitle>
              </ClientInformationTitleContainer>{' '}
              <Box>
                <FormControlLabel
                  label={t('Add_Client_Show_prices_on_delivery_note')}
                  control={
                    <div style={{ marginLeft: '5px' }}>
                      <Switch
                        checked={switchStateValue}
                        onChange={handleChangeswitch}
                        size="small"
                        color="primary"
                      />
                    </div>
                  }
                />
              </Box>
              <Gap20 />
              {customerDetail?.decks &&
                customerDetail.decks.map((deck: any, index) => (
                  <LocationInput
                    key={`locationInput${deck._id}}`}
                    label={deck.code}
                    defaultValue={deck?.location}
                    size="small"
                    onSelect={(loc) => handleSetLocation(index, loc)}
                  />
                ))}
            </div>
          </FormSection>
        </FormWrapper>
        <FormActions>
          <Button
            disableElevation
            sx={{ color: themes.default.black60 }}
            onClick={handleClose}
          >
            {t('common.buttons.cancel')}
          </Button>

          <SubmitButton
            loading={loading}
            onSubmit={onSubmit}
            disabled={disableBtn}
            isEdit={false}
            withOptions={true}
          />
        </FormActions>
      </Form>
    </ThemeProvider>
  );
};
