import { PopoverEl, PopOverElement } from 'app/components/Popover';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useStatusActions } from './hook/useStatusActions';
import { NestedMenuComponent } from 'app/components/NestedMenu';
import {
  useCalculateRoundtripRouteMutation,
  useDeleteRoundTripMutation,
  useRoundtripUpdateStatusMutation,
} from 'common/services/roundtripApi';
import { useToaster } from '../../../../../../hooks/useToaster';
import { useModal } from 'app/components/Dialog/hooks';
import { deleteFunction } from 'app/components/RoundTrips/function/ApiFunctions';
import { useTranslation } from 'react-i18next';
import { usePrint, usePrintV2 } from 'hooks/RoundTrips/usePrint';
import { toQueryParams } from '../../../../../../hooks/useQueryParams';
import { useAbility } from 'hooks/Abilities';
import { MenuItem } from '@mui/material';
import NestedMenuItem from 'mui-nested-menu-item';
import styled from 'styled-components';
import { PopOverElWrapper } from 'app/pages/AddRoundTrips/data/RoundTripData/components/Popover';
import { CaretRight } from '@phosphor-icons/react';
import { themes } from 'styles/theme/themes';
import { NestedItemsComponent } from 'app/components/NestedMenu/NestedItemsComponent';

interface Props {
  roundtrip: any;
  setRoundtripEdit?: any;
  functionRoundTripStatus: any;
  handleClickOpen: any;
  handleClickOpenDelete: any;
  typeEdit?: any;
  icon?: JSX.Element;
  openByDefault?: boolean;
  handleParentClose?: () => void;
  anchorOriginVertical?: 'center' | 'top' | 'bottom' | number;
  anchorOriginHorizontal?: 'center' | 'left' | 'right' | number;
  transformOriginHorizontal?: 'center' | 'left' | 'right' | number;
  transformOriginVerical?: 'center' | 'top' | 'bottom' | number;
}

export const PopoverAction: React.FC<Props> = ({
  roundtrip,
  setRoundtripEdit = () => {},
  functionRoundTripStatus,
  handleClickOpen,
  typeEdit,
  icon,
  openByDefault = false,
  handleParentClose,
  anchorOriginVertical,
  anchorOriginHorizontal,
  transformOriginHorizontal,
  transformOriginVerical,
}) => {
  const ability = useAbility();
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);
  const toast = useToaster();
  const [roundTripStatus] = useRoundtripUpdateStatusMutation();

  const [calculate] = useCalculateRoundtripRouteMutation();
  const [status, setStatus] = useState<any[]>([]);
  useStatusActions(
    setStatus,
    roundtrip,
    functionRoundTripStatus,
    roundTripStatus,
  );

  const { openModal, closeModal } = useModal();
  const [DeleteRoundtrip] = useDeleteRoundTripMutation();

  const handleDelete = () => {
    deleteFunction(roundtrip?.id, DeleteRoundtrip);
    toast(3000, 'success', t('alerts.roundtrip.delete'));
    closeModal();
  };

  const handleOpenDeleteModal = () => {
    openModal({
      action: {
        actionText: t('common.remove'),
        actionCallback: handleDelete,
      },
      title: `${t('roundtrips.edit_roundtrip.confirm_delete')} [${
        roundtrip?.code
      }]?`,
      content: t('roundtrips.edit_roundtrip.description_delete'),
      deleteModal: true,
      cancel: true,
    });
  };

  const handleCalculateRoute = async () => {
    await calculate({
      roundtripId: roundtrip._id,
    }).unwrap();

    toast(3000, 'info', 'roundtrip.calculateRouteSuccess');
  };

  const [clusterId, setClusterId] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setClusterId(urlParams.get('cluster')!.replaceAll('"', ''));
  }, []);

  const { triggerPrint } = usePrint();
  const { triggerPrint: triggerPrint2 } = usePrintV2();

  const handlePrintDeliveryNotes = () => {
    const orderIds = roundtrip.stops?.map((stop) => stop._order?.id);
    const query = toQueryParams({
      format: 'pdf',
      orderIds: orderIds.join(','),
    });

    triggerPrint2({
      uri: `orders/delivery-notes${query}`,
    });
  };

  const handlePrintPreparationNotes = () => {
    const orderIds = roundtrip.stops?.map((stop) => stop._order?.id);
    const query = toQueryParams({
      format: 'pdf',
      orderIds: orderIds.join(','),
    });

    triggerPrint2({
      uri: `orders/preparation-notes${query}`,
    });
  };

  const actions: any =
    typeEdit === true
      ? [
          {
            action: 'status',
            nested: true,
            actions: status,
            onClick: () => {
              setRoundtripEdit(roundtrip);
            },
            element: (
              <PopoverEl redStatus={theme.redStatus} style={{ width: '100%' }}>
                <span className="popover-item">
                  {t('orders.change_status')}
                </span>
              </PopoverEl>
            ),
          },
          {
            action: 'compute-directions',
            onClick: () => {
              handleCalculateRoute();
            },
            element: (
              <PopoverEl redStatus={theme.redStatus} style={{ width: '100%' }}>
                <div className="popover-item">
                  {t('roundtrip.calculateRoute')}
                </div>
              </PopoverEl>
            ),
          },
          ability.can('canexport', 'roundtrips')
            ? {
                action: 'print',
                nested: true,
                actions: [
                  {
                    action: 'print-roadmap',
                    element: (
                      <PopoverEl
                        onClick={(e) => {
                          e.preventDefault();
                          triggerPrint({
                            clusterId,
                            roundtripId: roundtrip._id,
                          });
                        }}
                        redStatus={theme.redStatus}
                        style={{ width: '100%' }}
                      >
                        <div className="popover-item">
                          {t('common.buttons.print_roadmap')}
                        </div>
                      </PopoverEl>
                    ),
                  },
                  {
                    action: 'print-deliveryNotes',
                    element: (
                      <PopoverEl
                        onClick={() => handlePrintDeliveryNotes()}
                        redStatus={theme.redStatus}
                        style={{ width: '100%' }}
                      >
                        <div className="popover-item">
                          {t('common.buttons.print_delivery_notes')}
                        </div>
                      </PopoverEl>
                    ),
                  },
                  {
                    action: 'print-preparationNotes',
                    element: (
                      <PopoverEl
                        onClick={() => handlePrintPreparationNotes()}
                        redStatus={theme.redStatus}
                        style={{ width: '100%' }}
                      >
                        <div className="popover-item">
                          {t('print.preparation-note-button')}
                        </div>
                      </PopoverEl>
                    ),
                  },
                ],
                onClick: () => {
                  setRoundtripEdit(roundtrip);
                },
                element: (
                  <PopoverEl
                    redStatus={theme.redStatus}
                    style={{ width: '100%' }}
                  >
                    <span className="popover-item">{t('orders.print')}</span>
                  </PopoverEl>
                ),
              }
            : {},
        ]
      : [
          {
            action: 'delete',
          },
        ];

  actions.push(
    {
      action: 'clear',
      onClick: () => {
        setRoundtripEdit(roundtrip);
        handleClickOpen();
      },
      element: (
        <PopoverEl redStatus={theme.redStatus} style={{ width: '100%' }}>
          <span className="popover-item">{t('clear_stop')}</span>
        </PopoverEl>
      ),
    },
    {
      seperator: true,
      action: 'separator',
      element: (
        <PopoverEl redStatus={theme.redStatus}>
          <div className="seperator" />
        </PopoverEl>
      ),
    },
    {
      action: 'delete',
      onClick: () => {
        setRoundtripEdit(roundtrip);

        handleOpenDeleteModal();
      },
      element: (
        <PopoverEl redStatus={theme.redStatus} style={{ width: '100%' }}>
          <div className="delete popover-item">{t('delete')}</div>
        </PopoverEl>
      ),
    },
  );

  if (openByDefault) {
    return (
      <div
        style={{ width: '100%' }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {actions.map((action, index) => {
          if (action?.element) {
            return (
              <div
                key={index}
                style={{ width: '100% !important' }}
                onClick={() => {
                  action.onClick && action.onClick();
                }}
              >
                {action?.nested ? (
                  // @ts-ignore
                  <MenuWrapper
                    label={action.element}
                    parentMenuOpen={true}
                    placeholder=""
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                    sx={{
                      paddingLeft: '0 !important',
                      paddingRight: '32px !important',
                      width: 'fit-content',
                    }}
                    rightIcon={
                      <CaretRight
                        color={themes.default.iconColor}
                        size={14}
                        weight="bold"
                      />
                    }
                    disableRipple={!!action?.disableRipple}
                  >
                    <NestedItemsComponent
                      action={action}
                      handleItemClick={() => {}}
                      handleAction={() => {}}
                      handleActionclick={() => {}}
                      handleClose={handleParentClose}
                      id={roundtrip?.id}
                    />
                  </MenuWrapper>
                ) : (
                  <>
                    {action?.seperator ? (
                      <div style={{ margin: '4px 10px' }}>{action.element}</div>
                    ) : (
                      <MenuItem
                        onClick={() => {}}
                        sx={{ padding: '0 !important' }}
                        disableRipple={!!action?.seperator}
                      >
                        <PopOverElement
                          primaryActiveColor={theme.primaryActiveColor}
                          className={
                            action?.seperator
                              ? 'seperator'
                              : action?.action === 'delete'
                                ? 'delete'
                                : ''
                          }
                          style={{
                            height: action?.seperator ? 'auto' : '',
                          }}
                          onClick={() => {
                            // action.onClick && action.onClick();
                          }}
                        >
                          <PopOverElWrapper>{action.element}</PopOverElWrapper>
                        </PopOverElement>
                      </MenuItem>
                    )}
                  </>
                )}
              </div>
            );
          }
        })}
      </div>
    );
  } else {
  }
  return (
    <div
      style={{ width: '100%' }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <NestedMenuComponent
        id={roundtrip.id}
        actions={actions}
        iconAction={icon}
        anchorOriginVertical={anchorOriginVertical}
        anchorOriginHorizontal={anchorOriginHorizontal}
        transformOriginHorizontal={transformOriginHorizontal}
        transformOriginVerical={transformOriginVerical}
      />
    </div>
  );
};

const MenuWrapper = styled(NestedMenuItem)(() => ({
  width: '100% !important',

  '& li': {
    width: '100% !important',
    padding: '0 !important',
  },
  '& div': {
    width: '100% !important',
  },
  '&:hover': { background: themes.default.grey },
  '&:focus': { background: themes.default.grey },
}));
