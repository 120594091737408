import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

import {
  SquaresFour,
  ClipboardText,
  PresentationChart,
  Hamburger,
  MapPinLine,
  Package,
  UsersFour,
  UsersThree,
  CurrencyEur,
  Percent,
  ChartPieSlice,
} from '@phosphor-icons/react';

import { MainNavItem, ScrollableSection, OptionsWrapper } from '../helpers';
import {
  CUSTOMER,
  DASHBOARD_ALL,
  STOCKEE,
  REPORTS,
  REPORTS_ALL,
} from '../../../../utils/routes';
import { themes } from '../../../../styles/theme/themes';
import { Icon } from 'app/components/Icon';
import { isActiveRoute } from '../index';
import { IconWrapper } from '../../../../styles/components/muitable/TableViewPopover';
import IfUserAcl from '../../IfUserAcl';
import { MenuTitleWrapper } from '../Components';

const dashboardAll = `${STOCKEE}${DASHBOARD_ALL}`;
const reportAll = `${STOCKEE}${REPORTS_ALL}`;

export default function StockeeMenu({ theme, mode }) {
  const { t } = useTranslation();
  const location = useLocation();
  const isDashboardAll = location.pathname === dashboardAll;
  const isReportAll = location.pathname === reportAll;
  const isMinify = mode === 'minify';
  const [openSectionIndex, setOpenSectionIndex] = useState<number[]>([0]);

  const handleRowToggle = (index: number) => {
    setOpenSectionIndex((prevIndices) => {
      if (prevIndices.includes(index)) {
        return prevIndices.filter((i) => i !== index);
      } else {
        return [index];
      }
    });
  };

  if (isDashboardAll || isReportAll) {
    return (
      <OptionsWrapper>
        <MenuTitleWrapper
          isMinify={isMinify}
          onClick={() => handleRowToggle(0)}
          openSectionIndex={openSectionIndex}
          theme={theme}
          title={t('nav.mainNav.overview')}
          index={0}
        >
          <MainNavItem
            to={dashboardAll}
            text={t('nav.mainNav.dashboard')}
            mode={mode}
            icon={
              <Icon
                icon={<SquaresFour />}
                color={
                  location.pathname === dashboardAll
                    ? theme.primaryActiveColor
                    : themes?.default?.black54
                }
                size={20}
              />
            }
          />

          <MainNavItem
            to={reportAll}
            text={t('nav.mainNav.reports')}
            mode={mode}
            icon={
              <IconWrapper isActive={isActiveRoute(location, reportAll)}>
                <PresentationChart />
              </IconWrapper>
            }
          />
        </MenuTitleWrapper>
      </OptionsWrapper>
    );
  }

  return (
    <ScrollableSection>
      <OptionsWrapper>
        <MenuTitleWrapper
          isMinify={isMinify}
          onClick={() => handleRowToggle(0)}
          openSectionIndex={openSectionIndex}
          theme={theme}
          title={t('nav.mainNav.overview')}
          index={0}
        >
          <MainNavItem
            to={STOCKEE}
            text={t('nav.mainNav.dashboard')}
            mode={mode}
            icon={
              <Icon
                icon={<ChartPieSlice />}
                color={
                  location.pathname === '/stockee'
                    ? theme.primaryActiveColor
                    : themes?.default?.black54
                }
                size={20}
              />
            }
          />

          <IfUserAcl acl={['reports']}>
            <MainNavItem
              to={`/stockee${REPORTS}`}
              text={t('nav.mainNav.reports')}
              mode={mode}
              icon={
                <IconWrapper isActive={isActiveRoute(location, REPORTS)}>
                  <PresentationChart
                    color={
                      location.pathname === '/stockee/reports'
                        ? theme.primaryActiveColor
                        : themes?.default?.black54
                    }
                  />
                </IconWrapper>
              }
            />
          </IfUserAcl>
        </MenuTitleWrapper>
      </OptionsWrapper>

      <OptionsWrapper>
        <MenuTitleWrapper
          isMinify={isMinify}
          onClick={() => handleRowToggle(1)}
          openSectionIndex={openSectionIndex}
          theme={theme}
          title={t('nav.mainNav.stock')}
          index={1}
        >
          <MainNavItem
            to="/stockee/products"
            text={t('nav.mainNav.products')}
            mode={mode}
            icon={
              <IconWrapper isActive={isActiveRoute(location, CUSTOMER)}>
                <Hamburger
                  color={
                    location.pathname === '/stockee/products'
                      ? theme.primaryActiveColor
                      : themes?.default?.black54
                  }
                />
              </IconWrapper>
            }
          />

          <MainNavItem
            to="/stockee/storage"
            text={t('nav.mainNav.storage_location')}
            mode={mode}
            icon={
              <MapPinLine
                color={
                  location.pathname === '/stockee/storage'
                    ? theme.primaryActiveColor
                    : themes?.default?.black54
                }
              />
            }
          />
          <MainNavItem
            to="/stockee/stock"
            text={t('nav.mainNav.stock')}
            mode={mode}
            icon={
              <Package
                color={
                  location.pathname === '/stockee/stock'
                    ? theme.primaryActiveColor
                    : themes?.default?.black54
                }
              />
            }
          />
        </MenuTitleWrapper>
      </OptionsWrapper>
      <OptionsWrapper>
        <MenuTitleWrapper
          isMinify={isMinify}
          onClick={() => handleRowToggle(3)}
          openSectionIndex={openSectionIndex}
          theme={theme}
          title={t('nav.mainNav.relations')}
          index={3}
        >
          <MainNavItem
            to="/stockee/suppliers"
            text={t('nav.mainNav.suppliers')}
            mode={mode}
            icon={
              <Icon
                icon={<ClipboardText />}
                color={
                  location.pathname === '/stockee/suppliers'
                    ? theme.primaryActiveColor
                    : themes?.default?.black54
                }
                size={20}
              />
            }
          />
          <MainNavItem
            to="/stockee/client_group"
            text={t('nav.mainNav.client_group')}
            mode={mode}
            icon={
              <Icon
                icon={<UsersFour />}
                color={
                  location.pathname === '/stockee/client_group'
                    ? theme.primaryActiveColor
                    : themes?.default?.black54
                }
                size={20}
              />
            }
          />
          <MainNavItem
            to="/stockee/clients"
            text={t('nav.mainNav.clients')}
            mode={mode}
            icon={
              <Icon
                icon={<UsersThree />}
                color={
                  location.pathname === '/stockee/clients'
                    ? theme.primaryActiveColor
                    : themes?.default?.black54
                }
                size={20}
              />
            }
          />
        </MenuTitleWrapper>
      </OptionsWrapper>
      <MenuTitleWrapper
        isMinify={isMinify}
        onClick={() => handleRowToggle(4)}
        openSectionIndex={openSectionIndex}
        theme={theme}
        title={t('nav.mainNav.pricing')}
        index={4}
      >
        <MainNavItem
          to="/stockee/pricing"
          text={t('nav.mainNav.pricing')}
          mode={mode}
          icon={
            <Icon
              icon={<CurrencyEur />}
              color={
                location.pathname === '/stockee/pricing'
                  ? theme.primaryActiveColor
                  : themes?.default?.black54
              }
              size={20}
            />
          }
        />
        <MainNavItem
          to="/stockee/discount_grid"
          text={t('nav.mainNav.discount_grid')}
          mode={mode}
          icon={
            <Icon
              icon={<Percent />}
              color={
                location.pathname === '/stockee/discount_grid'
                  ? theme.primaryActiveColor
                  : themes?.default?.black54
              }
              size={20}
            />
          }
        />
      </MenuTitleWrapper>
    </ScrollableSection>
  );
}
