import React, { useEffect, useRef, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOnScreen } from 'app/components/ProductCard/hook';
import { themes } from 'styles/theme/themes';
import { ChatContext } from 'app/components/Chat';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import {
  Wrapper,
  MessageContainer,
  MessageOptionsMenuContainer,
  FileMessageWrapper,
  MessageWithReplyWrapper,
  ReplyWrapper,
} from '../ChatMessage';
import { MessageOptionsMenu } from '../MessageOptionsMenu';
import EllipsisText from 'app/components/EllipsisText';
import { FileIcon } from 'app/components/Chat/components/ChatWindow/components/FileIcon';
import { ActionsHandleProps } from 'app/components/Chat/components/ChatWindow';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { StateSetter } from 'types';
import { SelectionCheckbox } from '../../../SelectionCheckbox';

dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

interface Props {
  messageUuid: string;
  type: 'sent' | 'received';
  similarToPrevMessage: boolean;
  content: string;
  name?: string;
  description?: string;
  contentType: string;
  createdAt: string;
  handleMessageActions: (action: ActionsHandleProps) => void;
  isEdited: boolean;
  reply?: any;
  senderFullName?: string;
  handleScrollToMessage?: (messageUuid) => void;
  highlightMessageUuid?: string;
  setHighlightMessageUuid?: StateSetter<string>;
  selectedMessagesUuid?: string[];
  handleToggleSelect?: (messageUuid: string, selected: boolean) => void;
}

export const LastChatMessage: React.FC<Props> = ({
  messageUuid,
  type,
  similarToPrevMessage,
  content,
  name,
  description,
  contentType,
  createdAt,
  handleMessageActions,
  isEdited,
  reply,
  senderFullName,
  handleScrollToMessage,
  highlightMessageUuid,
  setHighlightMessageUuid,
  selectedMessagesUuid,
  handleToggleSelect,
}) => {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);
  const { setIsLastMessageReached } = useContext(ChatContext);

  const [isHighlighted, setIsHighlighted] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const formatTimestamp = (timestamp) => {
    const date = dayjs(timestamp);

    if (date.isToday()) {
      return date.format('HH:mm');
    } else if (date.isYesterday()) {
      return t('yesterday');
    } else {
      return date.format('D MMMM, YYYY');
    }
  };

  const lastMessageRef: any = useRef(null);
  const isOnScreen = useOnScreen(lastMessageRef);

  useEffect(() => {
    setIsLastMessageReached(isOnScreen);
  }, [isOnScreen]);

  const canEdit = useMemo(() => {
    return type === 'sent';

    // const allowedEditDurationInMinutes = 10;
    // const editTimeUnix = allowedEditDurationInMinutes * 60 * 1000;

    // const nowUnix = new Date().getTime();
    // const createdAtUnix = new Date(createdAt).getTime();

    // if (nowUnix - editTimeUnix > createdAtUnix) {
    //   return false;
    // } else {
    //   return true;
    // }
  }, [contentType, type, createdAt]);

  const fileType = useMemo(() => {
    if (name) {
      const splittedFileName = name.split('.');
      return splittedFileName[splittedFileName?.length - 1];
    } else if (content) {
      const splittedFileName = content.split('.');
      return splittedFileName[splittedFileName?.length - 1];
    } else {
      return '';
    }
  }, [name, content]);

  useEffect(() => {
    if (!highlightMessageUuid || !messageUuid) return;
    if (highlightMessageUuid === messageUuid) {
      setIsHighlighted(true);
    }
  }, [highlightMessageUuid]);

  useEffect(() => {
    if (!isHighlighted) return;

    const timeoutId = setTimeout(() => {
      setIsHighlighted(false);
      setHighlightMessageUuid && setHighlightMessageUuid('');
    }, 4000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isHighlighted]);

  useEffect(() => {
    if (selectedMessagesUuid?.length === 0) {
      setIsSelected(false);
    } else if (selectedMessagesUuid?.some((uuid) => uuid === messageUuid)) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [selectedMessagesUuid]);

  return (
    <Wrapper
      type={type}
      similarToPrevMessage={similarToPrevMessage}
      isHighlighted={isHighlighted}
      highlightBackground={
        type === 'sent'
          ? theme?.strokePrimaryOpaque
          : themes?.default?.blueGray400Opaque
      }
      id={messageUuid}
      isSelected={isSelected}
    >
      {selectedMessagesUuid && selectedMessagesUuid.length > 0 ? (
        <SelectionCheckbox
          checked={isSelected}
          handleToggleSelect={handleToggleSelect}
          messageUuid={messageUuid}
        />
      ) : (
        <span />
      )}
      <MessageContainer
        ref={lastMessageRef}
        type={type}
        contentType={contentType}
      >
        <MessageWithReplyWrapper>
          {!!reply?.content && (
            <ReplyWrapper
              type={type}
              borderColor={theme?.primaryDark}
              backgroundColor={theme?.strokePrimaryOpaque}
              onClick={() => {
                handleScrollToMessage &&
                  handleScrollToMessage(reply?.messageUuid);
              }}
            >
              <div>
                <p className="name">{senderFullName}</p>
                <p className="content">
                  {reply?.contentType === 'file'
                    ? reply?.name
                    : reply?.contentType === 'images'
                      ? '🖼️ Image'
                      : reply?.content}
                </p>
              </div>
              {reply?.contentType === 'file' && (
                <FileIcon
                  fileName={reply?.name || ''}
                  size={{ height: 32, width: 32 }}
                />
              )}
              {reply?.contentType === 'images' && (
                <img src={reply?.content} alt="img" />
              )}
            </ReplyWrapper>
          )}
          {contentType === 'images' ? (
            <>
              <div
                style={{
                  display: 'flex',
                  gap: '8px',
                  justifyContent: 'space-between',
                  margin: '4px 8px 0 4px',
                }}
              >
                {description}
                {createdAt && (
                  <div className="time">{formatTimestamp(createdAt)}</div>
                )}
              </div>
              <img
                src={content}
                onClick={() =>
                  handleMessageActions({
                    actionType: 'view',
                    messageUuid: messageUuid,
                    messageContent: content,
                    fileType: 'images',
                  })
                }
              />
            </>
          ) : contentType === 'file' ? (
            <FileMessageWrapper type={type}>
              {description}
              <div className="contentDateWrapper">
                <div className="leftSection">
                  <FileIcon
                    fileName={name || ''}
                    fileType={fileType}
                    size={{ height: 40, width: 40 }}
                  />
                  <div>
                    <EllipsisText text={name || ''} width={190} />
                    <p className="fileType">{fileType?.toUpperCase()}</p>
                  </div>
                </div>
                {createdAt && (
                  <div className="time">{formatTimestamp(createdAt)}</div>
                )}
              </div>
            </FileMessageWrapper>
          ) : (
            <div
              style={{
                display: 'flex',
                gap: '8px',
                justifyContent: 'space-between',
              }}
            >
              {content}
              {createdAt && (
                <div className="time">
                  {isEdited && t('common.edited')} {formatTimestamp(createdAt)}
                </div>
              )}
            </div>
          )}
        </MessageWithReplyWrapper>
        <MessageOptionsMenuContainer
          contentType={contentType}
          className="messageOptionsMenuContainer"
        >
          <MessageOptionsMenu
            messageUuid={messageUuid}
            messageContent={content}
            canEdit={canEdit && contentType === 'text'}
            canDownload={contentType === 'images' || contentType === 'file'}
            canView={contentType === 'images' || contentType === 'file'}
            canReply={true}
            canSelect={true}
            fileName={name || `Untitled.${fileType}`}
            fileType={contentType}
            handleMessageActions={handleMessageActions}
          />
        </MessageOptionsMenuContainer>
      </MessageContainer>
    </Wrapper>
  );
};
