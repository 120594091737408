import * as React from 'react';
import { PopoverEl } from 'app/components/Popover';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { themes } from 'styles/theme/themes';
import { updateOrderFunction } from 'app/components/RoundTrips/function/ApiFunctions';
import { NestedMenuComponent } from 'app/components/NestedMenu';
import { handleCloseAssignRoundTrip } from 'functions/setAnchorFunction';
import { useUpdateOrderMutation } from 'common/services/orderApi';
import { useStopUpdateApiMutation } from 'common/services/roundtripApi';
import { ItemStatusComponent } from './ItemStatusComponent';
import { changeStatus } from './functions';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { OrderStatus } from 'app/pages/Orders/data/statuses';
import { useToaster } from 'hooks/useToaster';
import { getOrderStatusesToShow } from 'app/helpers/helpers';
import { selectConfiguration } from 'common/store/organization/selectors';
import { usePrintV2 as usePrint } from 'hooks/RoundTrips/usePrint';
import { useAbility } from 'hooks/Abilities';

interface Props {
  // setAnchorEl: any;
  onDisplayOrder: any;
  roundtrip?: any;
  typeEdit: any;
  stop: any;
  // accordionStatus: any;
  // setAccordionStatus: any;
  // roundTripCopy: any;
  // selectedStop: any;
  setSelectedStop: any;
  // addRoundTripOpen: any;
  setAddRoundTripOpen: any;
  // statusChangeRoundTripOpen: any;
  // setStatusChangeRoundTripOpen: any;
  leftPosition?: boolean;
  pageRows?: string;
  orders?: any;
  setOrders?: any;
  // updateOrders?: Function;
  handleDelete: any;
  // openModal: any;
  // fromGescom: boolean;
  setHover?: any;
  // triggerRoundtripDetails?: any;
}
export const ActionsStop: React.FC<Props> = ({
  // setAnchorEl,
  onDisplayOrder,
  roundtrip,
  typeEdit,
  stop,
  //   accordionStatus,
  // setAccordionStatus,
  // roundTripCopy,
  // selectedStop,
  setSelectedStop,
  // addRoundTripOpen,
  setAddRoundTripOpen,
  // statusChangeRoundTripOpen,
  // setStatusChangeRoundTripOpen,
  leftPosition,
  pageRows,
  orders,
  setOrders,
  // updateOrders,
  handleDelete,
  // openModal,
  // fromGescom,
  setHover,
  // triggerRoundtripDetails,
}) => {
  const authUser = useSelector(selectAuthUser);
  const configuration = useSelector(selectConfiguration);
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);
  const [UpdateOrders] = useUpdateOrderMutation();
  const [uploadStop] = useStopUpdateApiMutation();
  const toast = useToaster();
  const { triggerPrint } = usePrint();
  const ability = useAbility();
  function printDeliveryNote() {
    triggerPrint({
      uri: `orders/${stop?._order?.id || stop._id}/delivery?format=pdf`,
    });
  }

  function printPreparationNote() {
    triggerPrint({
      uri: `orders/preparation-notes?format=pdf&orderIds=${
        stop?._order?.id || stop._id
      }`,
    });
  }

  const handleDisplayOrder = (order) => {
    if (onDisplayOrder) {
      onDisplayOrder(order);
    }
  };

  const statusActions: Array<{ action: string; element: JSX.Element }> = [];
  let statuses: Array<{
    status: string;
    label?: string;
    classNameCircle: string;
    color: string;
  }>;
  const showDeliveryNote = stop.type !== 'fuel' && stop.type !== 'maintenance';

  if (stop.type === 'fuel' || stop.type === 'maintenance') {
    statuses = [
      {
        status: 'planned',
        label: 'planned',
        classNameCircle: 'green_circle',
        color: themes?.default?.blue700,
      },
      {
        status: 'completed',
        label: 'completed',
        classNameCircle: 'green_circle',
        color: themes?.default?.greenStatus,
      },
    ];
  } else if (
    authUser?.currentAgent?._role?.key === 'admin' ||
    authUser?.currentAgent?._role?.key === 'planner'
  ) {
    statuses = getOrderStatusesToShow(configuration?.orderStatusLogistic || []);
  } else {
    statuses = getOrderStatusesToShow(
      configuration?.orderStatusLogistic || [],
    )?.filter((status) => status.status !== OrderStatus.Delivered);
  }

  const canEdit = React.useMemo(() => {
    return ability.can('canedit', 'orders');
  }, [ability]);

  const canExport = React.useMemo(() => {
    return ability.can('canexport', 'orders');
  }, [ability]);
  statuses.map((status) => {
    statusActions.push({
      action: status.status,
      element: (
        <ItemStatusComponent
          action={async () => {
            const updatedOrder = await changeStatus(
              status.status,
              handleCloseAssignRoundTrip,
              setAddRoundTripOpen,
              roundtrip,
              uploadStop,
              stop,
              updateOrderFunction,
              UpdateOrders,
              pageRows,
            );
            if (updatedOrder) {
              toast(3000, 'success', 'order_status_success');

              const newOrders = orders?.map((o) => {
                if (o.id === updatedOrder?.id) {
                  return { ...o, status: updatedOrder?.status };
                }
                return o;
              });
              setOrders && setOrders(newOrders);
            }
          }}
          label={t(`orders.status.${status.label || status.status}`)}
          color={status.color}
        />
      ),
    });
  });

  const menuActions: any = [
    {
      action: 'view',
      onClick: () =>
        handleDisplayOrder(
          stop?._order ? { ...stop?._order, priority: stop?.priority } : stop,
        ),
      element: (
        <PopoverEl
          redStatus={theme.redStatus}
          style={{ width: '100%', minWidth: '191px' }}
        >
          <div className="popover-item">{t('common.button.view_details')}</div>
        </PopoverEl>
      ),
    },

    !!canEdit
      ? {
          action: 'status',
          nested: true,
          actions: statusActions,

          element: (
            <PopoverText>
              <PopoverEl redStatus={theme.redStatus} style={{ width: '100%' }}>
                <div className="popover-item">{t('orders.change_status')}</div>
              </PopoverEl>
            </PopoverText>
          ),
        }
      : null,
    showDeliveryNote && !!canExport
      ? {
          action: 'print',
          nested: true,
          actions: [
            {
              action: 'print-deliveryNotes',
              element: (
                <PopoverEl
                  onClick={() => printDeliveryNote()}
                  redStatus={theme.redStatus}
                  style={{ width: '100%' }}
                >
                  <div className="popover-item">
                    {t('common.buttons.print_delivery_notes')}
                  </div>
                </PopoverEl>
              ),
            },
            {
              action: 'print-preparationNotes',
              element: (
                <PopoverEl
                  onClick={() => printPreparationNote()}
                  redStatus={theme.redStatus}
                  style={{ width: '100%' }}
                >
                  <div className="popover-item">
                    {t('print.preparation-note-button')}
                  </div>
                </PopoverEl>
              ),
            },
          ],
          element: (
            <PopoverEl redStatus={theme.redStatus} style={{ width: '100%' }}>
              <span className="popover-item">{t('orders.print')}</span>
            </PopoverEl>
          ),
        }
      : null,
    // showDeliveryNote && !!canExport
    //   ? {
    //     action: 'print',
    //     onClick: printDeliveryNote,
    //     element: (
    //       <PopoverEl redStatus={theme.redStatus} style={{ width: '100%' }}>
    //         <div className="popover-item">
    //           {t('print.delivery-note-button')}
    //         </div>
    //       </PopoverEl>
    //     ),
    //   }
    //   : null,
    // showDeliveryNote && !!canExport
    //   ? {
    //     action: 'print-preparation',
    //     onClick: printPreparationNote,
    //     element: (
    //       <PopoverEl redStatus={theme.redStatus} style={{ width: '100%' }}>
    //         <div className="popover-item">
    //           {t('print.preparation-note-button')}
    //         </div>
    //       </PopoverEl>
    //     ),
    //   }
    //   : null,
    stop?._order?.status !== 'delivered' &&
      stop?.status !== 'completed' && {
        seperator: true,
        action: 'seperator',
        element: (
          <div
            className="seperator"
            style={{
              height: '0.5px',
              background: `${themes?.default?.gainsboro2}`,
              width: '100%',
            }}
          />
        ),
      },
    {
      action: 'delete',
      onClick: () => {
        setSelectedStop(stop);
        handleDelete(stop, roundtrip);
      },
      element: (
        <PopoverEl redStatus={theme.redStatus} style={{ width: '100%' }}>
          <div className="delete popover-item">
            {t(
              stop.type === 'fuel' || stop.type === 'maintenance'
                ? 'delete'
                : 'common.remove',
            )}
          </div>
        </PopoverEl>
      ),
    },
  ];

  const viewModeMenuActions: any = [
    {
      action: 'view',
      onClick: () =>
        handleDisplayOrder(
          stop?._order ? { ...stop?._order, priority: stop?.priority } : stop,
        ),
      element: (
        <PopoverEl
          redStatus={theme.redStatus}
          style={{ width: '100%', minWidth: '191px' }}
        >
          <div className="popover-item">{t('common.button.view_details')}</div>
        </PopoverEl>
      ),
    },
    showDeliveryNote && !!canExport
      ? {
          action: 'print',
          nested: true,
          actions: [
            {
              action: 'print',
              element: (
                <PopoverEl
                  onClick={() => printDeliveryNote()}
                  redStatus={theme.redStatus}
                  style={{ width: '100%' }}
                >
                  <div className="popover-item">
                    {t('print.delivery-note-button')}
                  </div>
                </PopoverEl>
              ),
            },
            {
              action: 'print-preparation',
              element: (
                <PopoverEl
                  onClick={() => printPreparationNote()}
                  redStatus={theme.redStatus}
                  style={{ width: '100%' }}
                >
                  <div className="popover-item">
                    {t('print.preparation-note-button')}
                  </div>
                </PopoverEl>
              ),
            },
          ],
          element: (
            <PopoverEl redStatus={theme.redStatus} style={{ width: '100%' }}>
              <div className="popover-item">{t('orders.print')}</div>
            </PopoverEl>
          ),
        }
      : null,
  ];

  // if (stop.type !== 'fuel') {
  //   const orderAssign = {
  //     action: 'status',
  //     nested: true,
  //     disableRipple: true,
  //     actions: (
  //       <OrderAssignPopover
  //         addRoundTripOpen={addRoundTripOpen}
  //         setAddRoundTripOpen={setAddRoundTripOpen}
  //         roundTripCopy={roundTripCopy}
  //         order={stop}
  //         deleteStop={() => {
  //           if (!roundtrip && updateOrders) {
  //             updateOrders();
  //           }
  //           deleteFunctionStop(deleteStop, roundtrip, selectedStop);
  //         }}
  //         currentRoundtrip={roundtrip}
  //         accordionStatus={accordionStatus}
  //         setAccordionStatus={setAccordionStatus}
  //       />
  //     ),
  //     element: (
  //       <>
  //         <PopoverText
  //           onMouseEnter={e => {
  //             handleClickAddRoundTrip(e);
  //             setSelectedStop(stop);
  //           }}
  //         >
  //           <PopoverEl redStatus={theme.redStatus} style={{ width: '100%' }}>
  //             <div className="popover-item">{t('orders.add_to_roundtrip')}</div>
  //           </PopoverEl>
  //         </PopoverText>
  //       </>
  //     ),
  //   };
  //   menuActions.splice(1, 0, orderAssign);
  // }

  return (
    // <PopOverContainer>
    <NestedMenuComponent
      leftPosition={leftPosition}
      id={roundtrip?.id}
      setHover={setHover}
      transformOriginHorizontal="left"
      actions={typeEdit === true ? menuActions : viewModeMenuActions}
    />
  );
};

const PopoverText = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
