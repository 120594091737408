import { Chip, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  TableNavRightIconsWrapper,
  TableNavTitleWrapper,
} from 'styles/components/roundTrips';
import { TableNavTitle } from 'styles/components/users';
import { TableViewPopover } from 'styles/components/muitable/TableViewPopover';
import {
  EXCEL,
  GRID,
  MAP,
  TRACK,
} from 'app/components/TableComponent/constants';
import BampteeLogo from 'assets/img/Menu/BampteeLogo.svg';
import { themes } from 'styles/theme/themes';
import dayjs from 'dayjs';
import { Icon } from 'app/components/Icon';
import { VerticalDivider } from 'app/components/EnhancedTableToolbar/components/VerticalDivider';
import { StatusButton } from './components/StatusButton';
import { createContext, useCallback } from 'react';
import { ClusterStatus } from '../../data/status';
import { HoverMenu } from 'app/pages/AddRoundTrips/components/TableNav/components/StatusButton/components/HoverMenu';
import {
  MapPin,
  Table,
  HashStraight,
  LineSegment,
  CalendarBlank,
  HouseSimple,
  Storefront,
} from '@phosphor-icons/react';
import styled from 'styled-components';

interface ContextProps {
  status: ClusterStatus;
  isToday: boolean;
  handleClick: Function;
  disabled: boolean;
  handleDelete: Function;
  loadingClusterStatusToggle: boolean;
  updateByFile: Function;
  mode: string;
}

export const TableNavContext = createContext<ContextProps>({
  status: ClusterStatus.Active,
  isToday: false,
  handleClick: () => {},
  handleDelete: () => {},
  disabled: true,
  loadingClusterStatusToggle: false,
  updateByFile: () => {},
  mode: '',
});

export function TableNav({
  handleLogoClick,
  roundTripData,
  mode,
  dateValue,
  clusterId,
  warehouse,
  type,
  selected,
  setSelected,
  setHeadCells,
  stateHeadCellsConst,
  setType,
  setExpanded,
  excelHeadCellsConst,
  isToday,
  handleClickOpenUnSchedule,
  handleClickOpenSchedule,
  resultNbr,
  handleDelete,
  loadingClusterStatusToggle,
  updateByFile,
}) {
  // hooks
  const { t } = useTranslation();
  const isBefore = dayjs(dateValue).isBefore(dayjs().startOf('day'));
  const dividerSx = {
    height: '22px',
    color: themes.default.grey3,
    width: '0.4px',
  };

  const handleStatusButtonClick = useCallback(() => {
    if (roundTripData?.status === ClusterStatus.Active) {
      handleClickOpenUnSchedule();
    } else {
      handleClickOpenSchedule();
    }
  }, [
    roundTripData?.status,
    handleClickOpenSchedule,
    handleClickOpenUnSchedule,
  ]);

  return (
    <div className="table-nav">
      <TableNavTitleWrapper>
        <div
          onClick={() => handleLogoClick()}
          style={{ cursor: 'pointer', marginLeft: '10px' }}
        >
          <img src={BampteeLogo} alt="Bamptee" />
        </div>
        <TableNavTitle>{t('roundtrips.nav.planning')}</TableNavTitle>
        <Chip
          label={t('roundtrips.nav.edit_mode')}
          size="small"
          variant="outlined"
          sx={{
            marginLeft: '0.75rem',
            color: themes?.default?.grey2,
          }}
        />
      </TableNavTitleWrapper>
      <CustomResultDetails>
        <Tooltip
          title={t('roundtrips.nav.result_number_is', {
            number: resultNbr ?? 1,
          })}
          arrow
        >
          <div>
            <Icon
              icon={<HashStraight />}
              color={themes?.default?.iconColor}
              size={16}
            />
            <span>
              {t('result_nbr_shortened')} {resultNbr ?? 1}
            </span>
          </div>
        </Tooltip>
        <VerticalDivider sx={{ ...dividerSx }} />
        <Tooltip
          title={`${t('cluster.type_is')} ${t(`cluster.mode.${mode}`)}`}
          arrow
        >
          <div>
            <Icon
              icon={<LineSegment />}
              color={themes?.default?.iconColor}
              size={16}
            />
            <span>{t(`cluster.mode.${mode}`)}</span>
          </div>
        </Tooltip>
        <VerticalDivider sx={{ ...dividerSx }} />
        <Tooltip
          title={t('roundtrips.nav.created_for', {
            date: `${dateValue?.['$D']}/${dateValue?.['$M'] + 1}/${
              dateValue?.['$y']
            }`,
          })}
          arrow
        >
          <div>
            <Icon
              icon={<CalendarBlank />}
              color={themes?.default?.iconColor}
              size={16}
            />
            <span>{`${dateValue?.['$D']}/${dateValue?.['$M'] + 1}`}</span>
          </div>
        </Tooltip>
        <VerticalDivider sx={{ ...dividerSx }} />
        <Tooltip
          title={t('roundtrips.nav.created_for', {
            date: warehouse?._id?.name ?? warehouse?.name,
          })}
          arrow
        >
          <div>
            <Icon icon={<HouseSimple />} />
            <span>{warehouse?.shortName}</span>
          </div>
        </Tooltip>
        <VerticalDivider sx={{ ...dividerSx }} />
        <Tooltip
          title={t('roundtrips.nav.created_for', {
            date: warehouse?._id?.name ?? warehouse?.name,
          })}
          arrow
        >
          <div>
            <Icon icon={<Storefront />} />
            <span>{warehouse?.name}</span>
          </div>
        </Tooltip>
      </CustomResultDetails>
      <TableNavRightIconsWrapper>
        <TableViewPopover
          views={[
            {
              type: MAP,
              icon: <MapPin size={16} color={themes?.default?.iconColor} />,
              label: `${t('map')}`,
            },
            {
              type: GRID,
              icon: <Table size={16} color={themes?.default?.iconColor} />,
              label: `${t('grid')}`,
            },
          ]}
          tableType={type}
          handleAction={(_e, action) => {
            const selectedCopy = { ...selected };
            const objectKeys = Object.keys(selectedCopy);
            for (let i = 0; i < objectKeys.length; i++) {
              selectedCopy[objectKeys[i]] = [];
            }
            setSelected(selectedCopy);
            if (action === MAP) {
              setHeadCells(stateHeadCellsConst);
              setType(MAP);
              setExpanded({});
            }
            if (action === GRID) {
              setHeadCells(excelHeadCellsConst);
              setType(GRID);
              // setExpanded({});
            }

            if (action === TRACK) {
              setHeadCells(stateHeadCellsConst);
              setType(TRACK);
              setExpanded({});
            }
            if (action === EXCEL) {
              setHeadCells(excelHeadCellsConst);
              setType(EXCEL);
              setExpanded({});
            }
          }}
        />
        <TableNavContext.Provider
          value={{
            status: roundTripData?.status,
            isToday,
            handleClick: handleStatusButtonClick,
            disabled: isBefore || !clusterId,
            handleDelete,
            loadingClusterStatusToggle,
            updateByFile,
            mode,
          }}
        >
          <StatusButton />
          <HoverMenu />
        </TableNavContext.Provider>
      </TableNavRightIconsWrapper>
    </div>
  );
}

const CustomResultDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  div {
    color: ${themes?.default?.textColorPrimary};
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.15px;
    color: ${themes?.default?.textColorPrimary};
    display: flex;
    align-items: center;
    gap: 4px;
  }

  span {
    padding: 2px 0 0 0;
  }
`;
