import * as React from 'react';
import { Chip, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { themes } from 'styles/theme/themes';
import { RoundtripStopStatus } from 'common/utils/enum';
interface Props {
  status: string | undefined;
  context?: string;
  date?: string;
  deliveryDistance?: number;
  deliveredAt?: string;
  minimal?: boolean;
  leftMenu?: boolean;
}
export const StatusOrder: React.FC<Props> = ({
  status,
  context,
  date,
  deliveryDistance,
  deliveredAt,
  minimal,
  leftMenu,
}) => {
  const { t, i18n } = useTranslation();
  const theme = useSelector(selectTheme);

  // @ts-ignore
  if (
    context === 'ordoria' &&
    [
      'ready-for-delivery',
      'loaded',
      'on-the-road',
      'prepared',
      'confirmed',
    ].includes(status ?? '')
  ) {
    return null;
    /*
    return (
      <StatusWrapper theme={theme}>
        <div className="accordion-data">
          <Chip label={t('orders.status.confirmed')} className="green_status" />
        </div>
      </StatusWrapper>
    );

     */
  }

  var formattedDate;
  var dotColor;
  var dotColorName;

  if (
    date &&
    (
      [
        RoundtripStopStatus.DELIVERED,
        RoundtripStopStatus.CANCELED,
        RoundtripStopStatus.COMPLETED,
        RoundtripStopStatus.PLANNED,
      ] as string[]
    )?.includes(status || '')
  ) {
    formattedDate = new Date(date).toLocaleTimeString(i18n.language, {
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: i18n.language === 'fr' ? 'h24' : 'h12',
    });

    if (deliveryDistance) {
      if (deliveryDistance >= 500) {
        dotColor = theme.redStatus;
        dotColorName = 'red';
      } else if (deliveryDistance >= 100) {
        dotColor = theme.Saffron;
        dotColorName = 'saffron';
      } else {
        dotColor = theme.greenStatus;
        dotColorName = 'green';
      }
    }
  }

  return (
    <StatusWrapper theme={theme} leftMenu={!!leftMenu}>
      <div>
        {status === 'confirmed' ? (
          <Chip
            label={t('orders.status.confirmed')}
            className="confirmed_status"
          />
        ) : status === 'created' ||
          status === 'initiated' ||
          status === 'initiated-customer' ? (
          <Chip
            label={t('orders.status.initiated')}
            className="initiated_status"
          />
        ) : status === 'pending' ? (
          <Chip label={t('orders.status.pending')} className="pending_status" />
        ) : status === 'pending-customer' ? (
          <Chip
            label={t('orders.status.pending')}
            className="pending_customer_status"
          />
        ) : status === 'prepared' ? (
          <Chip label={t('orders.status.prepared')} className="purple_status" />
        ) : status === 'ready-for-delivery' ? (
          <Chip
            label={t('orders.status.ready-for-delivery')}
            className="ready_status"
          />
        ) : status === 'loaded' || status === 'LOADED' ? (
          <Chip label={t('orders.status.loaded')} className="loaded_status" />
        ) : status === 'on-the-road' ? (
          <Chip
            className="orange_status"
            label={t('orders.status.on-the-road')}
          />
        ) : status === 'delivered' ? (
          <>
            <Chip
              className="delivered_status"
              sx={{ fontSize: leftMenu ? '0.65rem' : '0.8125rem' }}
              label={
                formattedDate
                  ? `${t('badge.delivered_at')} ${formattedDate}`
                  : deliveredAt
                    ? minimal
                      ? deliveredAt
                      : `${t('orders.status.delivered')} - ${deliveredAt}`
                    : t('orders.status.delivered')
              }
            />
            {/* {!!deliveryDistance && ( */}
            <Tooltip
              title={t(
                `orders_status_distance_dot_${dotColorName || 'not_provided'}`,
              )}
            >
              <Dot style={{ backgroundColor: dotColor }} />
            </Tooltip>
            {/* )} */}
          </>
        ) : status === 'blocked' || status === 'locked' ? (
          <Chip className="brown_status" label={t('orders.status.blocked')} />
        ) : status === 'canceled' ? (
          <>
            <Chip
              className="red_status"
              sx={{ fontSize: leftMenu ? '0.65rem' : '0.8125rem' }}
              label={
                formattedDate
                  ? `${t('badge.canceled_at')} ${formattedDate}`
                  : deliveredAt
                    ? minimal
                      ? deliveredAt
                      : `${t('orders.status.canceled')} - ${deliveredAt}`
                    : t('orders.status.canceled')
              }
            />
            {!!deliveryDistance && (
              <Dot style={{ backgroundColor: dotColor }} />
            )}
          </>
        ) : status === 'completed' ? (
          <>
            <Chip
              className="green_status"
              label={
                formattedDate
                  ? `${t('badge.completed_at')} ${formattedDate}`
                  : t('orders.status.completed')
              }
            />
            {!!deliveryDistance && (
              <Dot style={{ backgroundColor: dotColor }} />
            )}
          </>
        ) : status === 'planned' ? (
          <Chip
            className="blue_status"
            label={
              formattedDate
                ? `${t('planned_at')} ${formattedDate}`
                : t('orders.status.planned')
            }
          />
        ) : status === 'validated' ? (
          <Chip
            className="validated_status"
            label={t('orders.status.validated')}
          />
        ) : isEmpty(status) ? null : (
          <Chip className="grey_status" label={t(`orders.status.${status}`)} />
        )}
      </div>
    </StatusWrapper>
  );
};
const StatusStyle = css`
  align-items: center;
  // max-width: fit-content;
  height: 22px;
`;

const Dot = styled.div`
  margin-left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ccc;
`;

const StatusWrapper = styled.div<{ theme: any; leftMenu?: any }>`
  align-self: center;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 1.0125rem */
  letter-spacing: 0.025rem;

  span {
    font-weight: 400 !important;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .ready_status {
    color: ${themes?.default?.lightGreen600};
    background: ${themes?.default?.lightGreen55};
    ${StatusStyle}
  }
  .brown_status {
    color: ${themes?.default?.brown};
    background: ${themes?.default?.brown}1a;
    ${StatusStyle}
  }
  .orange_2_status {
    color: ${themes?.default?.safetyOrange};
    background: ${themes?.default?.safetyOrange}1a;
    ${StatusStyle}
  }
  .orange_status {
    color: ${themes?.default?.orange600};
    background: ${themes?.default?.orange50};
    ${StatusStyle}
  }
  .green_status {
    color: ${themes?.default?.greenStatus};
    background: ${themes?.default?.greenStatus}1a;
    ${StatusStyle}
  }
  .purple_status {
    color: ${themes?.default?.purple};
    background: ${themes?.default?.purple50};
    ${StatusStyle}
  }
  .blue_status {
    color: ${(props) => props?.theme?.blue700};
    background: ${themes?.default.blue50};
    ${StatusStyle}
  }
  .confirmed_status {
    color: ${(props) => props?.theme?.teal800};
    background: ${themes?.default.teal50};
    ${StatusStyle}
  }
  .yellow_status {
    color: ${themes?.default?.Saffron};
    background: ${themes?.default?.Saffron}1a;
    ${StatusStyle}
  }
  .red_status {
    color: ${themes?.default?.errorMain};
    background: ${themes?.default?.errorMain}1a;
    ${StatusStyle}
  }
  .grey_status {
    color: ${themes?.default?.tapa};
    background: ${themes?.default?.tapa}1a;
    ${StatusStyle}
  }
  .validated_status {
    color: ${(props) => props?.theme?.blue900};
    background: ${themes?.default.blue50};
    ${StatusStyle}
  }
  .initiated_status {
    color: ${(props) => props?.theme?.textPrimary};
    background: ${themes?.default.whisper};
    ${StatusStyle}
  }
  .pending_status {
    color: ${(props) => props?.theme?.textPending};
    background: ${themes?.default.bgPending};
    ${StatusStyle}
  }
  .pending_customer_status {
    color: ${(props) => props?.theme?.textPendingCustomer};
    background: ${themes?.default.bgPendingCustomer};
    ${StatusStyle}
  }
  .loaded_status {
    color: ${(props) => props?.theme?.lightblue50};
    background: ${themes?.default.lightblue900};
    ${StatusStyle}
  }
  .delivered_status {
    color: ${themes?.default?.textDelivered};
    background: ${themes?.default?.bgDelivered};
    ${StatusStyle}
  }
`;
