import { Chip, Grid, Typography } from '@mui/material';
import If from 'app/components/If';
import dayjs from 'dayjs';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { statuses } from './colors';

/**
 * Notification component for Roundtrips & orders status updated
 */
interface ActivityStatusUpdatedProps {
  content: any;
  status: string;
}

export function ActivityStatusUpdated({
  content,
  status,
}: ActivityStatusUpdatedProps) {
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const title = content?.[locale]?.title;
  const body = content?.[locale]?.body;

  const statusColors = statuses[status] || {
    backgroundColor: themes?.default?.primary,
    color: themes?.default?.primaryActiveColor,
  };

  const { backgroundColor, color } = statusColors;

  const time = useRef(dayjs().format('HH:mm'));

  const extractTitleParts = (title) => {
    if (!title) return null;

    const match = title.match(/^(.*?)(#\w+)(.*)$/);
    if (match) {
      return {
        beforeOrder: match[1],
        orderNumber: match[2].substring(1),
        afterOrder: match[3],
      };
    }
    return null;
  };

  const titleParts = useMemo(() => extractTitleParts(title), [title]);

  return (
    <MessageBody>
      <Grid container flexDirection="column">
        <Grid container alignItems="center" gap="4px">
          <If condition={titleParts?.beforeOrder !== ''}>
            <Typography>{titleParts ? titleParts.beforeOrder : ''}</Typography>
          </If>
          <CodeTitle>#{titleParts ? titleParts.orderNumber : ''}</CodeTitle>
          <Typography>{titleParts ? titleParts.afterOrder : ''}</Typography>
        </Grid>
        <Grid container alignItems="center" gap="4px">
          <SubTitle>{body}</SubTitle>
          <Chip
            size="small"
            label={time.current}
            sx={{
              height: '18px',
              backgroundColor: backgroundColor || themes?.default?.primary,
              color: color || themes?.default?.primaryActiveColor,
              marginLeft: 'auto',
            }}
          />
        </Grid>
      </Grid>
    </MessageBody>
  );
}

const MessageBody = styled.div`
  flex: 1;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  gap: 4px;
  align-items: center;
`;

const CodeTitle = styled(Typography)`
  font-size: 12px !important;
  font-weight: 700 !important;
  text-decoration: underline;
`;

const SubTitle = styled(Typography)`
  font-size: 12px !important;
  font-weight: 400 !important;
  color: ${themes?.default?.nobel};
`;
