import { Can } from '@casl/react';
import { selectAuthToken } from 'app/slices/auth/selectors';
import { toolsActions } from 'app/slices/tools';
import { useCheckQuery } from 'common/services/authApi';
import { BampteeApplications } from 'common/store/app';
import { useAbility } from 'hooks/Abilities';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  LOGOUT,
  SETTINGS,
  SETTINGS_COMPANY_PROFILE,
  SIGNIN,
} from 'utils/routes';

export const ApplicationRoute = ({ application }) => {
  const ability = useAbility();
  const location = useLocation();
  const navigate = useNavigate();
  const authToken = useSelector(selectAuthToken);
  const { error } = useCheckQuery();
  const dispatch = useDispatch();

  useCheckQuery();

  const firstApplicationAccess = useMemo(() => {
    let url = '/';
    const firstApp = BampteeApplications.find((app) =>
      ability.can('access', app.id),
    );
    if (firstApp) {
      url = firstApp.url;
    } else if (ability.can('access', 'idm')) {
      url = SETTINGS_COMPANY_PROFILE;
    }
    return url;
  }, [ability]);

  useEffect(() => {
    // execute on location change

    if (authToken === null) {
      // not logged in so redirect to login page with the return url
      navigate(SIGNIN);
    } else if ((error as { status: number })?.status === 401) {
      navigate(LOGOUT);
    } else {
      const idm = location.pathname.indexOf(SETTINGS) > -1;

      if (idm) {
        if (!ability.can('access', 'idm')) {
          if (location.pathname !== (firstApplicationAccess || '/')) {
            navigate(firstApplicationAccess || '/');
            dispatch(toolsActions.setZoomIn({ status: false }));
          }
        }
      } else {
        const findApp = BampteeApplications.find(
          (app: any) => location.pathname.indexOf(app.url) > -1,
        );
        if (findApp?.id) {
          if (
            !ability.can('access', findApp.id) &&
            location.pathname !== (firstApplicationAccess || '/')
          ) {
            navigate(firstApplicationAccess || '/');
          }
        }
      }
    }
  }, [
    location.pathname,
    ability,
    firstApplicationAccess,
    navigate,
    authToken,
    error,
    dispatch,
  ]);

  if (
    !ability.can('access', application) &&
    location.pathname !== (firstApplicationAccess || '/')
  ) {
    return <Navigate to={firstApplicationAccess || '/'} />;
  }

  return (
    <Can I="access" a={application} ability={ability}>
      <Outlet />
    </Can>
  );
};
