import { NewMessageNotification } from '../NewMessageNotification';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Slide } from '@mui/material';
import React from 'react';

interface notificationsToastProps {
  newMessageToastOpen: boolean;
  setNewMessageToastOpen: any;
  lastRecievedMessage: any;
  handleNotificationsToastClick: any;
}

export const NotificationsToast: React.FC<notificationsToastProps> = ({
  newMessageToastOpen,
  setNewMessageToastOpen,
  lastRecievedMessage,
  handleNotificationsToastClick,
}) => {
  const { t } = useTranslation();

  const formatTimestamp = (
    timestamp: string | number | Date | dayjs.Dayjs | null | undefined,
  ) => {
    const date = dayjs(timestamp);

    if (date.isToday()) {
      return date.format('HH:mm');
    } else if (date.isYesterday()) {
      return t('yesterday');
    } else {
      return date.format('D MMMM, YYYY');
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <Slide direction="left" in={newMessageToastOpen && !!lastRecievedMessage}>
        <div style={{ position: 'relative' }}>
          <NewMessageNotification
            senderPictureUrl={lastRecievedMessage?.sender?.pictureUrl}
            senderFullName={`${lastRecievedMessage?.sender?.firstName} ${lastRecievedMessage?.sender?.lastName}`}
            lastMessageAt={formatTimestamp(
              lastRecievedMessage?.message?.createdAt,
            )}
            lastMessage={lastRecievedMessage?.message?.content}
            sendeUnreadMessagesCount={lastRecievedMessage?.unreadCount}
            handleClose={() => setNewMessageToastOpen(false)}
            onClick={handleNotificationsToastClick}
          />
        </div>
      </Slide>
    </div>
  );
};
