import { ButtonBase, Popover } from '@mui/material';
import { Question } from '@phosphor-icons/react';
import { themes } from 'styles/theme/themes';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon } from 'app/components/Icon';

interface PopoverProps {
  buttonIcon?: JSX.Element;
  content?: {
    title: string;
    legends: { text: string; icon: JSX.Element | string }[];
  }[];
}

export const MapLegendPopover: React.FC<PopoverProps> = ({
  buttonIcon,
  content,
}) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <ButtonBase
        sx={{
          height: '23px',
          width: '23px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '23px',
          zIndex: '1200',
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Icon
          icon={buttonIcon || <Question />}
          size={20}
          color={
            !!anchorEl ? themes?.default?.primary : themes?.default?.iconColor
          }
        />
      </ButtonBase>
      <Popover
        id="map-legends-popover"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            background: 'transparent !important',
            boxShadow: 'none !important',
          },
        }}
      >
        <Wrapper>
          {content?.map((item, index) => (
            <SectionWrapper key={index}>
              <SectionHeader>{item.title}</SectionHeader>
              <SectionBody>
                {item.legends.map((le, index) => (
                  <Legend key={index}>
                    <LegendMessage>{le.text}</LegendMessage>
                    {typeof le.icon === 'string' ? (
                      <LegendIcon>
                        <img draggable="false" src={le.icon} alt="" />
                      </LegendIcon>
                    ) : (
                      <>{le.icon}</>
                    )}
                  </Legend>
                ))}
              </SectionBody>
            </SectionWrapper>
          ))}
        </Wrapper>
      </Popover>
    </>
  );
};
const Wrapper = styled.div`
  min-width: 315px;
  min-height: 224px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px;
  background: #fff;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin: 10px 10px 20px 10px;
`;
const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const SectionHeader = styled.div`
  min-height: 21px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: 0.15000000596046448px;
  color: ${themes?.default?.textBlack};
  border-bottom: 1px solid ${themes?.default?.gainsboro2};
  padding: 0 0 4px 0;
`;
const SectionBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const Legend = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 17px;
`;
const LegendMessage = styled.span`
  margin: 0;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 16.87px;
  letter-spacing: 0.3526666760444641px;
  text-align: left;
`;
const LegendIcon = styled.div`
  position: relative;
  height: 12px;
  width: 12px;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
  }
`;
