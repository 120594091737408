import { useTranslation } from 'react-i18next';
import styled, { css, keyframes } from 'styled-components';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { ManagedTooltip } from '../TableComponent/helpers/Components/ManagedTooltip';
import React, { useEffect, useMemo, useState } from 'react';

interface TruckIconProps {
  vehicle: boolean;
  driver: boolean;
  running: boolean;
  style?: React.CSSProperties;
  iconStyle?: {
    icon: React.CSSProperties;
    img: React.CSSProperties;
  };
  notTracked?: boolean;
  mapOpen?: boolean;
  status?: string;
  marker?: boolean;
}

const TruckIcon = ({
  vehicle,
  driver,
  running,
  style,
  iconStyle,
  notTracked,
  mapOpen,
  status,
  marker,
}: TruckIconProps) => {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);

  const [showRedPulse, setShowRedPulse] = useState(false);

  useEffect(() => {
    setShowRedPulse(driver);
  }, [driver]);

  const arrowIconColors = useMemo(() => {
    switch (status) {
      case 'available':
        return {
          iconColor: theme?.fruitSaladGreen,
          pulseColor: 'rgba(67, 160, 71, 0.4)',
        };
      case 'active':
        return {
          iconColor: theme?.primary,
          pulseColor: 'rgba(42, 89, 255, 0.4)',
        };
      case 'emergency':
        return {
          iconColor: theme?.Cinnabar,
          pulseColor: 'rgba(229, 57, 53, 0.4)',
        };
      case 'returning':
        return {
          iconColor: theme?.summerSky,
          pulseColor: 'rgba(41, 182, 246, 0.4)',
        };
      case 'offline':
      case 'out-of-service':
      case 'inactive':
        return { iconColor: theme?.nobel };
      default:
        return { iconColor: theme?.textColorPrimary };
    }
  }, [status, theme]);

  const tooltipTitle = useMemo(() => {
    let title = `${t('roundtrips.tooltip.truck_is')} ${t(
      `roundtrips.tooltip.truck.${status || 'inactive'}`,
    )}`;
    if (vehicle || showRedPulse) {
      title = t('roundtrips.tooltip.truck.warning');
    } else if (notTracked && !vehicle && !driver) {
      title += ` ${t('roundtrips.tooltip.truck.not_tracked').toLowerCase()}`;
    } else if (running) {
      title += ` - ${t('truck.moving')}`;
    }
    return title;
  }, [status, vehicle, showRedPulse, notTracked, driver, running, t]);

  const trackedIcon = useMemo(() => {
    if (notTracked && !(vehicle || driver)) {
      return (
        <div style={{ position: 'absolute', right: '-14.5px', top: '1px' }}>
          <PriorityHighIcon style={{ height: '14px' }} />
        </div>
      );
    }
    return null;
  }, [notTracked, vehicle, driver]);

  return (
    <TruckIconWrapper
      style={{
        ...style,
        outline: mapOpen ? `2px solid ${theme.primaryActiveColor}` : '',
      }}
    >
      <TruckIcons marker={!!marker} style={style}>
        <ManagedTooltip title={tooltipTitle}>
          {vehicle || showRedPulse ? (
            <RedPulse style={iconStyle?.icon} marker={!!marker} />
          ) : (
            <PulseIcon
              style={iconStyle?.icon}
              marker={!!marker}
              pulseColor={arrowIconColors.pulseColor || 'transparent'}
            >
              <ArrowIcon
                color={arrowIconColors.iconColor}
                style={iconStyle?.img}
              />
            </PulseIcon>
          )}
        </ManagedTooltip>
      </TruckIcons>
      {trackedIcon}
    </TruckIconWrapper>
  );
};

export default TruckIcon;

const ArrowIcon = ({
  color,
  style = { width: '19px', height: '19px' },
}: {
  color: string;
  style?: React.CSSProperties;
}) => (
  // inline svg to be able to set the color instead of having
  // to download svg for each color
  <div style={{ ...style }}>
    <svg
      width={style.width || '19px'}
      height={style.height || '19px'}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.26415 0.829546L9.26414 0.829524C9.10095 0.509864 8.83448 0.375 8.58998 0.375C8.34558 0.375 8.07978 0.509736 7.91748 0.828974L7.9172 0.829514L4.20462 8.10253L0.492038 15.3755L0.158036 15.205L0.492032 15.3756C0.326215 15.7004 0.346314 16.0671 0.491572 16.3517C0.63655 16.6356 0.885272 16.8053 1.16641 16.8053H2.04399L7.42784 14.8185L7.43827 14.8146L7.44891 14.8114C8.19574 14.585 8.98547 14.5831 9.73403 14.8053L9.74442 14.8084L9.75463 14.812L15.2818 16.8071H16.0167C16.2966 16.8071 16.5451 16.6378 16.6902 16.3535C16.8356 16.0688 16.856 15.7019 16.691 15.3771C16.6909 15.377 16.6909 15.3769 16.6908 15.3768L12.9786 8.10439L12.9785 8.10436L9.26415 0.829546Z"
        fill={color || '#2A59FF'}
        stroke="white"
        strokeWidth="0.75"
      />
    </svg>
  </div>
);

const sharedStyles = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 14px;
  height: 14px;

  img {
    width: 7.39px;
    height: 5.91px;
  }
`;

const sharedStylesMap = css`
  ${sharedStyles};
  width: 26px;
  height: 26px;

  img {
    width: 13.72px;
    height: 10.97px;
  }
`;

const ExpandFade = keyframes`
  0% {
    width: 1px;
    height: 1px;
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    width: 36px;
    height: 36px;
    opacity: 0;
  }
`;

const PulseIcon = styled.div<{ marker?: boolean; pulseColor: string }>`
  position: relative;
  z-index: 2;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid ${(props) => props.pulseColor};
    border-radius: 100px;
    width: 1px;
    height: 1px;
    z-index: 1;
    animation: ${ExpandFade} 3s infinite ease-in-out;
  }

  ${(props) => (props.marker ? sharedStylesMap : sharedStyles)}
`;

const RedPulse = styled.div<{ marker: boolean }>`
  background: #df0000;
  box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.5);
  animation: redPulse 1.5s infinite;

  @keyframes redPulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.15);
    }
    50% {
      box-shadow: 0 0 0 5px rgba(255, 0, 0, 0.15);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.15);
    }
  }

  ${(props) => (props.marker ? sharedStylesMap : sharedStyles)}
`;

const TruckIcons = styled.div<{ marker: boolean }>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  svg {
    width: ${(props) => (props.marker ? null : '14.5px')};
    height: ${(props) => (props.marker ? null : '12px')};
  }
`;

const TruckIconWrapper = styled.div`
  border-radius: 50%;
  padding: 4px;
  position: relative;
  height: fit-content;
  width: fit-content;
`;
