import TruckIcon from 'app/components/TruckIcon';
import { useMemo } from 'react';
import { getRoundtripUserAndVehicleInfo } from 'app/helpers/helpers';
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import { Driver } from 'app/components/RoundTrips/RoundTripData/statuses';

interface Props {
  vehicle: any;
  onClick?: any;
}

export default function MarkerVehicle({ vehicle, onClick }: Props) {
  const userRole = Driver;

  const roundtripInfo = useMemo(() => {
    return getRoundtripUserAndVehicleInfo({
      agents: vehicle?.roundtrip?.agents,
      vehicle: vehicle?.numberPlate,
      role: userRole,
    });
  }, [vehicle, userRole]);

  if (!vehicle?.lastPosition?.geometry?.coordinates) {
    return null;
  }
  return (
    <div onClick={() => onClick?.(vehicle)}>
      <AdvancedMarker
        position={{
          lat: vehicle.lastPosition.geometry.coordinates[1],
          lng: vehicle.lastPosition.geometry.coordinates[0],
        }}
      >
        {!roundtripInfo?.missingUser && !!roundtripInfo?.hasVehicle && (
          <span
            style={{
              position: 'relative',
              zIndex: 4,
            }}
          >
            <TruckIcon
              driver={!!roundtripInfo?.missingUser}
              vehicle={!roundtripInfo?.hasVehicle}
              running={vehicle?.roundtrip?._vehicle?.engineRunning}
              status={vehicle?.roundtrip?._vehicle?.status}
              style={{
                position: 'relative',
                transform: `rotate(${vehicle?.roundtrip?._vehicle?.angle}deg)`,
              }}
              marker={true}
            />
          </span>
        )}
      </AdvancedMarker>
    </div>
  );
}
