import * as React from 'react';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { clientStatusColor } from 'common/utils/clientStatusColor';
interface Props {
  status: string | undefined;
}
export const StatusCustomer: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);

  return (
    <StatusWrapper theme={theme}>
      <div className="accordion-data">
        <Chip
          label={t(`customer.status.${status}`)}
          className="status"
          sx={{
            color:
              clientStatusColor[status || 'other']?.color ||
              clientStatusColor['other']?.color,
            backgroundColor:
              clientStatusColor[status || 'other']?.background ||
              clientStatusColor['other']?.background,
          }}
        />
      </div>
    </StatusWrapper>
  );
};
const StatusStyle = css`
  align-items: center;
  // max-width: fit-content;
  height: 22px;
`;
const StatusWrapper = styled.div<{ theme: any }>`
  align-self: center;
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 1.0125rem */
  letter-spacing: 0.025rem;
  .status {
    ${StatusStyle}
  }
`;
