import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useLocation } from 'react-router-dom';
import { Collapse, IconButton, Badge } from '@mui/material';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

import {
  ChartPieSlice,
  RoadHorizon,
  Package,
  IdentificationBadge,
  Car,
  Shapes,
  Devices,
  Cardholder,
  PresentationChart,
} from '@phosphor-icons/react';
import PalletTruck from 'assets/img/navigtion_left/PalletTruck.svg';

import If from '../../If';
import { MainNavItem } from '../helpers';
import {
  ACCESSORIES,
  ALL_ROUNDTRIPS,
  CHECK_DOCUMENTS,
  CUSTOMER,
  DASHBOARD,
  DASHBOARD_ALL,
  INBOX,
  ORDERS,
  PAYMENTCARDS,
  REPORTS,
  REPORTS_ALL,
  ROUNDTRIP,
  SCHEDULED_ROUNDTRIPS,
  SUPPORTUNIT,
  TOOLS,
  UPLOAD_FILES,
  USERS,
  VEHICLES,
  VEHICLES_LOGISTIC,
  VEHICLES_SALES,
} from '../../../../utils/routes';
import { themes } from '../../../../styles/theme/themes';

import IfUserAcl from '../../IfUserAcl';
import { AccessoryType } from 'app/pages/Accessories/Types';
import { isActiveNestedRoute, isActiveRoute } from '../index';
import { selectConfiguration } from 'common/store/organization/selectors';
import { useApplication } from 'hooks/useApplication';
import { useSelector } from 'react-redux';
import { Can } from 'hooks/Abilities/context';
import { useAbility } from 'hooks/Abilities';
import { checkUserAcl } from 'common/helpers/acl';

export default function BiantaMenu({
  theme,
  mode,
  authUser,
  organizationActivities,
}) {
  const ability = useAbility();
  const { t } = useTranslation();
  const location = useLocation();

  const displayResourcesMenu = useMemo(() => {
    return (
      ability.can('canview', 'users') ||
      ability.can('canview', 'vehicles') ||
      ability.can('canview', 'accessories') ||
      ability.can('canview', 'tools') ||
      ability.can('canview', 'paymentcards') ||
      ability.can('canview', 'supportunits')
    );
  }, [ability]);

  const displayMainMenu = useMemo(() => {
    return (
      ability.can('canview', 'dashboard') ||
      ability.can('canview', 'roundtrips')
    );
  }, [ability]);

  const displayMenuAll = useMemo(() => {
    return (
      ability.can('canview', 'dashboard-all') ||
      ability.can('canview', 'reports-all')
    );
  }, [ability]);

  const displayGeneralMenu = useMemo(() => {
    return (
      ability.can('canview', 'orders') ||
      ability.can('canview', 'customers') ||
      ability.can('canview', 'reports') ||
      checkUserAcl(authUser, ['inbox', 'checkDocuments', 'uploadFiles'])
    );
  }, [ability, authUser]);

  const configuration = useSelector(selectConfiguration);
  const roles = configuration.roles;
  const currentApplication = useApplication();

  const [openSectionIndex, setOpenSectionIndex] = useState<number[]>(
    displayMainMenu ? [0] : [1],
  );

  const isMinify = mode === 'minify';
  const isDashboardAll = location.pathname === DASHBOARD_ALL;
  const isReportAll = location.pathname === REPORTS_ALL;

  const handleRowToggle = (index: number) => {
    setOpenSectionIndex((prevIndices) => {
      if (prevIndices.includes(index)) {
        return prevIndices.filter((i) => i !== index);
      } else {
        return [index];
      }
    });
  };

  const displayEmployees = useMemo(() => {
    return (
      roles.filter((r) =>
        r.privileges?.roleproperties?.includes(
          `${currentApplication?.id}.showinemployee`,
        ),
      )?.length && ability.can('canview', 'users')
    );
  }, [currentApplication?.id, roles, ability]);

  if (isDashboardAll || isReportAll) {
    return (
      <ScrollableSection>
        <If condition={displayMenuAll}>
          <MenuSectionWrapper>
            <If condition={!isMinify}>
              <TitleWrapper onClick={() => handleRowToggle(0)}>
                <Title textColorSecondary={theme.textColorSecondary}>
                  {t('nav.mainNav.title1')}
                </Title>
                <IconButton
                  sx={{
                    ml: 'auto',
                    mr: '12px',
                    ':hover': {
                      transform: 'scale(1.1)',
                      transition: 'all 100ms ease-in',
                    },
                  }}
                >
                  {openSectionIndex.includes(0) ? (
                    <KeyboardArrowDownIcon color="action" />
                  ) : (
                    <KeyboardArrowRightIcon color="action" />
                  )}
                </IconButton>
              </TitleWrapper>
            </If>
            <Collapse
              in={openSectionIndex.includes(0) || isMinify}
              timeout="auto"
              unmountOnExit
            >
              <Can I="canview" a="dashboard-all">
                <MainNavItem
                  to={DASHBOARD_ALL}
                  text={t('nav.mainNav.dashboard')}
                  mode={mode}
                  icon={
                    <IconWrapper
                      isActive={isActiveRoute(location, DASHBOARD_ALL)}
                    >
                      <ChartPieSlice />
                    </IconWrapper>
                  }
                />
              </Can>
              <Can I="canview" a="reports-all">
                <MainNavItem
                  to={REPORTS_ALL}
                  text={t('nav.mainNav.reports')}
                  mode={mode}
                  icon={
                    <IconWrapper
                      isActive={isActiveRoute(location, REPORTS_ALL)}
                    >
                      <PresentationChart />
                    </IconWrapper>
                  }
                />
              </Can>
            </Collapse>
          </MenuSectionWrapper>
        </If>
      </ScrollableSection>
    );
  }

  return (
    <ScrollableSection>
      <If condition={displayMainMenu}>
        <MenuSectionWrapper>
          <If condition={!isMinify}>
            <TitleWrapper onClick={() => handleRowToggle(0)}>
              <Title textColorSecondary={theme.textColorSecondary}>
                {t('nav.mainNav.title1')}
              </Title>
              <IconButton
                sx={{
                  ml: 'auto',
                  mr: '12px',
                  ':hover': {
                    transform: 'scale(1.1)',
                    transition: 'all 100ms ease-in',
                  },
                }}
              >
                {openSectionIndex.includes(0) ? (
                  <KeyboardArrowDownIcon color="action" />
                ) : (
                  <KeyboardArrowRightIcon color="action" />
                )}
              </IconButton>
            </TitleWrapper>
          </If>
          <Collapse
            in={openSectionIndex.includes(0) || isMinify}
            timeout="auto"
            unmountOnExit
          >
            <Can I="canview" a="dashboard">
              <MainNavItem
                to={DASHBOARD}
                text={t('nav.mainNav.dashboard')}
                mode={mode}
                icon={
                  <IconWrapper isActive={isActiveRoute(location, DASHBOARD)}>
                    <ChartPieSlice />
                  </IconWrapper>
                }
              />
            </Can>

            <Can I="canview" a="roundtrips">
              <MainNavItem
                to={`${ROUNDTRIP}/${SCHEDULED_ROUNDTRIPS}`}
                tabs={[
                  `${ROUNDTRIP}/${SCHEDULED_ROUNDTRIPS}`,
                  `${ROUNDTRIP}/${ALL_ROUNDTRIPS}`,
                ]}
                text={t('nav.mainNav.roundtrips')}
                mode={mode}
                icon={
                  <IconWrapper
                    isActive={isActiveNestedRoute(location, [
                      `${ROUNDTRIP}/${SCHEDULED_ROUNDTRIPS}`,
                      `${ROUNDTRIP}/${ALL_ROUNDTRIPS}`,
                    ])}
                  >
                    <RoadHorizon />
                  </IconWrapper>
                }
              />
            </Can>
          </Collapse>
        </MenuSectionWrapper>
      </If>
      <If condition={!!displayGeneralMenu}>
        <GeneralSectionWrapper>
          <If condition={!isMinify}>
            <TitleWrapper onClick={() => handleRowToggle(1)}>
              <Title textColorSecondary={theme.textColorSecondary}>
                {t('nav.mainNav.title2')}
              </Title>
              <IconButton
                sx={{
                  ml: 'auto',
                  mr: '12px',
                  ':hover': {
                    transform: 'scale(1.1)',
                    transition: 'all 100ms ease-in',
                  },
                }}
              >
                {openSectionIndex.includes(1) ? (
                  <KeyboardArrowDownIcon color="action" />
                ) : (
                  <KeyboardArrowRightIcon color="action" />
                )}
              </IconButton>
            </TitleWrapper>
          </If>
          <Collapse
            in={openSectionIndex.includes(1) || isMinify}
            timeout="auto"
            unmountOnExit
          >
            <Can I="canview" a="orders">
              <MainNavItem
                to={ORDERS}
                text={t('nav.mainNav.orders')}
                mode={mode}
                icon={
                  <IconWrapper isActive={isActiveRoute(location, ORDERS)}>
                    <Package />
                  </IconWrapper>
                }
              />
            </Can>
            <Can I="canview" a="customers">
              <MainNavItem
                to={CUSTOMER}
                text={t('nav.mainNav.customers')}
                mode={mode}
                icon={
                  <IconWrapper isActive={isActiveRoute(location, CUSTOMER)}>
                    <StarOutlineIcon />
                  </IconWrapper>
                }
              />
            </Can>

            <IfUserAcl acl={['inbox']}>
              <MainNavItem
                to={INBOX}
                text={t('nav.mainNav.inbox')}
                mode={mode}
                icon={
                  <Badge
                    color="primary"
                    badgeContent={
                      organizationActivities?.countNewConversations || 0
                    }
                  >
                    <IconWrapper isActive={isActiveRoute(location, INBOX)}>
                      <AllInboxIcon />
                    </IconWrapper>
                  </Badge>
                }
              />
            </IfUserAcl>

            <IfUserAcl acl={['checkDocuments']}>
              <MainNavItem
                to={CHECK_DOCUMENTS}
                text={t('nav.mainNav.checkDocuments')}
                mode={mode}
                icon={
                  <Badge
                    color="primary"
                    badgeContent={
                      organizationActivities?.countCheckDocuments || 0
                    }
                  >
                    <IconWrapper
                      isActive={isActiveRoute(location, CHECK_DOCUMENTS)}
                    >
                      <AssignmentTurnedInIcon />
                    </IconWrapper>
                  </Badge>
                }
              />
            </IfUserAcl>

            <IfUserAcl acl={['uploadFiles']}>
              <MainNavItem
                to={UPLOAD_FILES}
                text={t('nav.mainNav.uploads')}
                mode={mode}
                icon={
                  <IconWrapper isActive={isActiveRoute(location, UPLOAD_FILES)}>
                    <CloudUploadIcon />
                  </IconWrapper>
                }
              />
            </IfUserAcl>

            <Can I="canview" a="reports">
              <MainNavItem
                to={REPORTS}
                text={t('nav.mainNav.reports')}
                mode={mode}
                icon={
                  <IconWrapper isActive={isActiveRoute(location, REPORTS)}>
                    <PresentationChart />
                  </IconWrapper>
                }
              />
            </Can>
          </Collapse>
        </GeneralSectionWrapper>
      </If>

      <If condition={!!displayResourcesMenu}>
        <LastSectionWrapper>
          <If condition={!isMinify}>
            <TitleWrapper onClick={() => handleRowToggle(3)}>
              <Title textColorSecondary={theme.textColorSecondary}>
                {t('nav.mainNav.resources')}
              </Title>
              <IconButton
                sx={{
                  ml: 'auto',
                  mr: '12px',
                  ':hover': {
                    transform: 'scale(1.1)',
                    transition: 'all 100ms ease-in',
                  },
                }}
              >
                {openSectionIndex.includes(3) ? (
                  <KeyboardArrowDownIcon color="action" />
                ) : (
                  <KeyboardArrowRightIcon color="action" />
                )}
              </IconButton>
            </TitleWrapper>
          </If>
          <Collapse
            in={openSectionIndex.includes(3) || isMinify}
            timeout="auto"
            unmountOnExit
          >
            <If condition={!!displayEmployees}>
              <MainNavItem
                to={USERS}
                text={t('nav.mainNav.employees')}
                active={location?.pathname?.includes(USERS)}
                mode={mode}
                icon={
                  <IconWrapper isActive={location?.pathname?.includes(USERS)}>
                    <IdentificationBadge />
                  </IconWrapper>
                }
              />
            </If>

            <Can I="canview" a="vehicles">
              <MainNavItem
                to={`${VEHICLES}/logistic`}
                text={t('nav.mainNav.vehicles')}
                tabs={[VEHICLES_LOGISTIC, VEHICLES_SALES]}
                mode={mode}
                icon={
                  <IconWrapper
                    isActive={isActiveNestedRoute(location, [
                      VEHICLES_LOGISTIC,
                      VEHICLES_SALES,
                    ])}
                  >
                    <Car />
                  </IconWrapper>
                }
              />
            </Can>

            <Can I="canview" a="accessories">
              <MainNavItem
                to={ACCESSORIES}
                text={t('nav.mainNav.accessories')}
                mode={mode}
                active={location.pathname?.includes(ACCESSORIES)}
                icon={
                  <IconWrapper
                    isActive={location.pathname?.includes(ACCESSORIES)}
                  >
                    <Devices />
                  </IconWrapper>
                }
              />
            </Can>

            <Can I="canview" a="tools">
              <MainNavItem
                to={TOOLS}
                text={t('nav.mainNav.tools')}
                mode={mode}
                active={location.pathname?.includes(TOOLS)}
                icon={
                  <IconWrapper isActive={location.pathname?.includes(TOOLS)}>
                    <img src={PalletTruck} alt="" />
                  </IconWrapper>
                }
              />
            </Can>

            <Can I="canview" a="paymentcards">
              <MainNavItem
                to={PAYMENTCARDS}
                text={t('nav.mainNav.paymentcards')}
                mode={mode}
                active={
                  location.pathname?.includes(PAYMENTCARDS) ||
                  location.pathname?.includes(AccessoryType.paymentCards)
                }
                icon={
                  <IconWrapper
                    isActive={
                      location.pathname?.includes(PAYMENTCARDS) ||
                      location.pathname?.includes(AccessoryType.paymentCards)
                    }
                  >
                    <Cardholder />
                  </IconWrapper>
                }
              />
            </Can>
            <Can I="canview" a="supportunits">
              <MainNavItem
                to={SUPPORTUNIT}
                text={t('nav.mainNav.supportUnit')}
                mode={mode}
                icon={
                  <IconWrapper isActive={isActiveRoute(location, SUPPORTUNIT)}>
                    <Shapes />
                  </IconWrapper>
                }
              />
            </Can>
          </Collapse>
        </LastSectionWrapper>
      </If>
    </ScrollableSection>
  );
}

const Title = styled.p<{ textColorSecondary: string }>`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 0.813em;
  line-height: 15px;
  text-transform: uppercase;
  margin-left: 19px;
  color: ${(props) => props?.textColorSecondary};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
`;

const MenuSectionWrapper = styled.div`
  border-bottom: solid 1px ${themes?.default?.lightFill};
  padding-bottom: 8px;
`;

const GeneralSectionWrapper = styled.div`
  border-bottom: solid 1px ${themes?.default?.lightFill};
  padding: 8px 0;
`;

const LastSectionWrapper = styled.div`
  padding-top: 8px;
`;

const ScrollableSection = styled.div`
  max-height: calc(100vh - 85px);
  overflow-y: hidden;
  overflow-x: hidden;

  @supports selector(::-webkit-scrollbar) {
    scrollbar-gutter: stable;
    margin-right: -7px;
  }

  &:hover {
    overflow-y: overlay;
  }
`;

interface IconWrapperProps {
  isActive: boolean;
}

const IconWrapper = styled.div<IconWrapperProps>`
  img,
  svg {
    border-color: ${(props) =>
      props.isActive
        ? props.theme.primaryActiveColor
        : props.theme.gainsboro2} !important;
    color: ${(props) =>
      props.isActive
        ? props.theme.primaryActiveColor
        : props.theme.iconColor} !important;
  }
`;
