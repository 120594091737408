import React, { useState, useEffect, useMemo, useContext } from 'react';
import { ChatContext } from 'app/components/Chat';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { ButtonBase, Divider } from '@mui/material';
import { UserAvatar } from 'app/components/Chat/components/UserAvatar';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import EllipsisText from 'app/components/EllipsisText';
import { ActionPopover, PopoverEl } from 'app/components/Popover';
import { useTranslation } from 'react-i18next';
import { Icon } from 'app/components/Icon';
import { PushPin } from '@phosphor-icons/react';
import {
  useDeleteChatMutation,
  useTogglePinChatMutation,
  useClearChatHistoryMutation,
} from 'common/services/chatApi';
import { useModal } from 'app/components/Dialog/hooks';

interface Props {
  pinned: boolean;
  id: string;
  fullUser: any;
  selectedUserId: string;
  pictureUrl: string;
  fullName: string;
  role: string;
  lastMessageAt: string;
  lastMessage: string;
  unreadMessagesCount?: number;
}

export const ChatCard: React.FC<Props> = ({
  pinned,
  id,
  fullUser,
  selectedUserId,
  pictureUrl,
  fullName,
  role,
  lastMessageAt,
  lastMessage,
  unreadMessagesCount = 0,
}) => {
  const {
    selectedUser,
    setSelectedUser,
    triggerRefetchChats,
    currentUserId,
    setFilteredMessages,
  } = useContext(ChatContext);
  const theme = useSelector(selectTheme);
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [hovered, setHovered] = useState(false);
  const { closeModal, openModal } = useModal();

  const isSelectedChat = useMemo(() => {
    return selectedUser?._id === selectedUserId;
  }, [selectedUser, selectedUserId]);

  useEffect(() => {
    if (!menuOpen) setHovered(false);
  }, [menuOpen]);

  const [deleteChat] = useDeleteChatMutation();

  const handleCofirmDeleteDialog = (name) => {
    openModal({
      action: {
        actionText: t('common.buttons.delete'),
        actionCallback: () => handleDeleteChat(),
      },
      title: t('chats.chat.delete.confirm'),
      deleteModal: true,
      cancel: true,
      content: t('chats.chat.delete.messsage', { name: name }),
    });
  };

  const handleDeleteChat = async () => {
    if (!id) return;
    await deleteChat({ ids: [id] }).then(() => {
      triggerRefetchChats();
      closeModal();
      setSelectedUser(null);
    });
  };

  const [togglePinChat] = useTogglePinChatMutation();
  const [clearChat] = useClearChatHistoryMutation();

  const handleTogglePinChat = async () => {
    if (!id) return;
    await togglePinChat({ id: id }).then(() => {
      triggerRefetchChats();
    });
  };

  const handleClearChatModal = (name) => {
    openModal({
      action: {
        actionText: t('common.clear'),
        actionCallback: () => handleClearChat(),
      },
      title: t('clear_history'),
      deleteModal: false,
      cancel: true,
      content: t('chat.clear_history_confirmation_message', { name: name }),
    });
  };

  const handleClearChat = async () => {
    // closeModal();
    closeModal();
    if (!id) return;
    await clearChat({ body: { ids: [id] } }).then(async () => {
      await triggerRefetchChats();
      setFilteredMessages([]);
    });
  };

  return (
    <Wrapper
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      hovered={hovered || menuOpen}
    >
      <ButtonBase
        onClick={() => setSelectedUser({ ...fullUser, _id: selectedUserId })}
        sx={{ borderRadius: '4px', flex: 1 }}
      >
        <ButtonContentWrapper selected={isSelectedChat}>
          <ContactCardWrapper
            selected={isSelectedChat}
            hovered={hovered || menuOpen}
          >
            <UserAvatar
              size={32}
              userImagePath={pictureUrl}
              fullName={fullName}
              // status={onlineStatus ? onlineStatus : 'offline'}
            />
            <UserInfo primaryColor={theme.primary}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', gap: '2px' }}>
                  <p className="name">{fullName}</p>
                  <p className="name">{` - `}</p>
                  <p className="role">{role}</p>
                  {currentUserId === selectedUserId && (
                    <p className="role">{`(${t('you')})`}</p>
                  )}
                </div>
                <p className="time">{lastMessageAt}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className="lastMessage">
                  <EllipsisText width={220} text={lastMessage} />
                </p>
                {selectedUser?._id !== selectedUserId &&
                unreadMessagesCount > 0 ? (
                  <div className="unreadMessagesCount">
                    {unreadMessagesCount}
                  </div>
                ) : pinned ? (
                  <Icon icon={<PushPin />} size={16} />
                ) : null}
              </div>
            </UserInfo>
          </ContactCardWrapper>
        </ButtonContentWrapper>
      </ButtonBase>
      <ContactActionsButtonWrapper
        selected={isSelectedChat}
        className="ContactActionsButtonWrapper"
      >
        <ActionPopover
          id={fullUser?._id}
          handleAction={async () => {
            setMenuOpen(false);
          }}
          styling={{ justifyContent: 'end' }}
          iconSize={24}
          setMenuOpen={setMenuOpen}
          actions={[
            pinned
              ? {
                  action: 'unpin',
                  onClick: handleTogglePinChat,
                  element: (
                    <PopoverEl redStatus="" style={{ width: '100%' }}>
                      <div className="popover-item">{t('common.unpin')}</div>
                    </PopoverEl>
                  ),
                }
              : {
                  action: 'pin',
                  onClick: handleTogglePinChat,
                  element: (
                    <PopoverEl redStatus="" style={{ width: '100%' }}>
                      <div className="popover-item">{t('common.pin')}</div>
                    </PopoverEl>
                  ),
                },
            // {
            //   action: 'markAsUnread',
            //   onClick: () => {},
            //   element: (
            //     <PopoverEl redStatus="" style={{ width: '100%' }}>
            //       <div className="popover-item">{t('mark_as_unread')}</div>
            //     </PopoverEl>
            //   ),
            // },
            {
              action: 'clearHistory',
              onClick: () => handleClearChatModal(fullName),
              element: (
                <PopoverEl redStatus="" style={{ width: '100%' }}>
                  <div className="popover-item">{t('clear_history')}</div>
                </PopoverEl>
              ),
            },
            {
              action: 'divider',
              seperator: true,
              element: (
                <PopoverEl redStatus="" style={{ width: '100%' }}>
                  <Divider />
                </PopoverEl>
              ),
            },
            {
              action: 'delete',
              onClick: () => handleCofirmDeleteDialog(fullName),
              element: (
                <PopoverEl
                  redStatus={themes.default.redStatus}
                  style={{ width: '100%' }}
                >
                  <div className="delete popover-item">{t('delete')}</div>
                </PopoverEl>
              ),
            },
          ]}
        />
      </ContactActionsButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ hovered: boolean }>`
  position: relative;
  display: flex;
  box-sizing: border-box;
  .ContactActionsButtonWrapper {
    opacity: ${(props) => (props.hovered ? 1 : 0)};
  }
`;

const ButtonContentWrapper = styled.div<{ selected: boolean }>`
  width: 100%;
  max-height: 64px;
  min-height: 64px;
  background: ${(props) =>
    props.selected
      ? 'linear-gradient(166.61deg, #29B6F6 -0.09%, #2A59FF 105.26%)'
      : ''};
  padding: ${(props) => (props.selected ? '1px' : '')};
  border: ${(props) =>
    props.selected ? '' : `1px solid ${themes?.default?.gainsboro2}`};
  border-radius: 4px;
  box-sizing: border-box;
`;

const ContactCardWrapper = styled.div<{ selected: boolean; hovered: boolean }>`
  max-height: 62px;
  min-height: 62px;
  width: 100%;
  padding: 12px;
  border-radius: ${(props) => (props.selected ? '3px' : '4px')};
  display: flex;
  gap: 8px;
  transition: background 0.2s;
  cursor: pointer;
  background: ${(props) =>
    props.selected
      ? themes?.default?.lightBlueBg
      : props.hovered
        ? themes?.default?.lightGrey
        : themes?.default?.accordionWhiteBg};
  box-sizing: border-box;
`;

export const UserInfo = styled.div<{ primaryColor?: string }>`
  flex: 1;
  color: ${themes?.default?.textColorPrimary};
  line-height: 15.23px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  p {
    margin: 0;
    text-align: left;
  }
  .name {
    font-size: 0.92rem;
    font-weight: 700;
  }
  .role {
    font-size: 0.92rem;
  }
  .time {
    font-size: 0.85rem;
    font-weight: 400;
    line-height: 14.06px;
    color: ${themes?.default?.textColorSecondary};
  }
  .lastMessage {
    font-size: 0.85rem;
    font-weight: 400;
    line-height: 20px;
    color: ${themes?.default?.textColorDashboardNoOpacity};
  }
  .unreadMessagesCount {
    height: 1rem;
    width: 1rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.primaryColor};
    color: ${themes?.default?.accordionWhiteBg};
    font-size: 0.714rem;
    font-weight: 700;
    line-height: 1rem;
    text-align: center;
  }
`;

const ContactActionsButtonWrapper = styled.div<{ selected: boolean }>`
  width: 74px;
  border-radius: 0 3px 3px 0;
  position: absolute;
  top: 1px;
  bottom: 1px;
  right: 1px;
  padding: 0 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: 0.2s;
  opacity: 0;
  background: ${(props) =>
    props.selected ? themes?.default?.lightBlueBg : '#FAFAFAF2'};
`;
