import { Button, Grid, Tab, Tabs } from '@mui/material';
import { Bell, BellRinging, Checks } from '@phosphor-icons/react';
import { VerticalDivider } from 'app/components/EnhancedTableToolbar/components/VerticalDivider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

export function UnderToolbarNotification({ readNotifications, totalUnread }) {
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);

  const { t } = useTranslation();

  const handleTabChange = (_event, newValue) => {
    setCurrentTabIndex(newValue);
  };

  return (
    <Wrapper>
      <Tabs
        sx={{ minHeight: '40px', width: 'fit-content' }}
        value={currentTabIndex}
        onChange={handleTabChange}
      >
        <Tab
          label={
            <Grid container alignItems="center" gap="4px">
              <span>{t('notification.important')}</span>
              <div
                className="count"
                style={{
                  width: 'fit-content',
                  height: '20px',
                  padding: '2px 4px',
                  borderRadius: '3px',
                  backgroundColor:
                    currentTabIndex === 0
                      ? themes?.default?.primary
                      : themes?.default?.backgroundGreyColor,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color:
                    currentTabIndex === 0
                      ? themes?.default?.baseWhite
                      : themes?.default?.textColorDashboard,
                }}
              >
                {totalUnread || 0}
              </div>
            </Grid>
          }
          icon={<BellRinging size={24} />}
          iconPosition="start"
          value={0}
          sx={{ minHeight: '40px' }}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <VerticalDivider />
        </div>
        <Tab
          label={
            <Grid container alignItems="center" gap="4px">
              <span>{t('common.other')}</span>
              <div
                className="count"
                style={{
                  width: 'fit-content',
                  height: '20px',
                  padding: '2px 4px',
                  borderRadius: '3px',
                  backgroundColor:
                    currentTabIndex === 1
                      ? themes?.default?.primary
                      : themes?.default?.backgroundGreyColor,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color:
                    currentTabIndex === 1
                      ? themes?.default?.baseWhite
                      : themes?.default?.textColorDashboard,
                }}
              >
                0
              </div>
            </Grid>
          }
          value={1}
          sx={{ minHeight: '40px' }}
          icon={<Bell size={24} />}
          iconPosition="start"
        />
      </Tabs>
      <Button
        onClick={readNotifications}
        startIcon={<Checks color={themes?.default?.grey2} />}
        sx={{
          marginLeft: 'auto',
          color: 'black',
        }}
      >
        {t('common.buttons.clear_all')}
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  background: ${themes?.default?.accordionWhiteBg};
  padding: 0 20px 0 20px;
  border-bottom: 1px solid ${themes?.default?.lightGrey3};
`;
