import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { ButtonBase, Popover, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Icon } from 'app/components/Icon';
import { CaretDown } from '@phosphor-icons/react';
import { styled as muiStyled } from '@mui/styles';
import { JumpToDatePicker } from '../JumpToDatePicker';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat.js';
dayjs.extend(advancedFormat);

interface Props {
  date: string;
  handleScrollToGroup: Function;
  handleScrollToBeginning: Function;
}

export const DateButton: React.FC<Props> = ({
  date,
  handleScrollToGroup,
  handleScrollToBeginning,
}) => {
  /* ---------------------------- Use States ---------------------------- */
  const [optionsAnchorEl, setOptionsAnchorEl] = useState<any>(null);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [dateValue, setDateValue] = useState<any>(null);

  /* ----------------------------- Variables ---------------------------- */
  const { t } = useTranslation();

  /* ----------------------------- Functions ---------------------------- */
  const handleClose = () => {
    setDatePickerOpen(false);
    setOptionsAnchorEl(null);
    setDateValue(null);
  };

  const handleOptionsClick = (day) => {
    handleScrollToGroup(day);
    handleClose();
  };

  /* ---------------------------- Use Effects --------------------------- */
  useEffect(() => {
    if (!!dateValue) {
      const today = dayjs();
      let formattedDate = '';

      if (today.isSame(dateValue, 'date')) {
        formattedDate = t('calendar.today');
      } else if (dateValue.isYesterday()) {
        formattedDate = t('yesterday');
      } else {
        formattedDate = dateValue.format('dddd, MMMM Do');
      }

      handleClose();
      handleScrollToGroup(formattedDate);
    }
  }, [dateValue]);

  /* -------------------------------------------------------------------- */

  return (
    <Wrapper>
      <ButtonWrapperCentered>
        <ButtonBase
          sx={buttonSyles}
          onClick={(e) => setOptionsAnchorEl(e?.target)}
        >
          <Text>{date}</Text>
          <Icon
            icon={<CaretDown />}
            size={12}
            color={`${themes?.default?.black}42`}
          />
        </ButtonBase>
      </ButtonWrapperCentered>
      <Popover
        open={!!optionsAnchorEl}
        anchorEl={optionsAnchorEl}
        anchorOrigin={{ vertical: 24, horizontal: -10 }}
        onClose={() => setOptionsAnchorEl(null)}
      >
        <PopoverContent>
          <PopoverTitle>{t('date.jump_to')}</PopoverTitle>
          <StyledMenuItem
            onClick={() => handleOptionsClick(t('calendar.today'))}
          >
            {t('calendar.today')}
          </StyledMenuItem>
          <StyledMenuItem onClick={() => handleOptionsClick(t('yesterday'))}>
            {t('yesterday')}
          </StyledMenuItem>
          <StyledMenuItem
            onClick={() => {
              handleScrollToBeginning();
              handleClose();
            }}
          >
            {t('date.the_very_beginning')}
            <MenuItemsSeparator />
          </StyledMenuItem>
          <StyledMenuItem onClick={() => setDatePickerOpen(true)}>
            {t('date.jump_to_specific_date')}
          </StyledMenuItem>
        </PopoverContent>
      </Popover>
      <JumpToDatePicker
        open={datePickerOpen}
        onClose={() => handleClose()}
        dateValue={dateValue}
        setDateValue={setDateValue}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 0px 20px 0px;
  position: sticky;
  top: 0;
  z-index: 2;
`;

const ButtonWrapperCentered = styled.div`
  width: 100%;
  height: 26px;
  display: flex;
  justify-content: center;
  z-index: 2;
`;

const Text = styled.p`
  margin: 0px 0px 0px 6px;
  fontsize: 0.85rem;
  height: 20px;
  lineheight: 20px;
  color: ${themes?.default?.textColorPrimary};
`;

const buttonSyles = {
  width: 'fit-content',
  height: '26px',
  padding: '3px 4px',
  borderRadius: '13px',
  border: `1px solid ${themes?.default?.gainsboro2}`,
  background: themes?.default?.accordionWhiteBg,
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  position: 'relative',
  zIndex: '3',
};

const PopoverContent = styled.div`
  display: flex;
  min-width: 172px;
  height: 162px;
  flex-direction: column;
`;

const PopoverTitle = styled.div`
  padding: 8px 16px 0px 16px;

  font-size: 0.71rem;
  letter-spacing: 0.17px;
  color: ${themes?.default?.nobel};
`;

const StyledMenuItem = muiStyled(MenuItem)({
  height: '35px',
  position: 'relative',
  fontSize: '0.92rem',
  letterSpacing: '0.17px',
  color: themes?.default?.textColorPrimary,
});

const MenuItemsSeparator = styled.div`
  height: 0.5px;
  background: ${themes?.default?.gainsboro2};
  width: calc(100% - 8px - 8px);
  margin: 0 8px;
  position: absolute;
  bottom: 0;
  left: 0;
`;
