import React, { useEffect, useState, useContext } from 'react';
import { useApiCalls } from '../../hooks/useApiCalls';
import { useGrid } from 'app/components/Grid/hooks';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Autocomplete,
  FormControl,
  Grid,
  IconButton,
} from '@mui/material';
import { useGetWarehousesQuery } from 'common/services/organizationApi';
import { SelectOptionWrapper } from 'app/components/FilterComponents/styles';
import { useTranslation } from 'react-i18next';
import {
  useGetTypeAislesQuery,
  useGetTypeListQuery,
  useAddAreaMutation,
} from 'common/services/storageLocationApi';
import { StorageLocationContext } from '../../../StorageLocationProvider';
import { useToaster } from 'hooks/useToaster';
import CloseIcon from '@mui/icons-material/Close';
import { useQueryParams } from 'hooks/useQueryParams';

interface selectItem {
  _id: string;
  label: string;
}

export const AddUpdateArea = ({
  updateFormData,
  formData,
  validationErrors,
  setErrors,
}) => {
  const { t } = useTranslation();
  const [addAreaAction] = useAddAreaMutation();
  const [selectedAisles, setSelectedAisles]: any = useState([]);
  const [selectedType, setSelectedType]: any = useState();
  const [selectedDecks, setSelectedDecks]: any = useState([]);

  const { closeModal, isDisabled, setIsDisabled } = useContext(
    StorageLocationContext,
  );
  const toast = useToaster();
  const commonQueryParams = {
    status: 'active',
  };
  const { filters } = useGrid();
  const queryParams = useQueryParams(commonQueryParams);
  const { data: warehousesData } = useGetWarehousesQuery(queryParams);
  const [warehouses, setWarehouses]: any = useState();
  const [disabledAisle, setDisabledAisle] = useState<boolean>(true);

  const { data: aisles } = useGetTypeAislesQuery(formData._type, {
    skip: formData._type === null,
  });

  const { data: types } = useGetTypeListQuery('', {});
  useEffect(() => {
    if (warehousesData?.docs) {
      const formattedData: any = [];
      const selectedDeck: any = [];
      for (let i = 0; i < warehousesData.docs.length; i++) {
        if (filters._deck && filters._deck === warehousesData.docs[i]._id) {
          selectedDeck.push({
            label: warehousesData.docs[i].name,
            _id: warehousesData.docs[i]._id,
          });
        }
        formattedData.push({
          label: warehousesData.docs[i].name,
          _id: warehousesData.docs[i]._id,
        });
      }

      setWarehouses(formattedData);
      setSelectedDecks(selectedDeck);
    }
  }, [warehousesData]);

  const renderOption = (props, field) => {
    return (
      <div {...props} style={{ opacity: 1 }}>
        <SelectOptionWrapper>
          <div>{field.label}</div>
        </SelectOptionWrapper>
      </div>
    );
  };

  const handleAisleChange = (
    event: React.ChangeEvent<{}>,
    items: selectItem[],
  ) => {
    setSelectedAisles(items);
    const result: any = [];
    items.map((item) => result.push(item._id));
    updateFormData('aisles', result);
  };
  const { refetchAreas } = useApiCalls();

  const submit = async () => {
    try {
      const resp: any = await addAreaAction(formData).unwrap();

      if (resp) {
        if (!resp.error) {
          toast(5000, 'success', 'alerts.areas.add_success');
          refetchAreas();
          closeModal();
        }
      }
    } catch (err: any) {
      if (err.status === 400) {
        if (err.data?.validation) {
          err.data?.validation.forEach((errorItem) => {
            setErrors({
              ...validationErrors,
              [errorItem.context.key]: t(errorItem.message),
            });
          });
        }
      }
    }
  };

  const updateAisle = () => {
    setSelectedAisles([]);
    setIsDisabled(true);
  };

  const handleLocationWarehouseChange = (
    event: React.ChangeEvent<{}>,
    items: selectItem[],
  ) => {
    const result: any = [];
    items.map((item) => result.push(item._id));
    updateFormData('decks', result);
    setSelectedDecks(items);
  };
  const handleTypesChange = (
    event: React.ChangeEvent<{}>,
    item: selectItem,
  ) => {
    updateFormData('_type', item?._id);
    if (item?._id) {
      setDisabledAisle(false);
    } else {
      setDisabledAisle(true);
    }
    updateAisle();
    setSelectedType(item);
  };

  return (
    <Dialog
      open={true}
      fullWidth
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <strong>{t('storage.locations.add_area')} </strong>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          <Grid
            container
            sx={{
              justifyContent: 'space-between',
            }}
          >
            <>
              <FormControl size="small" sx={{ width: '100%' }}>
                <Autocomplete
                  size="small"
                  multiple={true}
                  options={warehouses || []}
                  getOptionLabel={(warehouse) => warehouse.label}
                  fullWidth
                  value={selectedDecks}
                  onChange={handleLocationWarehouseChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('warehouse')}
                      placeholder={t('warehouse')}
                      fullWidth
                      required
                      error={validationErrors.decks}
                      helperText={validationErrors.decks}
                      sx={{ marginBottom: '0 !important' }}
                    />
                  )}
                  renderOption={renderOption}
                />
              </FormControl>
              <FormControl size="small" sx={{ width: '100%' }}>
                <TextField
                  required={true}
                  onChange={(e) => updateFormData('name', e.target.value)}
                  label={t('common.name')}
                  variant="outlined"
                  type="text"
                  value={formData.name}
                  size="small"
                  error={validationErrors.name}
                  helperText={validationErrors.name}
                  inputProps={{ maxLength: 40 }}
                  sx={{ marginBottom: '0 !important' }}
                />
              </FormControl>
              <FormControl size="small" sx={{ width: '100%' }}>
                <Autocomplete
                  size="small"
                  options={types || []}
                  getOptionLabel={(option) => option.label}
                  onChange={handleTypesChange}
                  value={selectedType}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('common.type')}
                      placeholder={t('common.type')}
                      fullWidth
                      required
                      error={validationErrors._type}
                      helperText={validationErrors._type}
                      sx={{ marginBottom: '0 !important' }}
                    />
                  )}
                  renderOption={renderOption}
                />
              </FormControl>
              <FormControl size="small" sx={{ width: '100%' }}>
                <Autocomplete
                  size="small"
                  multiple={true}
                  disabled={disabledAisle}
                  options={aisles || []}
                  value={selectedAisles}
                  getOptionLabel={(option) => option.label}
                  onChange={handleAisleChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('common.aisles')}
                      placeholder={t('common.aisles')}
                      fullWidth
                      required
                      sx={{ marginBottom: '0 !important' }}
                      error={validationErrors.aisles}
                      helperText={validationErrors.aisles}
                    />
                  )}
                  renderOption={renderOption}
                />
              </FormControl>
            </>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ marginTop: '5px', marginRight: '14px' }}>
        <Button onClick={closeModal} color="inherit">
          {t('common.buttons.cancel')}
        </Button>
        <Button
          onClick={submit}
          disabled={isDisabled}
          variant="contained"
          autoFocus
          sx={{ height: '33px', width: '88px' }}
        >
          {t('common.buttons.add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
