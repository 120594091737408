import React, { useCallback, useMemo, useState } from 'react';
import { MenuOption } from '../types';
import { Menu } from '..';

export const useMenu = (options: MenuOption[]) => {
  const [menuAnchor, setMenuAcnhor] = useState<null | HTMLElement>(null);
  const open = Boolean(menuAnchor);

  const openMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setMenuAcnhor(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuAcnhor(null);
  }, []);

  const MenuComponent = useMemo(() => {
    return (
      <Menu
        options={options}
        open={open}
        anchorEl={menuAnchor}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      />
    );
  }, [options, closeMenu, menuAnchor, open]);

  return { Menu: MenuComponent, openMenu, closeMenu, isMenuOpen: open };
};
