import { SwipeableDrawer } from '@mui/material';
import { FuelDetails } from 'app/components/FuelDetails';
import If from 'app/components/If';
import { OrderDetail } from 'app/components/OrderDetail';

import { isEmpty } from 'lodash';

import styled from 'styled-components';

interface SwipeableDrowerOrderProps {
  selectedOrder: any;
  handleCloseOrderDrawer: () => void;
  onUpdate?: () => void;
  orderChangeStatus?: (status: string) => void;
  triggerRoundtripDetails?: Function;
  viewMode?: boolean;
  handleDisplayMap?: any;
  defaultTab?: number;
}

export function SwipeableDrowerOrder({
  selectedOrder,
  handleCloseOrderDrawer,
  onUpdate,
  orderChangeStatus,
  triggerRoundtripDetails,
  viewMode,
  handleDisplayMap,
  defaultTab = 0,
}: SwipeableDrowerOrderProps) {
  // hooks

  return (
    <SwipeableDrawer
      anchor="right"
      open={!isEmpty(selectedOrder)}
      onOpen={() => {}}
      onClose={() => handleCloseOrderDrawer()}
    >
      <DrawerContent>
        <If condition={selectedOrder}>
          <If
            condition={selectedOrder?.type === 'fuel'}
            otherwise={
              <OrderDetail
                order={selectedOrder}
                onCloseDrawer={handleCloseOrderDrawer}
                isCustomer={false}
                onUpdate={onUpdate}
                changeStatus={orderChangeStatus}
                triggerRoundtripDetails={triggerRoundtripDetails}
                viewMode={viewMode ?? false}
                handleDisplayMap={handleDisplayMap}
                defaultTab={defaultTab}
              />
            }
          >
            <FuelDetails
              order={selectedOrder}
              onCloseDrawer={handleCloseOrderDrawer}
            />
          </If>
        </If>
      </DrawerContent>
    </SwipeableDrawer>
  );
}

const DrawerContent = styled.div`
  height: 100vh;
  max-width: 100%;
  margin: auto;
`;
