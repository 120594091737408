import { Chip, IconButton, styled as muiStyled } from '@mui/material';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

interface FilterNameProps {
  isHighlighted?: boolean;
}

export const FilterName = styled.div<FilterNameProps>`
  font-weight: 500;
  font-size: 0.875rem;
  color: ${(props) =>
    props.isHighlighted ? props.theme.primaryActiveColor : 'inherit'};
`;

export const FilterTitle = styled(FilterName)`
  color: ${(props) => props.theme.textColorSecondary};
  margin-bottom: 10px;
  font-size: 0.875rem;
`;
export const ToggleIcon = styled(IconButton)`
  padding: 0 !important;
  justify-content: end !important;
`;

export const FilterSection = styled.div`
  max-height: 80vh;
  overflow-y: scroll;
  padding-right: 16px;
  overflow-x: hidden;

  a {
    cursor: pointer;
    color: ${(props) => props.theme.primary};
  }
`;

export const FilterHead = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 22px;
`;

export const FilterContainer = styled.div`
  width: 400px;
  padding: 16px 3px 16px 16px;
  .seperator {
    border: 0.8px solid ${(props) => props.theme?.textColorSecondary}80;
    width: 171px;
  }
  button {
    width: 100%;
    justify-content: left;
  }
  .button_icon {
    display: flex;
    align-items: center;
  }
  .title_action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      width: 37px;
      height: 37px;
    }
    cursor: pointer;
  }
`;
export const SelectOptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const DisabledChip = muiStyled(Chip)({
  '&.MuiChip-root': {
    backgroundColor: themes.default.lightGrey,
  },
  '& > span.MuiChip-label': {
    color: themes.default.darkGray,
  },
});
