import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Popover, Typography } from '@mui/material';
import { CustomIconButton } from 'app/components/Chat/components/CustomIconButton';
import { SidebarSimple, Browsers, X } from '@phosphor-icons/react';
import { StateSetter } from 'types';

interface ModalHeaderProps {
  title: string;
  onClose: () => void;
  viewMode: 'sidebar' | 'floated';
  setViewMode: StateSetter<'sidebar' | 'floated'>;
}

export const ModalHeader = ({
  title,
  onClose,
  viewMode,
  setViewMode,
}: ModalHeaderProps) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const popoverOpen = useMemo(() => {
    return !!anchorEl;
  }, [anchorEl]);

  function handleOpenPopover(e) {
    setAnchorEl(e.currentTarget || e.target);
  }

  function handleClosePopover() {
    setAnchorEl(null);
  }

  function handleChangeViewMode(mode: 'sidebar' | 'floated') {
    setViewMode(mode);
    handleClosePopover();
  }

  return (
    <>
      <Typography fontSize="1rem" fontWeight="700">
        {title || 'modal not titled'}
      </Typography>

      <HeaderToolBar>
        {/* close and switch mode btn */}

        <CustomIconButton
          icon={
            viewMode === 'sidebar' ? (
              <SidebarSimple weight="bold" />
            ) : (
              <Browsers weight="bold" />
            )
          }
          iconStyles={
            viewMode === 'sidebar' ? { transform: 'rotate(180deg)' } : {}
          }
          isDropdown={true}
          size={16}
          baseSize={34}
          square={true}
          onClick={(e) => handleOpenPopover(e)}
        />
        <CustomIconButton
          icon={<X weight="bold" />}
          size={16}
          baseSize={34}
          square={true}
          onClick={onClose}
        />

        {/* view switch popover */}
        <Popover
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <ViewModesWrapper>
            <CustomIconButton
              icon={<SidebarSimple weight="bold" />}
              iconStyles={{ transform: 'rotate(180deg)' }}
              size={16}
              baseSize={32}
              square={true}
              active={viewMode === 'sidebar'}
              onClick={() => handleChangeViewMode('sidebar')}
            />
            <CustomIconButton
              icon={<Browsers weight="bold" />}
              size={16}
              baseSize={32}
              square={true}
              active={viewMode === 'floated'}
              onClick={() => handleChangeViewMode('floated')}
            />
          </ViewModesWrapper>
        </Popover>
      </HeaderToolBar>
    </>
  );
};

const ViewModesWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 10%;
  border-radius: 0;
`;

const HeaderToolBar = styled.div``;
