import React, { useMemo } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

interface Props {
  status: 'online' | 'offline';
  size?: number;
}

export const UserStatusDot: React.FC<Props> = ({ status, size = 4 }) => {
  const color = useMemo(() => {
    if (status === 'online') {
      return themes?.default?.greenLaurel;
    } else {
      return themes?.default?.badgeRed;
    }
  }, [status]);
  return <Wrapper size={size} color={color} />;
};

const Wrapper = styled.div<{ size: number; color: string }>`
  height: ${(props) => props.size}px !important;
  width: ${(props) => props.size}px !important;
  border-radius: ${(props) => props.size}px !important;
  outline: 1px solid ${themes?.default?.grey};
  background: ${(props) => props.color};
  position: absolute;
  bottom: 2px;
  right: 0;
`;
