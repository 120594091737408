import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableRow,
  Typography,
} from '@mui/material';
import { isEmpty, isUndefined } from 'lodash';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { useLazyGetOrdersIdQuery } from 'common/services/orderApi';

import { StatusOrder } from 'app/pages/AddRoundTrips/data/RoundTripData/components/StatusOrder';
import MapPopover from '../MapPopover';
import { themes } from 'styles/theme/themes';
import {
  SummaryLabel,
  SummaryTableCell,
  SummaryTableValue,
} from '../OrderDetail/tabs/OrderInformation';
import {
  ArrowWrapper,
  ButtonWrapper,
  FooterWrapper,
  HeaderWrapper,
  LeftHeaderWrapper,
  RightHeaderWrapper,
  Wrapper,
} from '../OrderDetail';
import styled from 'styled-components';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CloseIcon from '@mui/icons-material/Close';
import If from '../If';
import { getAddressLabel } from 'app/helpers/helpers';
import { AddressIcon } from '../AddressIcon';

interface FuelDetailProps {
  order: any;
  onCloseDrawer: () => void;
}

export function FuelDetails({ order, onCloseDrawer }: FuelDetailProps) {
  const { t, i18n } = useTranslation();
  const { token } = useParams();

  const [openMap, setOpenMap] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<any>([]);
  const [selectedPreview, setSelectedPreview] = useState<any>({});

  const [triggerGetOrder] = useLazyGetOrdersIdQuery();

  useEffect(() => {
    if (order?.id && !isEmpty(order?.id)) {
      // @ts-ignore
      console.log('order?.id', order?.id);
      triggerGetOrder(order?.id);
    }
  }, [order?.id]);

  const location = order?._place?.location;

  useEffect(() => {
    setCoordinates(location?.geometry?.coordinates);
  }, [location]);

  const locale = i18n?.language ?? 'en';

  const handleDownload = (url: string, name: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = name;
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error downloading the image:', error);
      });
  };

  const handleClosePreview = () => {
    setSelectedPreview(null);
  };

  if (!order) {
    return null;
  }

  return (
    <>
      <FuelWrapper>
        <HeaderWrapper>
          <ArrowWrapper>
            <ArrowBackIcon
              color="action"
              fontSize="small"
              onClick={onCloseDrawer}
              sx={{ cursor: 'pointer' }}
            />
          </ArrowWrapper>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          >
            <Grid item xs={12} md={6}>
              <LeftHeaderWrapper>
                <Typography variant="h5" fontSize="1rem" fontWeight="700">
                  {t('fuel_details')}
                </Typography>
                <div style={{ marginLeft: 10 }}>
                  <StatusOrder
                    status={order?.status}
                    context={token ? 'ordoria' : 'bianta'}
                  />
                </div>
              </LeftHeaderWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <RightHeaderWrapper>
                <Typography
                  fontSize="0.75rem"
                  fontWeight="400"
                  color={themes?.default?.textColorSecondary}
                >
                  {t('clients.orderDetails.createdAt')}:{' '}
                  {window.dayjs(order.createdAt).locale(locale).format('LLL')}
                  {!isEmpty(order._createdBy) &&
                    !isUndefined(order._createdBy.fullName) &&
                    ` ${t('common.by').toLowerCase()} ${
                      order._createdBy.fullName
                    }`}
                </Typography>
              </RightHeaderWrapper>
            </Grid>
            <Typography
              fontSize="0.813rem"
              fontWeight="500"
              sx={{
                marginLeft: '3px',
                marginTop: '10px',
                color: themes.default.textColorSecondary,
              }}
            >
              <AddressIcon
                onClick={() => {
                  setOpenMap(true);
                }}
              />
              {location?.name
                ? `${getAddressLabel(location)?.toUpperCase()}`
                : t('clients.orderDetails.noDeliveryAddress').toUpperCase()}
            </Typography>
          </Grid>
        </HeaderWrapper>

        <Divider sx={{ mt: '8px', mb: '16px', mr: '-58px', ml: '-58px' }} />

        <If
          condition={
            order?.fuelQuantity && order?.fuelAmount && order?.documents?.length
          }
          otherwise={<EmptyData>{t('no_data_available_here_yet')}</EmptyData>}
        >
          <Table>
            <TableBody>
              <TableRow>
                <SummaryTableCell sx={{ paddingLeft: '20px !important' }}>
                  <SummaryLabel>{t('gas_liter')}</SummaryLabel>
                  <SummaryTableValue>
                    {order?.fuelQuantity?.toLocaleString()}
                  </SummaryTableValue>
                </SummaryTableCell>
                <SummaryTableCell sx={{ paddingLeft: '20px !important' }}>
                  <SummaryLabel>{t('total')} (€)</SummaryLabel>
                  <SummaryTableValue>
                    {order?.fuelAmount?.toLocaleString()}
                  </SummaryTableValue>
                </SummaryTableCell>
              </TableRow>
            </TableBody>
          </Table>

          <div style={{ marginTop: '20px' }}>
            <Typography variant="h5" fontSize="0.875rem" fontWeight="500">
              {t('fuel_change_details')}
            </Typography>
            {order?.documents?.map((document) => (
              <DocumentLine>
                <DocumentInfo>
                  <DocumentImage>
                    <img
                      onClick={() => {
                        setSelectedPreview(document);
                      }}
                      src={document.url}
                      alt={document.fileName}
                      style={{ maxWidth: '100%' }}
                    />
                  </DocumentImage>
                  <div>
                    <DocumentName>{document?.fileName}</DocumentName>
                    <DocumentSize>
                      {document?.size ? Math.ceil(document.size / 1024) : 0} KB
                    </DocumentSize>
                  </div>
                </DocumentInfo>
                <DownloadButton>
                  <FileDownloadIcon
                    onClick={() => {
                      handleDownload(document?.url, document?.fileName);
                    }}
                  />
                </DownloadButton>
              </DocumentLine>
            ))}
          </div>
        </If>
      </FuelWrapper>
      <FooterWrapper>
        <ButtonWrapper>
          <Button
            variant="contained"
            onClick={() => {
              onCloseDrawer();
            }}
          >
            {t('common.buttons.done')}
          </Button>
        </ButtonWrapper>
      </FooterWrapper>

      {coordinates && (
        <MapPopover
          open={openMap}
          handleClose={() => {
            setOpenMap(false);
          }}
          latitude={coordinates[1]}
          longitude={coordinates[0]}
        />
      )}

      <Dialog
        open={!!selectedPreview && selectedPreview?.url}
        onClose={handleClosePreview}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
          aria-label="close"
          onClick={handleClosePreview}
          sx={{
            position: 'absolute',
            right: 20,
            top: 12,
            color: 'gray',
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          id="alert-dialog-title"
          style={{
            fontSize: '1.429rem',
            fontWeight: '500',
            padding: '12px 70px 32px 24px',
          }}
        >
          <DownloadButton>
            <FileDownloadIcon
              onClick={() => {
                handleDownload(selectedPreview?.url, selectedPreview?.fileName);
              }}
            />
          </DownloadButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{
              fontSize: '1rem',
              color: 'black',
              fontWeight: '400',
              paddingBottom: '15px',
            }}
          >
            <PreviewImage>
              <img src={selectedPreview?.url} alt={selectedPreview?.fileName} />
            </PreviewImage>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

const DocumentLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const DocumentInfo = styled.div`
  display: flex;
`;

const DocumentImage = styled.div`
  padding-right: 20px;

  & img {
    width: 31px;
    height: 48px;
    cursor: pointer;
  }
`;

const DownloadButton = styled.div`
  cursor: pointer;
`;

const PreviewImage = styled.div`
  padding: 20px 50px;
  & img {
    width: 286px;
    height: 443px;
  }
`;

const DocumentName = styled.div`
  color: ${(props) => props.theme.textColorPrimary};
  font-size: 1rem;
`;

const DocumentSize = styled.div`
  color: ${(props) => props.theme.textColorSecondary};
  font-size: 0.875rem;
`;

const FuelWrapper = styled(Wrapper)`
  width: 600px;
`;

const EmptyData = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
