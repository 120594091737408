import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Stack, ButtonProps, FormControlLabel, Checkbox } from '@mui/material';
import { DialogProps } from './types';
import {
  Actions,
  CancelButton,
  IconButton,
  Content,
  LargeDialog,
  Title,
} from './styles';
import { useTranslation } from 'react-i18next';
import _, { isArray } from 'lodash';
import If from '../If';

const noop = function () {};

export const GenericDialog: React.FC<DialogProps> = ({
  open,
  handleClose,
  actionBack,
  title,
  actionYes,
  actionNo,
  children,
  deleteModal,
  checkboxText,
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);

  const hasActions = actionYes || actionNo;
  const actionYesArray = isArray(actionYes) ? actionYes : [actionYes];
  // Cancel and action button computed props
  // TODO: clean this code
  let cancelButtonText = '';
  let cancelButtonCallback = noop;
  let cancelButtonProps: ButtonProps = {};
  if (typeof actionNo === 'boolean' && actionNo) {
    cancelButtonText = t('common.buttons.cancel');
  } else if (typeof actionNo !== 'boolean') {
    if (actionNo?.actionText) {
      cancelButtonText = actionNo.actionText;
    }
    if (actionNo?.actionCallback) {
      cancelButtonCallback = actionNo?.actionCallback;
    }
    if (actionNo?.buttonProps) {
      cancelButtonProps = actionNo?.buttonProps;
    }
  }

  return (
    <div>
      <LargeDialog
        open={open}
        onClose={() => {
          handleClose();
          cancelButtonCallback?.();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" alignItems="center">
            {actionBack && (
              <IconButton
                aria-label="back"
                disableRipple
                onClick={actionBack.actionCallback}
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            <Title>{title}</Title>
          </Stack>
          <IconButton
            aria-label="close"
            disableRipple
            onClick={() => {
              handleClose();
              cancelButtonCallback?.();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        {children && <Content>{children}</Content>}
        {checkboxText && (
          <FormControlLabel
            sx={{ padding: '0 12px' }}
            control={
              <Checkbox
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
              />
            }
            label={checkboxText}
          />
        )}
        {hasActions && (
          <Actions>
            {actionNo && (
              <CancelButton
                variant="text"
                onClick={() => {
                  cancelButtonCallback();
                  handleClose();
                }}
                {...cancelButtonProps}
              >
                {cancelButtonText}
              </CancelButton>
            )}
            <If condition={!!actionYesArray?.length}>
              {actionYesArray?.map((action, index) => {
                const actionButtonProps: ButtonProps = _.merge(
                  action?.buttonProps,
                  {},
                );
                return (
                  <Button
                    key={index}
                    variant="contained"
                    color={deleteModal ? 'error' : 'primary'}
                    onClick={() => {
                      action?.actionCallback(checked);
                      // handleClose();
                    }}
                    autoFocus
                    {...actionButtonProps}
                  >
                    {action?.actionText}
                  </Button>
                );
              })}
            </If>
          </Actions>
        )}
      </LargeDialog>
    </div>
  );
};

export default GenericDialog;
