import { ManagedTooltip } from '../TableComponent/helpers/Components/ManagedTooltip';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { Icon } from '../Icon';

interface ActionIconButtonProps {
  onClick: (e?: any) => void;
  title: string;
  icon: JSX.Element;
  disabled?: boolean;
}
export const ActionIconButton = ({
  onClick,
  title,
  icon,
  disabled,
}: ActionIconButtonProps) => {
  const { t } = useTranslation();

  return (
    <ManagedTooltip title={t(title)}>
      <IconButton disabled={disabled} onClick={onClick} sx={{ height: '32px' }}>
        <Icon icon={icon} />
      </IconButton>
    </ManagedTooltip>
  );
};
