import { IconButton, Stack } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { LeftMenuContext } from '../LeftMenu';
import { ArrowLeft } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import If from 'app/components/If';
import { StatusPopover } from 'app/components/TableComponent/helpers/Components/StatusPopover';
import styled from 'styled-components';
import { AgentCategories } from 'app/components/RoundTrips/RoundTripData/agentCategories';
import TruckIcon from 'app/components/TruckIcon';
import { PopoverAction } from 'app/pages/AddRoundTrips/data/RoundTripData/components/PopoverAction';
import { functionRoundTripStatus } from 'app/components/RoundTrips/function/ApiFunctions';
import { TabsMenu } from 'app/components/TabsMenu';
import { useTranslation } from 'react-i18next';
import RoundtripPopover from '../../../RoundtripPopover';
import NoStops from 'assets/img/stops/stops-empty-state.svg';
import { NoDataComponents } from 'app/components/NoData/NoDataComponents';
import { StopsList } from './components/StopsList';
import { useSubscribeObject } from 'common/hooks/ws';
import { useLazyGetRoundTripDetailsQuery } from 'common/services/roundtripApi';
import { CountChip } from 'app/components/FilterComponents/components/CountChip';
import {
  Driver,
  Sales,
} from 'app/components/RoundTrips/RoundTripData/statuses';
import { getRoundtripUserAndVehicleInfo } from 'app/helpers/helpers';
import { StateSetter } from 'types';

interface RoundtripDetailsDrawerProps {
  selectedRoundtripDetails: any;
  setSelectedRoundtripDetails?: StateSetter<any>;
  disableEdit?: boolean;
  hideHeader?: boolean;
  hoveredStop?: string;
  setHoveredStop?: StateSetter<any>;
}

export const RoundtripDetailsDrawer = ({
  selectedRoundtripDetails,
  setSelectedRoundtripDetails,
  disableEdit,
  hideHeader,
  hoveredStop,
  setHoveredStop,
}: RoundtripDetailsDrawerProps) => {
  const {
    updateRoundtripStatus,
    viewMode,
    userContext,
    handleClickOpen,
    handleClickOpenDelete,
    roundtrips,
    setRoundtripEdit,
  } = useContext(LeftMenuContext);

  const { t } = useTranslation();

  const [triggerRoundtripDetails, { data: roundtripDetails }] =
    useLazyGetRoundTripDetailsQuery();

  const [roundtrip, setRoundtrip] = useState<any>(selectedRoundtripDetails);
  const [tab, setTab] = useState('details');

  const userRole = userContext === AgentCategories.Sales ? Sales : Driver;

  const roundtripInfo = useMemo(() => {
    return getRoundtripUserAndVehicleInfo({
      agents: roundtrip?.agents,
      vehicle: roundtrip?._vehicle?.numberPlate,
      role: userRole,
    });
  }, [roundtrip, userRole]);

  const canEdit = useMemo(() => {
    return !viewMode && !disableEdit;
  }, [viewMode, disableEdit]);

  const handleOnSwitchTab = (event, newValue) => {
    setTab(newValue);
  };

  const lastMessage = useSubscribeObject(
    'roundtrip',
    selectedRoundtripDetails?._id,
  );

  useEffect(() => {
    const message = JSON.parse(lastMessage);
    if (message) {
      triggerRoundtripDetails({ roundtripId: selectedRoundtripDetails?._id });
    }
  }, [lastMessage]);

  useEffect(() => {
    if (roundtripDetails?._id) {
      setRoundtrip(roundtripDetails);
    }
  }, [roundtripDetails]);

  useEffect(() => {
    if (selectedRoundtripDetails?._id) {
      setRoundtrip(selectedRoundtripDetails);
    }
  }, [selectedRoundtripDetails]);

  return (
    <DrawerContainer>
      <If condition={!!roundtrip}>
        <If condition={!hideHeader}>
          <DrawerHeader>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="center" gap="8px">
                <If condition={!!setSelectedRoundtripDetails}>
                  <IconButton
                    disableRipple
                    onClick={() => {
                      setSelectedRoundtripDetails?.(null);
                    }}
                  >
                    <Icon icon={<ArrowLeft />} />
                  </IconButton>
                </If>

                <StatusPopover
                  roundtrip={roundtrip}
                  functionRoundTripStatus={updateRoundtripStatus}
                  notEdit={!canEdit}
                  minimal={true}
                  drawer={true}
                  details={true}
                />
              </Stack>
              <Stack direction="row" alignItems="center" gap="8px">
                <TruckIcon
                  driver={false}
                  vehicle={false}
                  running={roundtrip?._vehicle?.engineRunning}
                  status={roundtrip?._vehicle?.status}
                  style={{
                    position: 'relative',
                    paddingLeft: '4px',
                  }}
                />
                <If condition={canEdit}>
                  <PopoverAction
                    typeEdit={canEdit}
                    roundtrip={roundtrip}
                    functionRoundTripStatus={functionRoundTripStatus}
                    handleClickOpen={handleClickOpen}
                    handleClickOpenDelete={handleClickOpenDelete}
                    setRoundtripEdit={setRoundtripEdit}
                    anchorOriginVertical="top"
                    anchorOriginHorizontal={35}
                    transformOriginVerical="top"
                    transformOriginHorizontal="left"
                  />
                </If>
              </Stack>
              <TruckIconWrapper>
                <If
                  condition={
                    !!roundtripInfo?.missingUser || !roundtripInfo?.hasVehicle
                  }
                >
                  <TruckIcon
                    driver={!!roundtripInfo?.missingUser}
                    vehicle={!roundtripInfo?.hasVehicle}
                    running={roundtrip?._vehicle?.engineRunning}
                    status={roundtrip?._vehicle?.status}
                    style={{
                      position: 'relative',
                      paddingLeft: '4px',
                    }}
                  />
                </If>
              </TruckIconWrapper>
            </Stack>
          </DrawerHeader>
        </If>
        <DrawerBody>
          <TabsWrapper>
            <TabsMenu
              handleOnSwitchTab={handleOnSwitchTab}
              selectedTab={tab}
              tabs={[
                {
                  value: 'details',
                  label: t('common.details'),
                },
                {
                  value: 'stops',
                  label: (
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px',
                      }}
                    >
                      {`${t('common.stops')}`}
                      <CountChip count={roundtrip?.stops?.length} />
                    </span>
                  ),
                },
              ]}
              withDivider={false}
            />
          </TabsWrapper>

          <If condition={tab === 'details'}>
            <RoundtripPopover
              roundtrip={roundtrip}
              displayCode={false}
              displayStatus={true}
              canEdit={canEdit}
              userContext={userContext}
              roundtrips={roundtrips}
            />
          </If>
          <If condition={tab === 'stops'}>
            <If
              condition={roundtrip?.stops?.length}
              otherwise={
                <CenterDiv>
                  <NoDataComponents
                    Icon={NoStops}
                    text={t('no_stops_yet')}
                    IconButton={null}
                    textButton=""
                    viewMode={true}
                  />
                </CenterDiv>
              }
            >
              <ContentWrapper>
                <StopsList
                  stops={roundtrip?.stops}
                  disableDrag={disableEdit}
                  hoveredStop={hoveredStop}
                  setHoveredStop={setHoveredStop}
                />
              </ContentWrapper>
            </If>
          </If>
        </DrawerBody>
      </If>
    </DrawerContainer>
  );
};

const DrawerContainer = styled.div`
  width: 249px;
  background: white;
  height: 100%;
`;

const DrawerHeader = styled.div`
  padding: 0 16px 0 8px;
  & div {
    margin-right: 0;
  }
  margin-bottom: 10px;
  position: relative;
`;
const DrawerBody = styled.div`
  & .MuiTabs-flexContainer {
    width: 100%;
    justify-content: space-between;
  }
  & button {
    flex-grow: 1;
    padding: 0;
  }
  height: calc(
    100vh - ${(props) => props.theme.tableNavHeight} -
      ${(props) => props.theme.toolBarHeight} - 50px
  );
`;

const CenterDiv = styled.div`
  height: calc(
    100vh - ${(props) => props.theme.tableNavHeight} -
      ${(props) => props.theme.toolBarHeight} - 120px
  );
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  padding: 0 1.5px 0 8px;
  width: 100%;
  height: calc(100% - 40px);

  @supports selector(::-webkit-scrollbar) {
    scrollbar-gutter: stable;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }
  scrollbar-color: transparent !important;
  overflow-y: overlay;
  overflow-x: hidden;

  &:hover {
    overflow-y: overlay;
    &::-webkit-scrollbar-thumb {
      background-color: ${(props) => props.theme.scrollBarColor} !important;
    }
    scrollbar-color: ${(props) => props.theme.scrollBarColor} !important;
  }
`;

const TabsWrapper = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.lightFill};
`;

const TruckIconWrapper = styled.div`
  position: absolute;
  top: 5.5px;
  right: -11px;
`;
