import { FormControlLabel, Popover, Switch } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import If from '../If';
import {
  getOrderStatusesToShow,
  handleCheckboxFilterChange,
  handleSelectFilterChange,
} from 'app/helpers/helpers';
import { useSelector } from 'react-redux';
import {
  selectConfiguration,
  selectOrganization,
} from 'common/store/organization/selectors';
import CheckboxGrid from '../FilterComponents/components/CheckboxGrid';
import { FilterContainer, FilterSection } from '../FilterComponents/styles';
import { FilterHeader } from '../FilterComponents/components/FilterHeader';
import ValueSlider from '../FilterComponents/components/ValueSlider';
import { useCheckValues } from '../FilterComponents/hooks/useCheckValues';
import DateRangeFilter from '../FilterComponents/components/DateRangeFilter';
import { useGetOrderFacetsQuery } from 'common/services/orderApi';
import { useCallback, useEffect, useMemo } from 'react';
import MultiSelect from '../FilterComponents/components/MultiSelect';
import { useLazyGetUsersWebQuery } from 'common/services/userApi';
import { useQueryParams } from 'hooks/useQueryParams';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { themes } from 'styles/theme/themes';
import { getAgent, getAgentFullName } from 'common/helpers/agent';
import { useAbility } from 'hooks/Abilities';

interface OrderFilterProps {
  setFilters: React.Dispatch<React.SetStateAction<any>>;
  filterOn: HTMLButtonElement | null;
  handleCloseFilter: () => void;
  excludedFilters?: string[];
  minQuantity?: number;
  maxQuantity?: number;
  minTotalQuantity?: number;
  anchorOriginHorizontal?: 'center' | 'left' | 'right' | number;
  transformOriginHorizontal?: 'center' | 'left' | 'right' | number;
  filters?: any;
  clusterId?: string;
  availableDate?: string;
  ordoria?: boolean;
}

const OrderFilter = ({
  setFilters,
  filterOn,
  handleCloseFilter,
  excludedFilters,
  minQuantity,
  maxQuantity,
  minTotalQuantity,
  anchorOriginHorizontal,
  transformOriginHorizontal,
  filters,
  clusterId,
  availableDate,
  ordoria,
}: OrderFilterProps) => {
  const ability = useAbility();
  const authUser = useSelector(selectAuthUser);
  const configuration = useSelector(selectConfiguration);
  const authOrganization = useSelector(selectOrganization);

  const { t } = useTranslation();

  const [triggerSalesmen, { data: salesData }] = useLazyGetUsersWebQuery();
  const [triggerUsers, { data: userData }] = useLazyGetUsersWebQuery();
  const { data: facetsData } = useGetOrderFacetsQuery(
    `?clusterId=${clusterId || ''}&availableDate=${availableDate || ''}`,
  );

  const maxSliderQuantity = useMemo(() => {
    return facetsData?.maxQuantity || maxQuantity;
  }, [facetsData]);
  const maxSliderProducts = useMemo(() => {
    return facetsData?.maxProducts || maxQuantity;
  }, [facetsData]);

  const maxSliderTotal = useMemo(() => {
    return Math.ceil(facetsData?.maxAmount) || 500;
  }, [facetsData]);

  const openFilter = Boolean(filterOn);
  const id2 = openFilter ? 'filter' : undefined;

  const { checkedValues, setCheckedValues } = useCheckValues(filters);

  const priorities = ['high', 'medium', 'low'];
  const deliveryTypes = [
    'delivery',
    'collection',
    'express',
    'urgent',
    'repair',
  ];
  const statuses = getOrderStatusesToShow(
    configuration?.orderStatusLogistic || [],
  );
  const vehicleTypes = ['vl', 'pl'];
  const quantitySliderMarks = [
    {
      value: 0,
      label: minQuantity,
    },
    {
      value: maxSliderQuantity,
      label: maxSliderQuantity,
    },
  ];
  const productSliderMarks = [
    {
      value: 0,
      label: minQuantity,
    },
    {
      value: maxSliderProducts,
      label: maxSliderProducts,
    },
  ];

  const totalSliderMarks = [
    {
      value: 0,
      label: minTotalQuantity || 0,
    },
    {
      value: maxSliderTotal,
      label: maxSliderTotal,
    },
  ];

  const handleQuantitySliderChange = (sliderValue: number[]) => {
    setFilters((prevState) => ({
      ...prevState,
      quantity: {
        minQuantity: sliderValue?.[0] || minQuantity || 0,
        maxQuantity: sliderValue?.[1] || maxSliderQuantity,
      },
    }));
  };

  const handleLinesSliderChange = (sliderValue: number[]) => {
    setFilters((prevState) => ({
      ...prevState,
      lines: {
        minProducts: sliderValue?.[0] || minQuantity || 0,
        maxProducts: sliderValue?.[1] || maxSliderProducts,
      },
    }));
  };

  const handleTotalSliderChange = (sliderValue: number[]) => {
    setFilters((prevState) => ({
      ...prevState,
      minTotal: sliderValue?.[0] || minQuantity || 0,
      maxTotal: sliderValue?.[1] || maxSliderTotal,
    }));
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    label: string,
    filterKey: string,
  ) => {
    handleCheckboxFilterChange(
      event,
      label,
      filterKey,
      checkedValues,
      setCheckedValues,
      setFilters,
    );
  };

  const handleOptionsChange = (
    filterKey: string,
    options: { value: string; label: string }[],
  ) => {
    handleSelectFilterChange(filterKey, options, setFilters);
  };

  const handleDeliveryDateChange = ({ begin, end }) => {
    setFilters((prevState) => ({
      ...prevState,
      begin: begin ? dayjs(begin).format('YYYY-MM-DD') : null,
      end: end ? dayjs(end).format('YYYY-MM-DD') : null,
    }));
  };

  const handleCreatedDateChange = ({ begin, end }) => {
    setFilters((prevState) => ({
      ...prevState,
      createdBeginAt: begin ? dayjs(begin).format('YYYY-MM-DD') : null,
      createdEndAt: end ? dayjs(end).format('YYYY-MM-DD') : null,
    }));
  };

  const toggleWithDeleted = () => {
    setFilters((prevState) => ({
      ...prevState,
      withDeleted: !prevState.withDeleted,
    }));
  };

  const facets = useMemo(() => {
    return facetsData?.facets?.[0] || {};
  }, [facetsData]);

  const users = useMemo(() => {
    return userData?.docs || [];
  }, [userData]);

  const salesmen = useMemo(() => {
    return salesData?.docs || [];
  }, [salesData]);

  const userQueryParams = useQueryParams({
    context: 'users',
    page: 1,
    limit: 20,
  });

  const searchUsers = (search: string) => {
    triggerUsers(`${userQueryParams}&text=${search}`);
  };

  const searchSalesmen = useCallback(
    (search: string) => {
      triggerSalesmen(`${userQueryParams}&roles=sales&text=${search}`);
    },
    [triggerSalesmen, userQueryParams],
  );

  useEffect(() => {
    if (ability.can('canview', 'users')) {
      searchSalesmen('');
    }
  }, [searchSalesmen]);

  const handleClearAll = () => {
    setCheckedValues({});

    setFilters({
      begin: null,
      end: null,
      priority: [],
      truckType: [],
      status: [],
      deliveryType: [],
      quantity: {
        minQuantity: minQuantity,
        maxQuantity: minQuantity,
      },
      lines: {
        minProducts: minQuantity,
        maxProducts: minQuantity,
      },
      productIds: [],
      salesmanIds: [],
      createdByIds: [],
      withDeleted: false,
    });
  };

  const getVehicleTypeCount = useCallback(
    (type: string) => {
      return facets?.cache__deck_vehicleCondition?.reduce(
        (totalCount: number, item: { _id: string[]; count: number }) => {
          if (item._id?.includes(type)) {
            return totalCount + item.count || 0;
          }
          return totalCount;
        },
        0,
      );
    },
    [facets?.cache__deck_vehicleCondition],
  );

  return (
    <Popover
      id={id2}
      open={openFilter}
      anchorEl={filterOn}
      onClose={handleCloseFilter}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: anchorOriginHorizontal || 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: transformOriginHorizontal || 'right',
      }}
    >
      <FilterContainer>
        <FilterHeader onClearAll={handleClearAll} />
        <FilterSection>
          <If condition={!excludedFilters?.includes('deliveryDate')}>
            <DateRangeFilter
              openByDefault={true}
              filterName={t('orders.deliveryDate')}
              dateValue={{ begin: filters?.begin, end: filters?.end }}
              handleFilterChange={handleDeliveryDateChange}
            />
          </If>
          <If
            condition={!excludedFilters?.includes('createdDate') && !!ordoria}
          >
            <DateRangeFilter
              openByDefault={false}
              filterName={t('orders.datatables.filter.createdDate')}
              dateValue={{
                begin: filters?.createdBeginAt,
                end: filters?.createdEndAt,
              }}
              handleFilterChange={handleCreatedDateChange}
            />
          </If>
          <If condition={!excludedFilters?.includes('status')}>
            <CheckboxGrid
              filterName={t('checkDocument.status')}
              checkedValues={checkedValues}
              handleFilterChange={(e, label) => {
                handleCheckboxChange(e, label, 'status');
              }}
              filterItems={statuses?.map((statusObj) => {
                return {
                  value: statusObj.status,
                  label: t(
                    `orders.status.${
                      statusObj.label ? statusObj.label : statusObj.status
                    }`,
                  ),
                  count: facets?.status?.find(
                    (stat: any) => stat._id === statusObj.status,
                  )?.count,
                };
              })}
            />
          </If>
          <If condition={!excludedFilters?.includes('priority')}>
            <CheckboxGrid
              filterName={t('common.priority')}
              checkedValues={checkedValues}
              handleFilterChange={(e, label) => {
                handleCheckboxChange(e, label, 'priority');
              }}
              filterItems={priorities.map((priority) => {
                return {
                  value: priority,
                  label: t(`common.${priority}`),
                  count: facets?.priority?.find(
                    (pr: any) => pr._id === priority,
                  )?.count,
                };
              })}
            />
          </If>
          <If condition={!excludedFilters?.includes('quantity')}>
            <ValueSlider
              sliderValue={[
                filters?.quantity?.minQuantity,
                filters?.quantity?.maxQuantity,
              ]}
              filterName={t('common.quantity')}
              marks={quantitySliderMarks}
              handleFilterChange={handleQuantitySliderChange}
              minQuantity={minQuantity}
              maxQuantity={maxSliderQuantity}
            />
          </If>
          <If condition={!excludedFilters?.includes('lines')}>
            <ValueSlider
              sliderValue={[
                filters?.lines?.minProducts,
                filters?.lines?.maxProducts,
              ]}
              filterName={t('product_lines')}
              marks={productSliderMarks}
              handleFilterChange={handleLinesSliderChange}
              minQuantity={minQuantity}
              maxQuantity={maxSliderProducts}
            />
          </If>
          <If condition={!excludedFilters?.includes('deliveryType')}>
            <CheckboxGrid
              filterName={t('clients.orderDetails.delivery_type')}
              checkedValues={checkedValues}
              handleFilterChange={(e, label) => {
                handleCheckboxChange(e, label, 'deliveryType');
              }}
              filterItems={deliveryTypes.map((dt) => {
                return {
                  value: dt,
                  label: t(`common.${dt}`),
                  count: facets?.type?.find((stat: any) => stat._id === dt)
                    ?.count,
                };
              })}
            />
          </If>
          <If condition={!excludedFilters?.includes('salesman')}>
            <If condition={!!salesmen?.length}>
              <MultiSelect
                filterName={t('customer.salesman')}
                selectedOptions={filters?.salesmanIds}
                handleFilterChange={(options) => {
                  handleOptionsChange('salesmanIds', options);
                }}
                filterItems={salesmen?.map((sale) => {
                  const preferredAgent = getAgent(sale, authOrganization);
                  return {
                    value: sale._id,
                    label: getAgentFullName(preferredAgent),
                    count: facets?._salesman?.find(
                      (stat: any) => stat._id === sale._id,
                    )?.count,
                  };
                })}
                onSearch={searchSalesmen}
              />
            </If>
          </If>
          <If condition={!excludedFilters?.includes('createdBy')}>
            <MultiSelect
              filterName={t('created_by')}
              selectedOptions={filters?.createdByIds || []}
              handleFilterChange={(options) => {
                handleOptionsChange('createdByIds', options);
              }}
              filterItems={users?.map((user) => {
                const preferredAgent = getAgent(user, authOrganization);
                return {
                  value: user._id,
                  label: getAgentFullName(preferredAgent),
                };
              })}
              onSearch={searchUsers}
            />
          </If>

          <If condition={!excludedFilters?.includes('vehicleType')}>
            <CheckboxGrid
              filterName={t('info.truck_type')}
              checkedValues={checkedValues}
              handleFilterChange={(e, label) => {
                handleCheckboxChange(e, label, 'truckType');
              }}
              filterItems={vehicleTypes?.map((vt) => {
                return {
                  value: vt,
                  label: t(`common.${vt}`),
                  count: getVehicleTypeCount(vt),
                };
              })}
            />
          </If>
          <If condition={!excludedFilters?.includes('totalTTC') && !!ordoria}>
            <ValueSlider
              sliderValue={[filters?.minTotal, filters?.maxTotal]}
              filterName={t('total_ttc')}
              marks={totalSliderMarks}
              handleFilterChange={handleTotalSliderChange}
              minQuantity={minTotalQuantity}
              maxQuantity={maxSliderTotal}
            />
          </If>
          <If
            condition={
              !excludedFilters?.includes('withDeleted') &&
              authUser?.currentAgent?._role?.key === 'admin'
            }
          >
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  onChange={toggleWithDeleted}
                  checked={filters?.withDeleted}
                  value={filters?.withDeleted}
                />
              }
              label={t(`with_deleted`)}
              sx={{
                marginLeft: 0,
                marginTop: '10px',
                color: !!filters?.withDeleted
                  ? themes.default.primaryActiveColor
                  : themes?.default?.textBlack,
                letterSpacing: '0.15px',
                '& .MuiTypography-root': {
                  fontSize: '0.875rem !important',
                  fontWeight: 400,
                  padding: '2px 0 0 0',
                },
              }}
            />
          </If>
        </FilterSection>
      </FilterContainer>
    </Popover>
  );
};

export default OrderFilter;
