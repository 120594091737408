import { Paper, Stack, SxProps } from '@mui/material';
import If from 'app/components/If';
import { isUndefined } from 'lodash';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { VariationChip } from '../VariationChip';
import { StatisticCardSkeleton } from './components/StatisticCardSkeleton';

interface StatisticCardProps {
  title: string;
  value: string;
  variation?: number;
  icon?: JSX.Element;
  width?: string;
  loading?: boolean;
  BottomSection?: JSX.Element;
  variationTooltip?: string;
  sxProps?: SxProps;
  padding?: string;
  titleColor?: string;
  valueSize?: string;
  valueWeight?: number;
  allOrganizations?: any;
  onClick?: any;
}

export const StatisticCard = ({
  title,
  value,
  variation,
  icon,
  width = '25%',
  loading,
  BottomSection,
  variationTooltip,
  sxProps,
  padding = '24px',
  titleColor = themes?.default?.sharpGray,
  valueSize = '1.5rem',
  valueWeight = 600,
  allOrganizations = false,
  onClick,
}: StatisticCardProps) => {
  return (
    <Paper
      onClick={onClick || (() => {})}
      sx={{
        width: width,
        boxShadow: 'none',
        border: `0.4px solid ${themes?.default?.textColorSecondary}50`,
        borderRadius: '8px',
        position: 'relative',
        ...sxProps,
        ':hover': {
          backgroundColor: allOrganizations ? themes?.default?.lightGrey : '',
          cursor: allOrganizations ? 'pointer' : 'default',
        },
      }}
    >
      <Content padding={padding}>
        <If
          condition={!loading}
          otherwise={<StatisticCardSkeleton withIcon={!!icon} />}
        >
          <If condition={!!icon}>
            <IconContainer>{icon}</IconContainer>
          </If>
          <Stack
            direction="row"
            gap="24px"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack gap="4px">
              <Title titleColor={titleColor}>{title}</Title>
              <Value valueSize={valueSize} valueWeight={valueWeight}>
                {value || 0}
              </Value>
            </Stack>
            <div>
              <If condition={!isUndefined(variation)}>
                <VariationChip
                  value={variation || 0}
                  tooltipTitle={variationTooltip}
                />
              </If>
            </div>
          </Stack>

          {BottomSection}
        </If>
      </Content>
    </Paper>
  );
};

const Content = styled.div<{ padding: string }>`
  padding: ${(props) => props.padding};
`;

const Title = styled.div<{ titleColor: string }>`
  color: ${(props) => props.titleColor};
  font-size: 0.8125rem;
  font-weight: 500;
  text-wrap: nowrap;
  line-height: 1.25rem;
`;

const Value = styled.div<{ valueSize?: string; valueWeight: number }>`
  color: ${(props) => props.theme.lightBlack};
  font-size: ${(props) => props.valueSize};
  font-weight: ${(props) => props.valueWeight};
  line-height: 2.375rem;
`;
const IconContainer = styled.div`
  margin-bottom: 12px;
`;
