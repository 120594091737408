import styled from 'styled-components';

export const StickyContent = styled.div`
  max-height: ${(props) => props.theme?.dashboardStickySectionHeight};
  overflow-y: scroll;
`;

export const StickyContainer = styled.div`
  height: 50%;
  padding: 0 0 20px 0;
  position: relative;
`;

export const StickyEmptyState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.75rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-right: 10px;
  width: 100%;
`;

export const RoundtripItemWrapper = styled.div`
  margin-bottom: 20px;
  cursor: pointer;
`;

export const StickySection = styled.div`
  position: absolute;
  right: 0;
  width: ${(props) => props.theme?.dashboardStickySectionWidth};
  height: ${(props) => `calc(100vh - ${props.theme.dashboardTopSection})`};
`;
export const MainSection = styled.div`
  width: ${(props) =>
    `calc(100% - ${props.theme.dashboardStickySectionWidth})`};
  height: ${(props) => `calc(100vh - ${props.theme.dashboardTopSection})`};
  overflow: scroll;
  padding-right: 1rem;
`;
export const BottomSection = styled.div`
  margin-top: 1rem;
  width: 100%;
  min-width: ${(props) => props.theme?.dashboardLargeCardWidth};
`;
export const ScrollSection = styled.div`
  min-width: ${(props) =>
    `calc(${props.theme?.dashboardLargeCardWidth} + 2rem)`};
`;

export const MapWrapper = styled.div`
  /* position: absolute; */
  width: 100%;
  height: 100%;
  /* z-index: 100; */
`;
